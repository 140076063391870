<template>
  <div>
    <el-dialog
      title="基本工资管理"
      :visible.sync="showDialog"
      width="90%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <div class="df-row">
          <div class="df-col">
            <el-date-picker
              v-model="submitData.month"
              value-format="yyyy-MM"
              type="month"
              size="mini"
              placeholder="选择月份"
              @change="onChangeDate"
            >
            </el-date-picker>
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="getBasicData"
              >查询</el-button
            >
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="onShowImportDialog"
              >导入员工基本工资</el-button
            >
          </div>
          <div class="df-col">
            <el-button type="primary" size="mini" @click="saveBasicData"
              >保存员工基本工资</el-button
            >
          </div>
          <div class="df-col">
            <el-button type="danger" size="mini" @click="deleteAllBasicData"
              >删除员工基本工资</el-button
            >
          </div>
        </div>

        <el-table
          v-loading="loading"
          ref="table"
          :data="tableData"
          :max-height="tableMaxHeight"
          size="mini"
          border
          stripe
          @row-click="handleRowClick"
        >
          <el-table-column
            prop="BasicYear"
            label="工资年份"
            width="70px"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="BasicMonth"
            label="工资月份"
            width="70px"
            fixed="left"
          ></el-table-column>
          <!-- <el-table-column prop="" label="开始时间" width="90px">
            <template slot-scope="scope">
              {{
                util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.StartDate * 1000)
                )
              }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="结束日期" width="90px">
            <template slot-scope="scope">
              {{
                util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.EndDate * 1000)
                )
              }}
            </template>
          </el-table-column> -->
          <el-table-column
            prop="EmplNo"
            label="员工工号"
            width="80px"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="EmplName"
            label="员工姓名"
            width="80px"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="DutyName"
            label="员工职位"
            width="80px"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="LevelName"
            label="员工级别"
            width="80px"
            fixed="left"
          ></el-table-column>
          <el-table-column prop="" label="状态" width="60px" fixed="left">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.DimissionMark ? 'info' : 'success'"
                size="mini"
                effect="dark"
                >{{ scope.row.DimissionMark ? "离职" : "在职" }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            v-for="(item, index) in tableColumnList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
          >
            <template slot-scope="scope">
              <el-input
                v-if="
                  item.type === 'input' &&
                    scope.$index == cell[0] &&
                    item.prop == cell[1]
                "
                v-model="scope.row[item.prop]"
                size="mini"
                clearable
                @change="onChangeInput(scope, item, $event)"
              ></el-input>

              <el-select
                v-else-if="
                  item.type === 'select' &&
                    scope.$index == cell[0] &&
                    item.prop == cell[1]
                "
                v-model="scope.row[item.prop]"
                size="mini"
                clearable
                @change="onChangeInput(scope, item, $event)"
              >
                <el-option
                  v-for="item in item.prop == 'BasepayType'
                    ? basicPayModeList
                    : modeList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>

              <span v-else>{{
                item.type == "select"
                  ? handleSelectValue(scope, item)
                  : scope.row[item.prop]
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="删除" width="50px">
            <template slot-scope="scope">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                circle
                @click="deleteSingleBasicData(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <el-dialog
      title="导入员工基本工资"
      :visible.sync="showImportDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="showImportDialog = false"
    >
      <span class="import-content">
        <div class="date">
          <span>计算日期</span>
          <el-date-picker
            v-model="importData.month"
            value-format="yyyy-MM"
            type="month"
            size="mini"
            :clearable="false"
            placeholder="选择月份"
          >
          </el-date-picker>
        </div>

        <el-checkbox
          v-model="importData.copy_last"
          :true-label="1"
          :false-label="0"
          :indeterminate="false"
          >导入上一月底薪</el-checkbox
        >
      </span>
      <span slot="footer">
        <el-button @click="showImportDialog = false">取消</el-button>
        <el-button type="primary" @click="generateBasicData">导入</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Report from "@/api/report";

export default {
  data() {
    return {
      tableMaxHeight: "",
      submitData: {
        month: "",
      },
      importData: {
        month: "",
        copy_last: 0,
      },
      tableData: [],
      basicPayModeList: [
        { Name: "无", Value: 0 },
        { Name: "以月结算", Value: 1 },
        { Name: "按日结算", Value: 2 },
        { Name: "按月计算(保底)", Value: 3 },
      ],
      modeList: [
        { Name: "无", Value: 0 },
        { Name: "以月结算", Value: 1 },
        { Name: "按日结算", Value: 2 },
      ],
      tableColumnList: [
        { label: "考勤天数", prop: "AtteDays", width: "100px", type: "input" },
        {
          label: "底薪模式",
          prop: "BasepayType",
          width: "140px",
          type: "select",
        },
        {
          label: "底薪基数",
          prop: "BasepayValue",
          width: "100px",
          type: "input",
        },
        {
          label: "餐费模式",
          prop: "ChargeMealsType",
          width: "130px",
          type: "select",
        },
        {
          label: "餐费基数",
          prop: "ChargeMealsValue",
          width: "100px",
          type: "input",
        },
        {
          label: "住宿费模式",
          prop: "ChargeStayType",
          width: "130px",
          type: "select",
        },
        {
          label: "住宿费基数",
          prop: "ChargeStayValue",
          width: "100px",
          type: "input",
        },
        {
          label: "电话费模式",
          prop: "ChargePhoneType",
          width: "130px",
          type: "select",
        },
        {
          label: "电话费基数",
          prop: "ChargePhoneValue",
          width: "100px",
          type: "input",
        },
        {
          label: "交际费模式",
          prop: "ChargePhoneType",
          width: "130px",
          type: "select",
        },
        {
          label: "交际费基数",
          prop: "ChargePhoneValue",
          width: "100px",
          type: "input",
        },
        {
          label: "全勤基数",
          prop: "FullAttendanceValue",
          width: "100px",
          type: "input",
        },
        {
          label: "奖励基数",
          prop: "RewardValue",
          width: "100px",
          type: "input",
        },
        {
          label: "扣罚基数",
          prop: "DeductionValue",
          width: "100px",
          type: "input",
        },
      ],
      displayMap: {},
      cell: [],
      loading: false,
      showDialog: false,
      showImportDialog: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取基本工资数据
    async getBasicData() {
      this.loading = true;
      try {
        let { data } = await Report.getBasicData({
          month: this.submitData.month,
        });
        this.tableData = data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 导入员工薪资
    async generateBasicData() {
      try {
        let { errcode } = await Report.generateBasicData(this.importData);
        if (errcode == 0) {
          this.$message.success("导入成功");
          this.submitData.month = JSON.parse(
            JSON.stringify(this.importData.month)
          );
          this.showImportDialog = false;
          this.getBasicData();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存员工基本工资
    saveBasicData() {
      this.$confirm("确定保存吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { tableData } = this,
          arr = tableData.filter((item) => item.EditMode);
        // console.log(arr);
        try {
          let { errcode } = await Report.saveBasicData({
            MonthDate: this.submitData.month + "-01",
            EditItems: arr,
          });
          if (errcode == 0) {
            this.$message.success("保存成功");
            this.onCloseDialog();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 删除全部员工薪资
    deleteAllBasicData() {
      this.$confirm("确定删除全部员工基本工资吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.deleteAllBasicData({
            month: this.submitData.month,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getBasicData();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 删除单独员工薪资
    deleteSingleBasicData(event) {
      this.$confirm("确定删除此员工基本工资信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.deleteSingleBasicData({
            basic_guid: event.BasicGuid,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getBasicData();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight * 0.6;
    },

    // 单元格点击事件
    handleRowClick(row, column, event) {
      let rowIndex = this.tableData.indexOf(row);
      this.cell = [rowIndex, column.property];
      // console.log(this.cell);
    },

    handleSelectValue(scope, item) {
      let param = {};
      param = this.basicPayModeList.find(
        (e) => e.Value === scope.row[item.prop]
      );
      return param.Name;
    },

    //
    onChangeInput(scope, item, event) {
      scope.row[item.prop] = event || 0;
      scope.row.EditMode = 1;
    },

    // 初始化日期状态
    async initDateState() {
      let { beginMonth } = await this.$store.dispatch("onGetDateState");
      this.submitData.month = beginMonth;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { monthTime: event });
      this.initDateState();
    },

    // 打开导入员工基本工资对话框
    onShowImportDialog() {
      this.importData = {
        month: this.util.getMonth(),
        copy_last: 0,
      };
      this.showImportDialog = true;
    },

    // 打开对话框
    async onShowDialog() {
      this.showDialog = true;
      await this.initDateState();
      await this.getBasicData();
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
      this.cell = [];
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .content-box {
  height: auto;
  max-height: 50%;
  overflow-y: auto;
}

.import-content {
  .date {
    .flex-row;
    margin-bottom: 10px;
    span {
      margin-right: 10px;
    }
  }
}
</style>
