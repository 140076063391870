<template>
  <div
    class="class-trend"
    :style="[{ '--data-column-width': dataColumnWidth + 'px' }]"
  >
    <div class="df-row">
      <!-- <div v-if="authType == 'H'" class="df-col">
        <el-select
          style="width: 260px;"
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStore"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- 员工选择器 -->
      <div class="df-col">
        <DfEmplSelect
          ref="DfEmplSelect"
          size="mini"
          
          :emplGuids="submitData.EmplGuids"
          :storeList="storeList"
          :selectMultiple="false"
          :menuMultiple="false"
          :dutyMultiple="false"
          :emplMultiple="false"
          @selected="onSelectedEmpl"
        ></DfEmplSelect>
      </div>

      <!-- 显示内容单选框 -->
      <div class="df-col">
        <el-radio-group v-model="submitData.QueryMode" @input="onChangeRadio">
          <el-radio
            v-for="(item, index) in modeSelector"
            :key="index"
            :label="item.Value"
          >
            {{ item.Name }}
          </el-radio>
        </el-radio-group>
      </div>

      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-show="submitData.QueryMode == 2"
          v-model="monthArray"
          type="monthrange"
          size="mini"
          value-format="yyyy-MM"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="onChangeDate"
        >
        </el-date-picker>

        <el-date-picker
          v-show="submitData.QueryMode == 1"
          v-model="dayArray"
          type="daterange"
          size="mini"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 累计业绩 -->
        <el-checkbox
          v-model="submitData.CumulativePerfor"
          :true-label="1"
          :false-label="0"
          >累计业绩</el-checkbox
        >
      </div>

      <div class="df-col">
        <!-- 赠送项目 -->
        <el-checkbox
          v-model="submitData.ShowDonaItem"
          :true-label="1"
          :false-label="0"
          >赠送项目</el-checkbox
        >
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button @click="onSearchData" size="mini" type="primary"
          >开始查询</el-button
        >
      </div>

      <!-- 自定义表格按钮 -->
      <div class="df-col">
        <el-button @click="onCustomTable" size="mini" type="primary"
          >自定义表格</el-button
        >
      </div>

      <!-- 导出表格按钮 -->
      <div class="df-col">
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
          >导出表格</el-button
        >
      </div>

      <!-- 打印数据按钮 -->
      <!-- <div class="df-col">
        <el-button @click="onPrintTable" size="mini" type="primary"
          >打印数据</el-button
        >
      </div> -->
    </div>

    <table id="table-loading" v-loading="loading">
      <el-table
        ref="table"
        id="exprot-table"
        border
        stripe
        :data="dataList"
        :summary-method="onTotal"
        :max-height="tableMaxHeight"
      >
        <el-table-column
          v-if="dataList.length > 0"
          prop="StatField"
          label="时间趋势"
          :width="columnMaxWidth"
          :fixed="isFixedRow"
          show-overflow-tooltip
        >
        </el-table-column>

        <!-- 合并列表头(一级表头) -->
        <el-table-column
          v-for="(item, index) in topList"
          :key="index"
          :label="item.Name"
          align="center"
        >
          <!-- 子表头（二级表头） -->
          <el-table-column
            v-if="item.Code == item2.TopCode"
            v-for="(item2, index2) in headList"
            :key="index2"
            :label="item2.Name"
            :prop="item2.DataKey"
            show-overflow-tooltip
            :width="dataColumnWidth || ''"
          >
            <template slot-scope="scope">
              <div ref="tb-col">{{ scope.row[item2.DataKey] }}</div>
              <div>
                {{
                  scope.row[item2.DataKeyPlus] != undefined
                    ? "(" + scope.row[item2.DataKeyPlus] + ")"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <table
        ref="summary"
        v-if="dataList.length > 0"
        class="summary-box"
        cellpadding="0"
        cellspacing="0"
        border="0"
      >
        <tbody>
          <tr>
            <!-- 不需要合计的动态空值列 -->
            <td
              class="summary-item-fixed"
              :style="'position: ' + isFixedRow ? 'unset' : 'sticky'"
            >
              <div>合计</div>
            </td>

            <td
              v-if="index != 0"
              v-for="(item, index) in headList"
              :key="index"
              class="summary-item"
            >
              <div>
                <div>{{ totaList[item.DataKey] }}</div>
                <div>
                  {{
                    totaList[item.DataKeyPlus] != undefined
                      ? "(" + totaList[item.DataKeyPlus] + ")"
                      : ""
                  }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </table>

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      :reportCode="reportCode"
      @refresh="onRefreshCustom"
      @onConfirm="onSubmitRevenue"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import Report from "@/api/report.js";

import CustomDialog from "@/views/report/components/custom-dialog.vue";

export default {
  components: { CustomDialog },

  data() {
    return {
      reportCode: "",
      authType: "",
      tableMaxHeight: "", // 表格最大高度
      columnMaxWidth: "120",
      dataColumnWidth: "",
      submitData: {
        StoresGuids: [],
        EmplGuids: [],
        BeginDate: "",
        EndDate: "",
        QueryMode: 1,
        CumulativePerfor: 1,
        ShowDonaItem: 1,
      },
      modeSelector: [
        { Name: "按月统计", Value: 2 },
        { Name: "按日统计", Value: 1 },
      ], // 显示内容列表
      storesData: [],
      storeList: [], // 门店员工列表
      copyStoreList: [], // 门店员工列表拷贝
      dayArray: [], // 日选择数据
      monthArray: [], // 月份选择数据
      topList: [], // 一级表头数据
      headList: [], // 二级表头数据
      headData: {}, // 自定义表头源数据
      heads: [], // 自定义表头
      totaList: [], // 合计数据
      dataList: [], // 表格数据
      loading: false, // 表格加载
      isFixedRow: true,
      excelDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("scroll", this.scrollMove, true);
    window.addEventListener("resize", this.getResize);

    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;

    await this.initDateState();
    await this.initEmployeeBasicClassPersonal();
    await this.fetchEmployeeBasicClassPersonal();
  },

  destroyed() {
    window.removeEventListener("scroll", this.scrollMove, true);
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化分类趋势数据
    async initEmployeeBasicClassPersonal() {
      try {
        let { data } = await Report.initEmployeeBasicClassPersonal();
        let { StoreList } = data.emplSelector;
        this.storeList = StoreList;
        this.copyStoreList = JSON.parse(JSON.stringify(StoreList));
      } catch (err) {
        console.log(err);
      }
    },

    // 获取自定义表格数据
    async fetchEmployeeBasicClassPersonal() {
      try {
        let { data } = await Report.fetchEmployeeBasicClassPersonal();
        let { Heads, reportCode } = data;
        this.headData = data;
        this.heads = Heads;
        this.reportCode = reportCode;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取分类趋势数据
    async queryEmployeeBasicClassPersonal() {
      let submitData = JSON.parse(JSON.stringify(this.submitData));
      let dataColumnWidth = 0;
      if (!this.submitData.EmplGuids.length) {
        // return this.$message.info("请选择员工");
      } else if (this.loading) {
        return;
      }

      submitData.EmplGuid = submitData.EmplGuids[0];
      delete submitData.EmplGuids;
      if (submitData.QueryMode == 2) {
        submitData.BeginDate += "-01";
        submitData.EndDate += "-01";
      }
      this.loading = true;
      try {
        let { data } = await Report.queryEmployeeBasicClassPersonal(submitData);
        let { TopList, HeadList, DataList, Total } = data;
        // await this.tableColumnMaxWidth(HeadList);
        this.topList = TopList;
        this.headList = HeadList;
        this.dataList = DataList;
        this.totaList = Total;

        this.$nextTick(() => {
          setTimeout(() => {
            this.dataColumnWidth = this.$refs["tb-col"][0].offsetWidth + 28;
          }, 100);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 保存表头数据
    async saveEmployeeBasicClassPersonal() {
      try {
        let { errcode } = await Report.saveEmployeeBasicClassPersonal({
          Heads: this.heads,
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.fetchEmployeeBasicClassPersonal();
          this.queryEmployeeBasicClassPersonal();
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 60);
    },

    // 滚动条联动
    scrollMove(event) {
      let that = this;
      let table = that.$refs.table.bodyWrapper; // table表格dom
      let summary = that.$refs.summary; // 合计行dom
      if (event.target.className == "summary-box") {
        table.scrollLeft = summary.scrollLeft;
      } else {
        summary.scrollLeft = table.scrollLeft;
      }
    },

    // tableColumnMaxWidth(headList) {
    //   let sum = 0;
    //   for (let i = 0; i < headList.length; i++) {
    //     if (headList[i].TopCode != "Employee") {
    //       break;
    //     }
    //     if (headList[i].DataKey == "StoresName") {
    //       sum += 210;
    //     } else {
    //       sum += 120;
    //     }
    //   }
    //   this.$nextTick(() => {
    //     // sum += 200
    //     this.columnMaxWidth = sum.toString();
    //     // console.log(sum.toString());
    //     // return sum.toString();
    //   });
    // },

    // 查询按钮点击事件
    onSearchData() {
      this.queryEmployeeBasicClassPersonal();
    },

    async onRefreshCustom() {
      await this.fetchEmployeeBasicClassPersonal();
      await this.onCustomTable();
    },

    // 打开自定义列对话框
    onCustomTable() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    // 保存确认按钮点击事件
    onSubmitRevenue(event) {
      let { Heads } = event;
      this.heads = Heads;
      this.saveEmployeeBasicClassPersonal();
    },

    // 员工选中事件
    onSelectedEmpl(event) {
      // console.log(event);
      this.storeList = event.storeList;
      this.submitData.EmplGuids = event.emplGuids;
    },

    // 初始化日期状态
    async initDateState() {
      let {
        beginDay,
        endDay,
        beginMonth,
        endMonth,
      } = await this.$store.dispatch("onGetDateState");
      this.dayArray = [beginDay, endDay];
      this.monthArray = [beginMonth, endMonth];
      this.onChangeRadio(this.submitData.QueryMode);
    },

    // 门店改变事件
    onChangeStore(event) {
      let storeList = JSON.parse(JSON.stringify(this.storeList));
      let copyStoreList = JSON.parse(JSON.stringify(this.copyStoreList));
      let emplGuids = JSON.parse(JSON.stringify(this.submitData.EmplGuids));

      if (event.length == 0) {
        storeList = copyStoreList;
      } else {
        storeList.forEach((item, index) => {
          for (let i = 0; i < event.length; i++) {
            if (event[i] == item.StoresGuid) {
              storeList[index].isSelected = true;
              break;
            } else if (item.activeNumber) {
              storeList[index] = copyStoreList[index];
              break;
            }
            storeList[index].isSelected = false;
          }
        });
      }
      // this.submitData.EmplGuids = emplGuids;
      // this.storeList = storeList;
      this.$refs.DfEmplSelect.onSelectedEmpl({ storeList });
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch(
        "onChangeDateState",
        this.submitData.QueryMode == 2
          ? { monthTime: event }
          : { dayTime: event }
      );
      this.initDateState();
    },

    // 单选框改变事件
    onChangeRadio(event) {
      let arr = [];

      if (event == 2) {
        arr = this.monthArray;
      } else if (event == 1) {
        arr = this.dayArray;
      }

      this.submitData.BeginDate = arr[0];
      this.submitData.EndDate = arr[1];
    },

    // 自定义合计
    onTotal({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else {
          sums[index] = this.totaList[column.property];
        }
      });
      return sums;
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#table-loading", "员工项目分类趋势分析表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.class-trend {
  .df-row {
    margin-top: 20px;
    align-items: center;

    .df-col {
      .flex-row;
      align-items: unset;

      div > * {
        width: 100%;
      }

      .el-radio-group {
        .flex-row;
      }

      .el-date-picker {
        width: auto !important;
      }

      .el-select {
        min-width: 180px;
      }

      .el-button {
        width: 100%;
      }
    }
  }

  #table-loading {
    display: block;
    width: 100%;
    .el-table {
      width: 100%;
    }
  }

  .summary-box {
    width: 100%;
    height: 60px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    overflow-x: hidden;

    tbody {
      // height: 100%;
      // width: 100%;
      tr {
        // .flex-row;
        // height: 100%;
        // width: 100%;

        > div {
          height: 100%;
          border-right: 1px solid #eee;
        }

        div {
          box-sizing: border-box;
          background-color: white;
        }

        .summary-item-fixed {
          position: sticky;
          left: 0;
          top: 0;
          width: 120px;
          height: 50px;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          // border: unset;
          border-left: 1px solid #bbb;
          border-bottom: 1px solid #bbb;

          div {
            width: 120px;
            height: 100%;
            padding: 10px;
            border-right: 1px solid #bbb;
            box-sizing: border-box;
          }

          // .stores-name {
          //   width: 210px;
          // }
        }

        .summary-item {
          // width: 80px !important;
          border-bottom: 1px solid #bbb;

          > div {
            // width: 80px;
            // width: calc(var(--data-column-width) - 1px);
            // height: 100%;
            // padding: 10px 20px;
            // border-right: 1px solid #bbb;

            .flex-col;
            align-items: unset;
            justify-content: center;
            width: calc(var(--data-column-width));
            height: calc(50px - 2px);
            padding: 10px 20px;
            border-right: 1px solid #bbb;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

::v-deep .down-select {
  display: none !important;
}

@media (max-width: 1280px) {
  .class-trend {
    .df-row {
      .flex-row;
      // align-items: unset;
      flex-wrap: wrap;
      margin-block: 20px 10px;

      .df-col {
        margin-block: 0 10px;
        .el-date-editor {
          width: 240px;
        }
      }
    }
  }
}
</style>
