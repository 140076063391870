<template>
    <div>
        <div class="header">
          <MenuThird :listName="'business'"></MenuThird>
        </div>
        <div class="main">
          <router-view/>
        </div>
    </div>
</template>

<script>
import MenuThird from '@/components/menu/menu-third.vue'
export default {
    components: { MenuThird },

    directives: {  },

    data() {
			return {
					
			};
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.header{
}
.main{
    // padding-top: 20px;
    height: calc(100vh - (100px + 40px ));
    overflow-y: auto;
    box-sizing: border-box;
}
</style>