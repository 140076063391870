<template>
  <div class="df-date-picker">
    <DfShortcutDate
      :date="dateValue"
      :shortcuts="shortcuts"
      @change="onShortcutChange"
    ></DfShortcutDate>

    <el-date-picker
      v-model="dateValue"
      :size="size"
      :type="type"
      :range-separator="rangeSeparator"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :value-format="valueFormat"
      :picker-options="pickerOptions"
      @change="onChangeDate"
    >
    </el-date-picker>
  </div>
</template>

<script>
import DfShortcutDate from "./df-shortcut-date.vue";

export default {
  components: {
    DfShortcutDate,
  },
  //   model: {
  //     prop: "date",
  //     event: "change",
  //   },

  props: {
    // 日期组件配置
    date: {
      type: [Array, String],
      default: () => [],
    },
    size: {
      type: String,
      default: "mini",
    },
    type: {
      type: String,
      default: "daterange",
    },
    rangeSeparator: {
      type: String,
      default: "至",
    },
    startPlaceholder: {
      type: String,
      default: "开始日期",
    },
    endPlaceholder: {
      type: String,
      default: "结束日期",
    },
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd",
    },
    pickerOptions: {
      type: Object,
      default: () => ({
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }),
    },
    storage: {
      type: Boolean,
      default: true,
    },

    // 快捷按钮组件配置
    shortcuts: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    date: {
      handler(newVal, oldVal) {
        let newType = typeof newVal,
          oldType = typeof oldVal;
        if (
          oldType == newType &&
          (newType === "string"
            ? newVal === oldVal
            : JSON.stringify(newVal) === JSON.stringify)
        ) {
          return;
        }
        this.dateValue = newVal;
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      dateValue: "",
    };
  },

  async mounted() {
    // await this.initDateState();
  },

  methods: {
    // 初始化日期状态
    // async initDateState() {
    //   if (this.storage) {
    //     let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
    //     this.dateValue = [beginDay, endDay];
    //   } else {
    //     this.dateValue || (await this.initValue());
    //   }
    //   await this.$emit("change", this.dateValue);
    //   this.submitData.begin_date = beginDay;
    //   this.submitData.end_date = endDay;
    //   await this.checkPickerChange();
    // },

    // 初始化默认值
    // async initValue() {
    //   if (this.type === "daterange") {
    //     this.dateValue = [
    //       await this.util.getNowMonthToday(),
    //       await this.util.getNowMonthToday(),
    //     ];
    //   } else if (this.type === "date") {
    //     this.dateValue = await this.util.getNowMonthToday();
    //   }
    // },

    // 日期改变事件
    onChangeDate(event) {
      //   await this.$store.dispatch("onChangeDateState", { dayTime: event });
      //   await this.initDateState();
      this.$emit("change", event);
    },

    // 快捷按钮改变事件
    onShortcutChange(event) {
      this.$emit("shortcutClick", event);
    },
  },
};
</script>

<style lang="less" scoped>
.df-date-picker {
  .flex-row;
  flex-wrap: wrap;

  .el-date-editor {
    width: 240px;
  }
}
</style>
