<template>
  <div class="df-empl-select">
    <el-select
      popper-class="down-select"
      :popper-append-to-body="!isDialog"
      v-model="emplSelectedList"
      :multiple="selectMultiple"
      :collapse-tags="isTags"
      :size="size"
      placeholder="请选择员工"
      @focus="onShowEmplSelect"
      @remove-tag="onDeleteTag"
      @change="onChangeManager"
    >
      <el-option
        v-for="item in storeList"
        :key="item.ManagerGuid"
        :label="item.ManagerName"
        :value="item.ManagerGuid"
      >
      </el-option>
    </el-select>

    <DfEmplDialog
      v-if="isDialog"
      ref="DfEmplDialog"
      :menuMultiple="menuMultiple"
      :dutyMultiple="dutyMultiple"
      :emplMultiple="emplMultiple"
      :dataList="storeList"
      @onConfirm="onSelectedEmpl"
    ></DfEmplDialog>
  </div>
</template>

<script>
import DfEmplDialog from "./df-empl-dialog.vue";

export default {
  components: { DfEmplDialog },

  props: {
    storesGuids: {
      // 选中门店id数组
      type: Array,
      default: () => null,
    },

    emplGuids: {
      // 选中员工id数组
      type: Array,
      default: () => null,
    },

    storeList: {
      // 门店员工数据
      type: Array,
      default: () => [],
    },

    isTags: {
      // 是否启用标签模式
      type: Boolean,
      default: () => true,
    },

    isDialog: {
      // 是否启用对话框模式
      type: Boolean,
      default: () => true,
    },

    selectMultiple: {
      // 多选框是否多选
      type: Boolean,
      default: () => true,
    },

    menuMultiple: {
      // 菜单是否多选
      type: Boolean,
      default: () => false,
    },

    dutyMultiple: {
      // 职位是否多选
      type: Boolean,
      default: () => false,
    },

    emplMultiple: {
      // 员工是否多选
      type: Boolean,
      default: () => false,
    },

    size: {
      // 选择器组件的大小
      type: String,
      default: () => "small",
    },
  },

  watch: {
    emplGuids: {
      handler(newVal) {
        if (!this.isDialog && newVal != null) {
          this.emplSelectedList = newVal[0];
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      authType: "", // 当前账号等级 H 总店 M 各门店
      emplSelectedList: "", // 选中员工列表
    };
  },

  created() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
  },

  methods: {
    // 打开员工选择对话框
    onShowEmplSelect() {
      let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid");
      if (!this.isDialog) {
        return;
      }
      if (
        this.authType == "H" &&
        this.emplGuids == null &&
        this.storesGuids != null &&
        this.storesGuids.length == 0
      ) {
        return this.$message.info("请选择至少一个门店");
      } else if (
        this.authType == "H" &&
        this.storesGuids == null &&
        this.emplGuids != null &&
        this.emplGuids.length == 0
      ) {
        this.storeList.forEach((item) => {
          if (item.StoresGuid == selectStoreGuid) {
            item.isSelected = true;
          }
        });
      } else if (this.authType != "H") {
        this.storeList.forEach((item) => (item.isSelected = true));
      }
      // console.log(this.storeList);
      this.$refs.DfEmplDialog.onShowDialog();
    },

    // 下拉框改变事件
    onChangeManager(event) {
      if (this.isDialog) {
        return;
      }
      this.returnBack({ emplGuids: [event], storeList: [] });
    },

    // 员工选中事件
    onSelectedEmpl(event) {
      let storeList = event.storeList || [];
      let arr = [],
        emplGuids = [];

      storeList.forEach((storeItem) => {
        storeItem.DutyList.forEach((dutyItem) => {
          if (dutyItem.isIndeterminate || dutyItem.checkAll) {
            dutyItem.EmplList.filter((emplItem) => {
              if (emplItem.check) {
                arr.push(emplItem.EmplName);
                emplGuids.push(emplItem.EmplGuid);
              }
            });
          }
        });
      });
      //   this.storeList = storeList;
      //   this.emplGuids = emplGuids;
      this.emplSelectedList = arr;
      this.returnBack({ emplGuids, storeList });
      // console.log(this.emplSelectedList);
    },

    // 员工选择器tag删除事件
    onDeleteTag() {
      let storeList = JSON.parse(JSON.stringify(this.storeList));
      let emplGuids = JSON.parse(JSON.stringify(this.emplGuids));

      for (let i = 0; i < storeList.length; i++) {
        for (let j = 0; j < storeList[i].DutyList.length; j++) {
          for (let z = 0; z < storeList[i].DutyList[j].EmplList.length; z++) {
            if (storeList[i].DutyList[j].EmplList[z].check) {
              storeList[i].DutyList[j].EmplList[z].check = 0;

              let checkLength = storeList[i].DutyList[j].EmplList.filter(
                (emplItem) => emplItem.check
              ).length;
              // let emplLength = storeList[i].DutyList[j].EmplList.length;

              storeList[i].DutyList[j].isIndeterminate = checkLength
                ? true
                : false;
              storeList[i].DutyList[j].checkAll = false;
              storeList[i].activeNumber--;

              //   this.storeList = storeList;
              //   this.emplGuids.splice(0, 1);
              emplGuids.splice(0, 1);
              this.returnBack({ emplGuids, storeList });
              // console.log(
              //   this.submitData.EmplGuids,
              //   this.submitData.EmplGuids.length
              // );
              return;
            }
          }
        }
      }
    },

    // 父组件通信
    returnBack(param = { emplGuids: [], storeList: [] }) {
      this.$emit("selected", param);
    },
  },
};
</script>

<style lang="less" scoped>
.el-select{
  min-width: 200px;
}
.el-tag {
  display: flex;
  align-items: center;
  .el-select__tags-text {
    display: inline-block;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
