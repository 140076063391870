<template>
  <div class="surplus">
    <div class="df-row">
      <div class="df-col" v-if="authType == 'H'">
        <el-select
          v-model="submitData.StoresGuids"
          collapse-tags
          size="mini"
          multiple
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-select
          popper-class="down-select"
          :popper-append-to-body="false"
          v-model="submitData.ItemGuids"
          multiple
          collapse-tags
          size="mini"
          placeholder="请选择项目"
          @focus="onShowGoods"
          @remove-tag="onDeleteTag"
        >
          <el-option
            v-for="item in goodsList"
            :key="item.ItemGuid"
            :label="item.ItemName"
            :value="item.ItemGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="packItemSurplusStatis">查询</el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      id="exprot-table"
      :data="tableData"
      border
      stripe
      :max-height="tableMaxHeight"
    >
      <el-table-column prop="ItemNo" label="项目编号"> </el-table-column>
      <el-table-column prop="ItemName" label="项目名称"> </el-table-column>
      <el-table-column prop="SurpCount" label="剩余次数"> </el-table-column>
      <el-table-column prop="Consume" label="剩余消耗额"> </el-table-column>
      <el-table-column prop="InfiCount" label="不限次数"> </el-table-column>
    </el-table>

    <!-- 选择项目对话框 -->
    <DfGoodsDialog
      ref="DfGoodsDialog"
      :multiple="true"
      @onConfirm="onSelectItems"
    ></DfGoodsDialog>
  </div>
</template>

<script>
import Report from "@/api/report.js";

export default {
  data() {
    return {
      tableMaxHeight: 0,
      authType: "",
      submitData: {
        StoresGuids: [],
        ItemGuids: [],
      },
      storesData: [],
      goodsList: [],
      tableData: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;
    
    // this.packItemSurplusStatis();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取套餐结余统计
    async packItemSurplusStatis() {
      try {
        let { data } = await Report.packItemSurplusStatis(this.submitData);
        this.tableData = data.RecordList
      } catch (err) {
        console.log(err);
      }
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 打开项目选择对话框
    onShowGoods() {
      this.$refs.DfGoodsDialog.onShowDialog(this.goodsList);
    },

    // 选择项目事件
    onSelectItems(event) {
      // console.log(event);
      let { selectItems } = event;
      this.submitData.ItemGuids = selectItems.map((item) => item.ItemGuid);
      this.goodsList = selectItems;
    },

    // 删除下拉框tag事件
    onDeleteTag(event) {
      let { goodsList, submitData } = this,
        arr = [];

      for (let i = 0; i < submitData.ItemGuids.length; i++) {
        for (let j = 0; j < goodsList.length; j++) {
          if (goodsList[j].ItemGuid == submitData.ItemGuids[i]) {
            arr.push(goodsList[j]);
            break;
          }
        }
      }

      this.goodsList = arr;
    },
  },
};
</script>

<style lang="less" scoped>
.surplus {
  .df-row {
    flex-wrap: wrap;
    margin: 15px 0;

    .df-col {
      margin: 5px 10px 5px 0;
      .el-select {
        width: 260px;
      }
    }
  }
}

::v-deep .down-select {
  display: none !important;
}
</style>
