import Vue from "vue";
import Util from "./utils";
import Store from "../store/index";

let tempBounding = "";

Vue.directive("dialogDrag", {
  bind: function(el, binding, vnode, oldVnode) {
    // 获取到vue实例
    let that = vnode.context;
    // 获取到拖拽区
    let dragHeader = el.querySelector(".special-dialog-header");
    // 鼠标在拖拽区按下时触发拖拽
    dragHeader.onmousedown = function(e) {
      var disx = e.pageX - el.offsetLeft;
      var disy = e.pageY - el.offsetTop;

      document.onmousemove = function(e) {
        let { clientHeight, clientWidth } = Util.getDocumentElementInfo();
        let leftMax = clientWidth - el.clientWidth - 2,
          leftMin = 0,
          topMax = clientHeight - el.clientHeight - 2,
          topMin = 0;
        let left = e.pageX - disx,
          top = e.pageY - disy;

        if (left > leftMax) {
          el.style.left = leftMax + "px";
        } else if (left < leftMin) {
          el.style.left = leftMin + "px";
        } else {
          el.style.left = left + "px";
        }

        if (top > topMax) {
          el.style.top = topMax + "px";
        } else if (top < topMin) {
          el.style.top = topMin + "px";
        } else {
          el.style.top = top + "px";
        }
      };
      document.onmouseup = function() {
        document.onmousemove = document.onmouseup = null;
      };
    };
  },
});

Vue.directive("JZoom", {
  inserted: function(el) {
    const run = () => {
      //获取随机id
      const getRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
      };
      let { clientHeight, clientWidth } = Util.getDocumentElementInfo();

      const randomId = getRandom(1000000, 9999999);
      //需要添加指令的元素外层套上一层盒子
      const box = document.querySelector(".app-div");
      // const box = document.createElement("div");
      box.style.position = "relative";
      // box.style.top = "0px";
      // box.style.left = "0px";
      // box.style.zIndex = "99";
      // box.style.pointerEvents = "none"
      // box.classList = "j-zoom-box";

      el.style.position = "relative";
      const bounding = tempBounding || el.getBoundingClientRect();
      const content = document.createElement("div");
      content.classList = "j-zoom-content";
      const parentElement = el.parentElement;
      // parentElement.removeChild(el);
      content.appendChild(el);
      // console.log(document.querySelector(".special-dialog"));
      content.style.display = "block";
      content.style.position = "absolute";
      content.style.height = bounding.height + "px";
      content.style.width = bounding.width + "px";
      content.style.top = clientHeight / 2 - bounding.height / 2 + "px";
      content.style.left = clientWidth / 2 - bounding.width / 2 + "px";
      // document.querySelector(".special-dialog").style.top = 0
      // document.querySelector(".special-dialog").style.left = 0
      // bounding.top = 0
      // bounding.left = 0
      // box.style.height = bounding.height + "px";
      // box.style.width = bounding.width + "px";
      content.style.zIndex = "10";
      el.style.height = "100%";
      el.style.width = "100%";
      box.appendChild(content);
      // parentElement.appendChild(box);

      let isMouseDown = "";
      let defaultPos = {},
        mouseX = 0,
        mouseY = 0;

      const rectWidth = 10;

      function minWindow(el) {
        content.style.height = 50 + "px";
        content.style.width = 300 + "px";
        content.style.top = clientHeight - 40 + "px";
        content.style.left = "2px";
        isMouseDown = "bottomRight";
        hornMouseDown(el, "bottomRight");
        hornMouseOver(el);
        hornMouseUp();
      }
      function maxWindow(el) {
        content.style.height = clientHeight * 0.95 + "px";
        content.style.width = clientWidth * 0.95 + "px";
        content.style.top = clientHeight / 2 - (clientHeight * 0.95) / 2 + "px";
        content.style.left = clientWidth / 2 - (clientWidth * 0.95) / 2 + "px";
        isMouseDown = "bottomRight";
        hornMouseDown(el, "bottomRight");
        hornMouseOver(el);
        hornMouseUp();
      }
      function closeWindow(el) {
        box.removeChild(content);
        // console.log(content);
      }

      function addTool() {
        let minDom = document.querySelector(".dialog-min"),
          maxDom = document.querySelector(".dialog-max"),
          closeDom = document.querySelector(".dialog-close");

        minDom.addEventListener("click", minWindow);
        maxDom.addEventListener("click", maxWindow);
        closeDom.addEventListener("click", closeWindow);
      }

      function hornMouseDown(e, flag) {
        isMouseDown = flag;
        const bounding = content.getBoundingClientRect();
        defaultPos = {
          left: parseFloat(content.style.left || 0),
          top: parseFloat(content.style.top || 0),
          width: parseFloat(content.style.width || 0),
          height: parseFloat(content.style.height || 0),
          x: bounding.x,
          y: bounding.y,
          startX: e.clientX || e.touches[0].clientX,
          startY: e.clientY || e.touches[0].clientY,
        };
        document.addEventListener("mousemove", hornMouseOver);
        document.addEventListener("mouseup", hornMouseUp);
        document.addEventListener("touchmove", hornMouseOver);
        document.addEventListener("touchend", hornMouseUp);
      }
      function hornMouseOver(event) {
        if (!isMouseDown) return;
        let clientX = event.clientX || event.touches[0].clientX,
          clientY = event.clientY || event.touches[0].clientY,
          mouseX = event.x || clientX,
          mouseY = event.y || clientY;

        let addWidth = clientX - defaultPos.startX;
        let addHeight = clientY - defaultPos.startY;

        if (isMouseDown.includes("top")) {
          addHeight = -addHeight;
        }
        if (isMouseDown.includes("Left") || isMouseDown === "left") {
          addWidth = -addWidth;
        }

        let { clientHeight, clientWidth } = Util.getDocumentElementInfo();

        const topMid = content.querySelector("#" + "topMid" + randomId);
        const leftMid = content.querySelector("#" + "leftMid" + randomId);
        const rightMid = content.querySelector("#" + "rightMid" + randomId);
        const bottomMid = content.querySelector("#" + "bottomMid" + randomId);

        if (mouseX > 0 && mouseX < clientWidth) {
          if (!["left", "right"].includes(isMouseDown)) {
            content.style.height = defaultPos.height + addHeight + "px";
            leftMid.style.height =
              defaultPos.height + addHeight - rectWidth * 2 + "px";
            rightMid.style.height =
              defaultPos.height + addHeight - rectWidth * 2 + "px";
          }
          if (isMouseDown.includes("Left") || isMouseDown === "left") {
            content.style.left =
              Math.min(
                defaultPos.left - addWidth,
                defaultPos.left + defaultPos.width
              ) + "px";
            if (
              defaultPos.left - addWidth >
              defaultPos.left + defaultPos.width
            ) {
              content.style.width = 0 + "px";
            }
          }
          if (isMouseDown.includes("Right")) {
            content.style.left = defaultPos.left + "px";
          }
        }
        if (mouseY > 0 && mouseY < clientHeight) {
          if (!["top", "bottom"].includes(isMouseDown)) {
            content.style.width = defaultPos.width + addWidth + "px";
            topMid.style.width =
              defaultPos.width + addWidth - rectWidth * 2 + "px";
            bottomMid.style.width =
              defaultPos.width + addWidth - rectWidth * 2 + "px";
          }

          if (isMouseDown.includes("top")) {
            content.style.top =
              Math.min(
                defaultPos.top - addHeight,
                defaultPos.top + defaultPos.height
              ) + "px";
            if (
              defaultPos.top - addHeight >
              defaultPos.top + defaultPos.height
            ) {
              content.style.height = 0 + "px";
            }
          }
          if (isMouseDown.includes("bottom")) {
            content.style.top = defaultPos.top + "px";
          }
        }
        // console.log(event.x, event.y);
      }

      function hornMouseUp() {
        isMouseDown = "";
        document.removeEventListener("mousemove", hornMouseOver);
        document.removeEventListener("mouseup", hornMouseUp);
        document.addEventListener("touchmove", hornMouseOver);
        document.addEventListener("touchend", hornMouseUp);
      }

      function createHornRect(position) {
        const isTop = position.includes("top");
        const rect = document.createElement("div");
        rect.style.width = rectWidth + "px";
        rect.style.height = rectWidth + "px";
        rect.style.position = "absolute";
        rect.style.background = "transparent";
        // rect.style.background = "red";
        if (isTop) rect.style.top = 0;
        else rect.style.bottom = 0;
        rect.style.left = position.includes("Left") ? "0" : "";
        rect.style.right = position.includes("Right") ? "0" : "";
        const map = {
          topLeft: "nw",
          topRight: "ne",
          bottomLeft: "sw",
          bottomRight: "se",
        };
        rect.style.cursor = map[position] + "-resize";
        return rect;
      }
      function obj2Style(dom, obj) {
        for (const k in obj) {
          dom.style[k] = obj[k];
        }
      }

      function createAndAddHorn(position) {
        const horn = createHornRect(position);
        content.appendChild(horn);
        horn.addEventListener("mousedown", (e) => {
          hornMouseDown(e, position);
        });
        horn.addEventListener("touchstart", (e) => {
          hornMouseDown(e, position);
        });
      }
      function createEdgeElement(position) {
        const edge = document.createElement("div");
        edge.id = position + "Mid" + randomId;
        let height = rectWidth,
          width = rectWidth;
        if (position === "top" || position === "bottom")
          width = bounding.width - rectWidth * 2;
        else height = bounding.height - rectWidth * 2;
        const cursorMap = {
          top: "n-resize",
          bottom: "s-resize",
          left: "w-resize",
          right: "e-resize",
        };
        // console.log(position, width, height);
        if (position == "top") {
          return edge;
        }
        obj2Style(edge, {
          [["top", "bottom"].includes(position) ? "left" : "top"]:
            rectWidth + "px",
          [position]: 0,
          width: width + "px",
          position: "absolute",
          height: height + "px",
          zIndex: "100000",
          cursor: cursorMap[position],
          background: "transparent",
          // background: "yellow",
        });
        return edge;
      }
      function createAndAddEdgeElement(position) {
        const edge = createEdgeElement(position);
        content.appendChild(edge);
        edge.addEventListener("mousedown", (e) => {
          hornMouseDown(e, position);
        });
        edge.addEventListener("touchstart", (e) => {
          hornMouseDown(e, position);
        });
      }
      function addElement() {
        ["topLeft", "topRight", "bottomLeft", "bottomRight"].forEach(
          (position) => {
            createAndAddHorn(position);
          }
        );
        ["top", "bottom", "left", "right"].forEach((position) => {
          createAndAddEdgeElement(position);
        });
      }

      addElement();
      addTool();
    };
    run();
  },
});

Vue.directive("JDrag", {
  inserted: function(el) {
    let startX,
      startY,
      currentX = 0,
      currentY = 0,
      mouseX = 0,
      mouseY = 0;

    let dragHeader = el.querySelector(".special-dialog-header");
    el.style.position = "relative";
    el.parentNode.style.position = "relative";
    dragHeader.style.cursor = "pointer";

    const content = document.querySelector(".j-zoom-content");
    const bounding = tempBounding || el.getBoundingClientRect();
    let { clientHeight, clientWidth } = Util.getDocumentElementInfo();
    content.style.display = "block";
    content.style.position = "absolute";
    content.style.height = bounding.height + "px";
    content.style.width = bounding.width + "px";
    content.style.top = clientHeight / 2 - bounding.height / 2 + "px";
    content.style.left = clientWidth / 2 - bounding.width / 2 + "px";
    content.style.zIndex = "10";

    tempBounding = JSON.parse(JSON.stringify(bounding));

    dragHeader.addEventListener("mousedown", function(event) {
      event.preventDefault();
      const parent = el.parentNode;
      if ([...parent.classList].includes("j-zoom-content")) {
        currentX = parseFloat(parent.style.left || 0);
        currentY = parseFloat(parent.style.top || 0);
      }
      startX = event.pageX - currentX;
      startY = event.pageY - currentY;
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    });
    dragHeader.addEventListener("touchstart", function(event) {
      const parent = el.parentNode;
      if ([...parent.classList].includes("j-zoom-content")) {
        currentX = parseFloat(parent.style.left || 0);
        currentY = parseFloat(parent.style.top || 0);
      }
      startX = event.touches[0].clientX - currentX;
      startY = event.touches[0].clientY - currentY;
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
    });
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    }
    function onTouchEnd() {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    }
    function onTouchMove(event) {
      currentX = event.touches[0].clientX - startX;
      currentY = event.touches[0].clientY - startY;
      mouseX = event.touches[0].clientX;
      mouseY = event.touches[0].clientY;
      drag(el);
    }
    function onMouseMove(event) {
      currentX = event.pageX - startX;
      currentY = event.pageY - startY;
      mouseX = event.x;
      mouseY = event.y;
      // console.log(mouseX, mouseY);
      drag(el);
    }
    function drag(el) {
      let { clientHeight, clientWidth } = Util.getDocumentElementInfo();
      const parent = el.parentNode;
      // console.log(currentX, currentY);

      if ([...parent.classList].includes("j-zoom-content")) {
        if (mouseX > 0 && mouseX < clientWidth) {
          parent.style.left = currentX + "px";
        }
        if (mouseY > 0 && mouseY < clientHeight) {
          parent.style.top = currentY + "px";
        }
      } else {
        if (mouseX > 0 && mouseX < clientWidth) {
          el.style.left = currentX + "px";
        }
        if (mouseY > 0 && mouseY < clientHeight) {
          el.style.top = currentY + "px";
        }
      }
    }
  },
});

Vue.directive("tooltip", {
  inserted(el, binding) {
    // console.log(el.target);
    function showTooltip(event) {
      // console.log(event.target.offsetWidth, event.target.innerText);
      // let randomStr = Math.random().toString(36).substr(3, 5)
      // !event.target.offsetParent.id ? event.target.offsetParent.id = 'tooltip-' + randomStr : null
      // let eventParent = document.querySelector("#"+event.target.offsetParent.id)
      console.log(event.target.title);
      // let tooltip = document.querySelector(".tooltip-box")
      let style = window.getComputedStyle(event.target, null),
        fontSize = style.fontSize
          ? Number(style.fontSize.substring(0, style.fontSize.length - 2))
          : 18;
      if (event.target.innerText.length * fontSize > event.target.offsetWidth) {
        // event.target['data-title'] = event.target.innerText
        console.log(event.target);
        // let tooltipBox = document.createElement("div");
        // tooltipBox.className = "tooltip-box";
        // tooltipBox.style = `position: absolute;
        // top: ${eventParent.offsetTop - 60}px;
        // left: ${eventParent.offsetLeft}px;
        // max-width: 600px;
        // padding: 5px 10px;
        // color: white;
        // word-wrap: break-word;
        // word-break: break-all;
        // background-color: rgba(0, 0, 0, 0.5);
        // border-radius: 5px;
        // box-sizing: border-box;`;
        // tooltipBox.innerText = event.target.innerText;
        // eventParent.style = 'position: relative;'
        // // event.target.appendChild(tooltipBox);
        // eventParent.appendChild(tooltipBox);
      }
      // console.log(eventParent.offsetTop);
    }
    function closeTooltip(event) {
      // let eventParent = document.querySelector("#"+event.target.offsetParent.id)
      // console.log(event.target.offsetParent.id);
      // let tooltipBox = document.querySelector(".tooltip-box");
      // if (eventParent) {
      //   eventParent.removeChild(tooltipBox);
      // }
      // mouseEnd()
    }
    el.addEventListener("mouseenter", showTooltip);
    el.addEventListener("mouseleave", closeTooltip);
    // el.removeEventListener("mouseleave", showTooltip);
  },
});
