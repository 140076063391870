<template>
  <div class="test">
    <div></div>
  </div>
</template>

<script>
export default {
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.test {
  .flex-col;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  > span {
    margin: 10px 0;
  }
}

.special-dialog {
  // position: absolute;
  // top: calc(50vh - 150px);
  // left: calc(50vw - 150px);
  width: 200px;
  height: 200px;
  border: 1px solid black;
  background-color: white;
  .div {
    width: 100%;
  }
}
</style>
