<template>
  <div>
    <div class="top">
      <div class="left">
        <div>
          <el-select v-model="managerState" placeholder="状态" size="small">
            <el-option
              v-for="(item, index) in stateList"
              :key="item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-select v-model="roleNo" placeholder="系统角色" size="small">
            <el-option
              v-for="(item, index) in roleNoList"
              :key="item.RoleNo"
              :label="item.RoleName"
              :value="item.RoleNo"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-input v-model="word" placeholder="姓名或手机号" size="small">
          </el-input>
        </div>
        <div>
          <el-button
            class="search-button"
            type="primary"
            size="small"
            @click="onSearchRole"
            >查询</el-button
          >
        </div>
      </div>

      <div class="right">
        <el-button type="danger" size="small" @click="onAddRole"
          >新增</el-button
        >
        <!-- <el-button type="danger" size="small" @click="onAlterRole">修改</el-button> -->
        <el-button type="danger" size="small" @click="onDeleteRole"
          >删除</el-button
        >
        <el-button type="danger" size="small" @click="onRecoverRole"
          >恢复</el-button
        >
        <el-button type="danger" size="small" @click="onDisableRole"
          >停用</el-button
        >
        <el-button type="danger" size="small" @click="onStoresRole"
          >分管门店</el-button
        >
        <el-button type="danger" size="small" @click="onCopyRole"
          >复制权限</el-button
        >
        <el-button type="danger" size="small" @click="onCopyRoleMore"
          >复制他人权限</el-button
        >
        <el-button type="danger" size="small" @click="onSetMoreRole"
          >多人权限批量设置</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      v-loading="loading"
      @selection-change="handleSelectionChange"
      size="small"
      style="width: 100%"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="ManagerAdmin" label="管理员账号">
      </el-table-column>
      <el-table-column prop="ManagerName" label="姓名"> </el-table-column>
      <el-table-column prop="ManagerPhone" label="手机号"> </el-table-column>
      <el-table-column prop="RoleName" label="系统角色"> </el-table-column>
      <el-table-column prop="AddTime" label="创建日期">
        <template slot-scope="scope">
          {{
            util.dateFormat("YYYY-mm-dd", new Date(scope.row.AddTime * 1000))
          }}
        </template>
      </el-table-column>
      <el-table-column prop="LoginCount" label="登录次数"> </el-table-column>
      <el-table-column prop="StoresName" label="所属门店"> </el-table-column>
      <el-table-column prop="ListStoresName" label="分管门店">
      </el-table-column>
      <el-table-column prop="SupervisionText" label="门店消费通知">
      </el-table-column>
      <el-table-column prop="ManagerNumber" label="授权卡号"> </el-table-column>
      <el-table-column prop="ManagerTypeName" label="账号类型">
      </el-table-column>
      <el-table-column prop="date" label="操作" width="290">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="onEditRole(scope.row)"
            >修改信息</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onEditPassword(scope.row)"
            >修改密码</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onEditPermissions(scope.row)"
            >权限设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <AddAdmin ref="AddAdmin" @addRoleSuccess="initPageData"></AddAdmin>
    <EditPassword ref="EditPassword"></EditPassword>
    <CopyPermissions ref="CopyPermissions"></CopyPermissions>
    <CopyPermissionsMore ref="CopyPermissionsMore"></CopyPermissionsMore>
    <AddPermissions ref="AddPermissions"></AddPermissions>
    <AddPermissionsMulti
      ref="AddPermissionsMulti"
      @addAuthoritySuccess="initPageData"
    ></AddPermissionsMulti>
    <AddStores ref="AddStores" @success="initPageData"></AddStores>
  </div>
</template>

<script>
import api from "@/api/management.js";
import AddAdmin from "./components/add-admin.vue";
import EditPassword from "./components/edit-password.vue";
import CopyPermissions from "./components/copy-permissions.vue";
import CopyPermissionsMore from "./components/copy-permissions-more.vue";
import AddPermissions from "./components/add-permissions.vue";
import AddPermissionsMulti from "./components/add-permissions-multi.vue";
import AddStores from "./components/add-stores.vue";

export default {
  components: {
    AddAdmin,
    EditPassword,
    CopyPermissions,
    CopyPermissionsMore,
    AddPermissions,
    AddPermissionsMulti,
    AddStores,
  },

  data() {
    return {
      selectRole: "",
      checked: false,
      tableData: [],
      roleNoList: [],
      stateList: [],
      managerState: 1,
      word: "",
      roleNo: "",
      storesGuid: "",
      loading: false,
      selectRoleData: [],
      storesData: [],
      authType: "",
      disabled: false,
    };
  },

  mounted() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;
    this.disabled = accountInfo.authType == "M" ? true : false;
    this.storesGuid = window.sessionStorage.getItem("selectStoreGuid");
    this.initPageData();
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { tableMaxHeight } = this.util.getTableMoreInfo(100 + 40 + 48);
      this.tableMaxHeight = tableMaxHeight;
    },

    async initPageData() {
      this.loading = true;
      try {
        let submitData = {
          manager_state: this.managerState,
          word: this.word,
          role_no: this.roleNo,
          stores_guid: this.storesGuid,
        };
        let { data } = await api.getManagerList(submitData);
        let { managerList, roleNoList, stateList } = data;
        this.tableData = managerList;
        this.roleNoList = roleNoList;
        this.stateList = stateList;
      } catch (e) {}
      this.loading = false;
    },

    onSearchRole() {
      this.initPageData();
    },

    onAddRole() {
      this.$refs.AddAdmin.openAddAdmin();
    },

    onAlterRole() {
      if (this.selectRoleData.length == 0 || this.selectRoleData.length > 1) {
        let text =
          this.selectRoleData.length == 0
            ? "请勾选管理员!"
            : "只能选择一个管理员!";
        return this.$message.error(text);
      }
      this.$refs.AddAdmin.openAddAdmin(this.selectRoleData[0]);
    },

    onDeleteRole() {
      if (this.selectRoleData.length == 0 || this.selectRoleData.length > 1) {
        let text =
          this.selectRoleData.length == 0
            ? "请勾选管理员!"
            : "只能选择一个管理员!";
        return this.$message.error(text);
      }

      this.$confirm(`是否删除`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.deleteManager({
              manager_guid: this.selectRoleData[0].ManagerGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onRecoverRole() {
      if (this.selectRoleData.length == 0 || this.selectRoleData.length > 1) {
        let text =
          this.selectRoleData.length == 0
            ? "请勾选管理员!"
            : "只能选择一个管理员!";
        return this.$message.error(text);
      }

      this.$confirm(`是否恢复`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.recoverManager({
              manager_guid: this.selectRoleData[0].ManagerGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "恢复成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onDisableRole() {
      if (this.selectRoleData.length == 0 || this.selectRoleData.length > 1) {
        let text =
          this.selectRoleData.length == 0
            ? "请勾选管理员!"
            : "只能选择一个管理员!";
        return this.$message.error(text);
      }

      this.$confirm(`是否停用`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.disableManager({
              manager_guid: this.selectRoleData[0].ManagerGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "停用成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onStoresRole() {
      if (this.selectRoleData.length == 0 || this.selectRoleData.length > 1) {
        let text =
          this.selectRoleData.length == 0
            ? "请勾选管理员!"
            : "只能选择一个管理员!";
        return this.$message.error(text);
      }

      this.$refs.AddStores.onShowDialog(this.selectRoleData[0]);
    },

    onCopyRole() {
      this.$refs.CopyPermissions.openCopyPermissions(this.tableData);
    },

    onCopyRoleMore() {
      if (this.selectRoleData.length == 0) {
        return this.$message.error("请勾选管理员!");
      }
      this.$refs.CopyPermissionsMore.openCopyPermissions(
        this.tableData,
        this.selectRoleData
      );
    },

    onSetMoreRole() {
      if (this.selectRoleData.length == 0) {
        return this.$message.error("请勾选管理员!");
      }
      this.$refs.AddPermissionsMulti.openAddPermissions(this.selectRoleData);
    },

    handleSelectionChange(event) {
      this.selectRoleData = event;
    },

    onEditRole(event) {
      this.$refs.AddAdmin.openAddAdmin(event);
    },

    onEditPassword(event) {
      this.$refs.EditPassword.openEditPassword(event);
    },

    onEditPermissions(event) {
      this.$refs.AddPermissions.openAddPermissions(event);
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  .flex-row;
  justify-content: space-between;
  margin-bottom: 20px;

  .left {
    .flex-row;
    > div {
      margin-right: 10px;
    }
  }
  .right {
    .flex-row;
    justify-content: flex-end;
  }
}

.checkbox {
  margin-right: 10px;
}

@media (max-width: 1366px) {
  .top {
    flex-wrap: wrap;
    .right {
      margin-top: 10px;
    }
  }
}
</style>
