import { request } from "../utils/axios";

export default {
  businessRevenue(params = {}) {
    return request("get", "/report/business_revenue", params);
  },

  saveBusinessRevenue(params = {}) {
    return request(
      "post",
      "/report/business_revenue?subact=save_custom",
      params
    );
  },

  queryBusinessRevenue(params = {}) {
    return request(
      "post",
      "/report/business_revenue?subact=query_data",
      params
    );
  },

  categoryStatis(params = {}) {
    return request("get", `/report/category_statis`, params);
  },

  businessSummary(params = {}) {
    return request("get", `/report/business_summary`, params);
  },

  initEmployeePerforStatis(params = {}) {
    return request("get", `/report/employee_perfor_statis`, params);
  },

  getEmployeePerforStatis(params = {}) {
    return request("get", `/report/employee_perfor_statis`, params);
  },

  businesStoresStatis(params = {}) {
    return request("get", `/report/business_stores_statis`, params);
  },

  initBusinessStoresStatis(params = {}) {
    return request("get", `/report/business_stores_statis`, params);
  },

  employeePerforStoresStatis(params = {}) {
    return request("get", `/report/employee_perfor_stores_statis`, params);
  },

  initEmployeePerforStoresStatis(params = {}) {
    return request(
      "post",
      `/report/employee_perfor_stores_statis?subact=query_data`,
      params
    );
  },

  // 报表 ==> 员工提成汇总表

  initEmplRoyaStatic(params = {}) {
    return request("get", `/report/employee_roya_statis`, params);
  },

  getEmplRoyaStatic(params = {}) {
    return request("get", `/report/employee_roya_statis`, params);
  },

  // 报表 ==> 跨店消费统计表

  initCrossStoresFinance(params = {}) {
    return request(
      "get",
      `/report/cross_stores_finance?subact=init_data`,
      params
    );
  },

  fetchCrossStoresFinance(params = {}) {
    return request(
      "get",
      `/report/cross_stores_finance?subact=fetch_custom`,
      params
    );
  },

  queryCrossStoresFinance(params = {}) {
    return request(
      "post",
      `/report/cross_stores_finance?subact=query_data`,
      params
    );
  },

  saveCrossStoresFinance(params = {}) {
    return request(
      "post",
      `/report/cross_stores_finance?subact=save_custom`,
      params
    );
  },

  // 报表 ==> 员工项目分类汇总表

  initEmplBasicClassStatis(params = {}) {
    return request(
      "get",
      `/report/employee_basic_class_statis?subact=init_data`,
      params
    );
  },

  fetchEmplBasicClassStatis(params = {}) {
    return request(
      "get",
      `/report/employee_basic_class_statis?subact=fetch_custom`,
      params
    );
  },

  saveEmplBasicClassStatis(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_statis?subact=save_custom`,
      params
    );
    // return request('post', `/report/employee_basic_class_statis?subact=save_custom`, {Heads: []});
  },

  queryEmplBasicClassStatis(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_statis?subact=query_data`,
      params
    );
  },

  clearCustomHead(params = {}) {
    return request("get", `/report/clear_custom_head`, params);
  },

  // 报表 ==> 项目分类统计明细
  initBasicClassDetailStatis(params = {}) {
    return request(
      "get",
      `/report/basic_class_detail_statis?subact=init_data`,
      params
    );
  },

  queryBasicClassDetailStatis(params = {}) {
    return request(
      "post",
      `/report/basic_class_detail_statis?subact=query_data`,
      params
    );
  },

  // 报表 ==> 现金业绩

  queryfinanceProfitByCash(params = {}) {
    return request("get", `/report/finance_profit_by_cash`, params);
  },

  // 报表 ==> 项目分类付款统计

  queryBasicClassPaymentStatis(params = {}) {
    return request("get", `/report/basic_class_payment_statis`, params);
  },

  // 报表 ==> 员工业绩分析表

  initEmplPerforAnalyse(params = {}) {
    return request(
      "get",
      `/report/employee_perfor_analyse?subact=init_data`,
      params
    );
  },

  fetchEmplPerforAnalyse(params = {}) {
    return request(
      "get",
      `/report/employee_perfor_analyse?subact=fetch_custom`,
      params
    );
  },

  saveEmplPerforAnalyse(params = {}) {
    return request(
      "post",
      `/report/employee_perfor_analyse?subact=save_custom`,
      params
    );
  },

  queryEmplPerforAnalyse(params = {}) {
    return request(
      "post",
      `/report/employee_perfor_analyse?subact=query_data`,
      params
    );
  },

  // 报表 ===> 员工分类业绩月度统计表

  initEmployeeBasicClassMonthly(params = {}) {
    return request(
      "get",
      `/report/employee_basic_class_monthly?subact=init_data`,
      params
    );
  },

  queryEmployeeBasicClassMonthly(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_monthly?subact=query_data`,
      params
    );
  },

  // 报表 ===> 会员消费分析

  initMemberExpenseStatis(params = {}) {
    return request(
      "get",
      `/report/member_expense_statis?subact=init_data`,
      params
    );
  },

  queryMemberExpenseStatis(params = {}) {
    return request(
      "post",
      `/report/member_expense_statis?subact=query_data`,
      params
    );
  },

  // 报表 ===> 自定义消费分类分析
  fetchHeadMemberExpenseAmountStatis(params = {}) {
    return request(
      "get",
      `/report/member_expense_amount_statis?subact=fetch_head`,
      params
    );
  },

  saveHeadMemberExpenseAmountStatis(params = {}) {
    return request(
      "post",
      `/report/member_expense_amount_statis?subact=save_head`,
      params
    );
  },

  fetchParamExpenseAmountStatis(params = {}) {
    return request(
      "get",
      `/report/member_expense_amount_statis?subact=fetch_param`,
      params
    );
  },

  saveParamExpenseAmountStatis(params = {}) {
    return request(
      "post",
      `/report/member_expense_amount_statis?subact=save_param`,
      params
    );
  },

  queryExpenseAmountStatis(params = {}) {
    return request(
      "get",
      `/report/member_expense_amount_statis?subact=query_data`,
      params
    );
  },

  // 报表 ===> 会员消费分类分析

  fetchHeadMemberBasicClassStatis(params = {}) {
    return request(
      "get",
      `/report/member_basic_class_statis?subact=fetch_head`,
      params
    );
  },

  saveHeadMemberBasicClassStatis(params = {}) {
    return request(
      "post",
      `/report/member_basic_class_statis?subact=save_head`,
      params
    );
  },

  fetchParamMemberBasicClassStatis(params = {}) {
    return request(
      "get",
      `/report/member_basic_class_statis?subact=fetch_param`,
      params
    );
  },

  saveParamMemberBasicClassStatis(params = {}) {
    return request(
      "post",
      `/report/member_basic_class_statis?subact=save_param`,
      params
    );
  },

  queryMemberBasicClassStatis(params = {}) {
    return request(
      "get",
      `/report/member_basic_class_statis?subact=query_data`,
      params
    );
  },

  // 报表 ===> 项目分类趋势

  initEmployeeBasicClassPersonal(params = {}) {
    return request(
      "get",
      `/report/employee_basic_class_personal?subact=init_data`,
      params
    );
  },

  fetchEmployeeBasicClassPersonal(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_personal?subact=fetch_custom`,
      params
    );
  },

  queryEmployeeBasicClassPersonal(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_personal?subact=query_data`,
      params
    );
  },

  saveEmployeeBasicClassPersonal(params = {}) {
    return request(
      "post",
      `/report/employee_basic_class_personal?subact=save_custom`,
      params
    );
  },

  // 报表 ===> 项目消费表

  initWorkSellBillRecord(params = {}) {
    return request(
      "get",
      `/report/work_sell_bill_record?subact=init_data`,
      params
    );
  },

  queryWorkSellBillRecord(params = {}, isExport) {
    return request(
      "post",
      `/report/work_sell_bill_record?subact=query_data${
        isExport ? "&export=1" : ""
      }`,
      params
    );
  },

  // 报表 ===> 开充卡

  initOpenKeepBillRecord(params = {}) {
    return request(
      "get",
      `/report/open_keep_bill_record?subact=init_data`,
      params
    );
  },

  queryOpenKeepBillRecord(params = {}, isExport) {
    return request(
      "post",
      `/report/open_keep_bill_record?subact=query_data${
        isExport ? "&export=1" : ""
      }`,
      params
    );
  },

  // 报表 ===> 套餐结余统计

  packItemSurplusStatis(params = {}) {
    return request("post", `/report/pack_item_surplus_statis`, params);
  },

  // 报表 ===> 工资月报表
  getFinalStatis(params = {}) {
    return request("get", `/salary/get_final_statis`, params);
  },

  getBasicData(params = {}) {
    return request("get", `/salary/get_basic_data`, params);
  },

  importRoyaData(params = {}) {
    return request("get", `/salary/import_roya_data`, params);
  },

  deleteRoyaData(params = {}) {
    return request("get", `/salary/delete_roya_data`, params);
  },

  importBorrowData(params = {}) {
    return request("get", `/salary/import_borrow_data`, params);
  },

  deleteBorrowData(params = {}) {
    return request("get", `/salary/delete_borrow_data`, params);
  },

  generateBasicData(params = {}) {
    return request("get", `/salary/generate_basic_data`, params);
  },

  saveBasicData(params = {}) {
    return request("post", `/salary/save_basic_data`, params);
  },

  deleteSingleBasicData(params = {}) {
    return request("get", `/salary/delete_single_basic_data`, params);
  },

  deleteAllBasicData(params = {}) {
    return request("get", `/salary/delete_all_basic_data`, params);
  },

  // 报表 ===> 工资项目设置
  initSetting(params = {}) {
    return request("get", `/salary/init_setting`, params);
  },

  getDutyObjects(params = {}) {
    return request("get", `/salary/get_duty_objects`, params);
  },

  saveDutyObjects(params = {}) {
    return request("post", `/salary/save_duty_objects`, params);
  },

  saveHiddenObjects(params = {}) {
    return request("post", `/salary/save_hidden_objects`, params);
  },
};
