<template>
  <div class="setting">
    <div class="setting-item">
      <div class="title">薪资项目设置</div>
      <div class="content-box">
        <div class="content-item df-row">
          <div class="df-col">
            <span>员工职位</span>
          </div>

          <div class="df-col">
            <el-select
              v-model="itemSettingSearchData.duty_no"
              placeholder="选择职位"
              size="mini"
              @change="getDutyObjects"
            >
              <el-option
                v-for="item in dutyList"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </div>

          <div class="df-col">
            <el-button type="primary" size="mini" @click="saveDutyObjects"
              >保存</el-button
            >
          </div>
        </div>

        <div class="content-item df-row">
          <div class="df-col">
            <span>基础计算对象</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in dutyBasicCalcuSelectors"
              :key="index"
              v-model="item.Checked"
              :indeterminate="false"
              >{{ item.Name }}</el-checkbox
            >
          </div>
        </div>

        <!-- <div class="content-item df-row">
          <div class="df-col">
            <span>以业绩计算提成</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in obj1.calcDona"
              :key="index"
              v-model="item.value"
              :true-label="1"
              :false-label="0"
              :indeterminate="false"
              @change=""
              >{{ item.name }}</el-checkbox
            >
          </div>
        </div> -->
      </div>
    </div>

    <div class="setting-item">
      <div class="title">薪资项目显示列</div>
      <div class="content-box">
        <div class="content-item df-row">
          <div class="df-col">
            <el-button type="primary" size="mini" @click="saveHiddenObjects"
              >保存</el-button
            >
          </div>
        </div>

        <div class="content-item df-row">
          <div class="df-col">
            <span>显示基础计算对象列</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in hidden.BasicCalcuSelectors"
              :key="index"
              v-model="item.Checked"
              :indeterminate="false"
              >{{ item.Name }}</el-checkbox
            >
          </div>
        </div>

        <!-- <div class="content-item df-row">
          <div class="df-col">
            <span>显示以业绩计算提成列</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in obj2.calcDonaCol"
              :key="index"
              v-model="item.value"
              :true-label="1"
              :false-label="0"
              :indeterminate="false"
              @change=""
              >{{ item.name }}</el-checkbox
            >
          </div>
        </div>

        <div class="content-item df-row">
          <div class="df-col">
            <span>显示以业绩分别计算指定及轮牌提成列</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in obj2.calcPerforCol"
              :key="index"
              v-model="item.value"
              :true-label="1"
              :false-label="0"
              :indeterminate="false"
              @change=""
              >{{ item.name }}</el-checkbox
            >
          </div>
        </div>

        <div class="content-item df-row">
          <div class="df-col">
            <span>显示以开充卡计算业绩提成列</span>
          </div>

          <div class="df-col group">
            <el-checkbox
              v-for="(item, index) in obj2.calcCardPerforCol"
              :key="index"
              v-model="item.value"
              :true-label="1"
              :false-label="0"
              :indeterminate="false"
              @change=""
              >{{ item.name }}</el-checkbox
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Report from "@/api/report";

export default {
  data() {
    return {
      itemSettingSearchData: {
        duty_no: "",
      },
      itemSettingSubmitData: {},
      dutyList: [],
      hidden: {
        BasicCalcuSelectors: [],
      },
      dutyBasicCalcuSelectors: [],
    };
  },

  async mounted() {
    await this.initSetting();
    await this.getDutyObjects();
  },

  methods: {
    // 初始化设置
    async initSetting() {
      try {
        let { data } = await Report.initSetting();
        this.dutyList = data.dutyList;
        this.hidden = data.hidden;
        this.itemSettingSearchData.duty_no = this.dutyList[0].DutyNo;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取薪资项目设置
    async getDutyObjects() {
      try {
        let { data } = await Report.getDutyObjects(this.itemSettingSearchData);
        this.dutyBasicCalcuSelectors = data.BasicCalcuSelectors;
      } catch {
        console.log(err);
      }
    },

    // 保存薪资项目显示列
    async saveHiddenObjects() {
      //   console.log(this.hidden.BasicCalcuSelectors);
      try {
        let { errcode } = await Report.saveHiddenObjects(this.hidden);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initSetting();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 保存薪资项目设置
    async saveDutyObjects() {
      //   console.log(this.dutyBasicCalcuSelectors);
      try {
        let { errcode } = await Report.saveDutyObjects({
          DutyNo: this.itemSettingSearchData.duty_no,
          BasicCalcuSelectors: this.dutyBasicCalcuSelectors,
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.getDutyObjects();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  position: relative;
  height: 100%;

  &,
  .setting-item {
    .flex-col;
    align-items: unset;
  }

  .setting-item {
    padding: 0 10px;
    .title {
      padding: 10px 10px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }

    .content-box {
      .df-row {
        margin-top: 20px;
      }
      .content-item {
        .flex-row;
        align-items: unset;
        margin-inline: 10px;
        box-sizing: border-box;

        .group {
          width: 800px;
          .el-checkbox {
            margin-bottom: 10px;
          }
        }

        .el-select {
          //   width: 400px;
          .el-textarea {
            margin-bottom: 10px;
          }
        }

        .name {
          margin-left: 5px;
        }

        .no-use {
          margin-left: 10px;
          color: tomato;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .tips {
        margin-left: 10px;
        color: #999;
      }
    }
  }

  .save-btn {
    // .flex-row;
    // justify-content: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    margin: 20px 0;
    width: calc(- (50% / 2));
  }
}
</style>
