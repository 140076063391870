<template>
  <div class="class-detail-statis">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <div class="df-col">
        <!-- 日期选择器 -->
        <DfDatePicker
          :date="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :shortcuts="['today', 'yesterday', 'month', 'lastMonth']"
          @change="onChangeDate"
          @shortcutClick="onShortcutClick"
        ></DfDatePicker>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.PaymentGuids"
          multiple
          collapse-tags
          size="mini"
          placeholder="不限付款方式"
        >
          <el-option
            v-for="item in paymentList"
            :key="item.PaymentGuid"
            :label="item.PaymentName"
            :value="item.PaymentGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button
          type="primary"
          size="mini"
          @click="queryBasicClassDetailStatis"
        >
          查询
        </el-button>
      </div>
      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <!-- start 项目分类统计明细表 -->
    <el-table
      id="exprot-table"
      :data="recordList"
      show-summary
      :summary-method="getSummaries"
      :span-method="objectSpanMethod"
      :max-height="tableMaxHeight"
      stripe
      v-loading="loading"
    >
      <el-table-column
        header-align="center"
        prop="ParentsBasicName"
        label="项目分类"
        :width="adaptiveDefault.tableColumnWidth.parentsBasicName"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsTotalCount"
        label="总个数"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsTotalPerfor"
        label="总业绩"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsAvgPrice"
        label="项目单价"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsAssignCount"
        label="指定数"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsAssignPerfor"
        label="指定业绩"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column
        header-align="center"
        prop="ParentsAssignRate"
        label="指定率"
        :width="adaptiveDefault.tableColumnWidth.other"
      ></el-table-column>
      <el-table-column header-align="center" label="项目明细">
        <el-table-column
          header-align="center"
          prop="ItemName"
          label="服务项目"
          :width="adaptiveDefault.tableColumnWidth.itemName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="TotalCount"
          label="客次"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="TotalPerfor"
          label="客业绩"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="AvgPrice"
          label="客单价"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="AssignCount"
          label="指定客"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="AssignPerfor"
          label="指定业绩"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="NotAssignCount"
          label="非指定客"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="NotAssignPerfor"
          label="非指定业绩"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="MaleCount"
          label="男客"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="MalePerfor"
          label="男客业绩"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="FemaleCount"
          label="女客"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
        <el-table-column
          header-align="center"
          prop="FemalePerfor"
          label="女客业绩"
          :width="adaptiveDefault.tableColumnWidth.other"
        ></el-table-column>
      </el-table-column>
    </el-table>
    <!-- end 项目分类统计明细表 -->
  </div>
</template>

<script>
import Report from "@/api/report";

export default {
  components: {},

  data() {
    return {
      submitData: {
        // 提交数据
        BeginDate: "", // 开始日期
        EndDate: "", // 结束日期
        PaymentGuids: [], // 支付方式guid
      },
      paymentList: [],
      multipleDate: [], // 日期选择器数据
      recordList: [], // 表格内容数据列表
      tableData: [], // 表格单元格矩阵
      tableMaxHeight: "", // 表格最大高度
      loading: false, // 表格加载
      excelDisabled: false, // 导出按钮禁用

      mediaWidthAdaptive: {
        "1000": {
          tableColumnWidth: {
            parentsBasicName: "120",
            itemName: "120",
            other: "70",
          },
        },
        "1280": {
          tableColumnWidth: {
            parentsBasicName: "120",
            itemName: "120",
            other: "80",
          },
        },
        "1920": {
          tableColumnWidth: {
            parentsBasicName: "120",
            itemName: "120",
            other: "",
          },
        },
      },
      adaptiveDefault: {
        tableColumnWidth: {
          parentsBasicName: "",
          itemName: "",
          other: "",
        },
      },
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initBasicClassDetailStatis();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight, clientWidth } = this.util.getDocumentElementInfo();
      let tableMaxHeight = clientHeight - (100 + 40 + 68);

      this.tableMaxHeight = tableMaxHeight > 400 ? tableMaxHeight : 400;
      for (let key in this.mediaWidthAdaptive) {
        // console.log(Number(key));
        if (clientWidth <= Number(key)) {
          this.adaptiveDefault = {};
          this.adaptiveDefault = JSON.parse(
            JSON.stringify(this.mediaWidthAdaptive[key])
          );
          break;
        }
      }
    },

    // 初始化项目分类数据
    async initBasicClassDetailStatis() {
      try {
        let { data } = await Report.initBasicClassDetailStatis();
        this.paymentList = data.paymentList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取项目分类统计数据
    async queryBasicClassDetailStatis() {
      this.loading = true;
      try {
        let { data, errcode } = await Report.queryBasicClassDetailStatis(
          this.submitData
        );
        let recordList = data.RecordList;
        let arr = [];

        recordList.forEach((item1, index1) => {
          let itemsLength = item1.DetailItems.length;
          /* 由于接口返回的RecordList父级字段和子级字段重名了，所以需要重置赋值，
          子级字段不做改动，父级字段重置为 Parents + 原字段名，DetailItems可以不改 */
          let param = {
            ParentsAssignCount: item1.AssignCount,
            ParentsAssignPerfor: item1.AssignPerfor,
            ParentsAssignRate: item1.AssignRate,
            ParentsAvgPrice: item1.AvgPrice,
            ParentsBasicName: item1.BasicName,
            ParentsBasicGuid: item1.BasicGuid,
            DetailItems: item1.DetailItems,
            ParentsTotalCount: item1.TotalCount,
            ParentsTotalPerfor: item1.TotalPerfor,
          };

          item1.DetailItems.forEach((item2, index2) => {
            let childParam = {
              ...item2,
              RecordItemLength: itemsLength,
            };
            if (index2 == 0) {
              childParam = {
                ...param,
                ...childParam,
              };
            }

            delete childParam.DetailItems;
            arr.push(childParam);
          });

          !itemsLength ? arr.push({ ...param, RecordItemLength: 1 }) : "";
        });
        this.recordList = arr;
        this.twoDimensionalArray();
      } finally {
        this.loading = false;
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    async onChangeDate(event) {
      await this.$store.dispatch("onChangeDateState", { dayTime: event });
      await this.initDateState();
    },

    async onShortcutClick(event) {
      await this.onChangeDate(event);
      await this.initPageData();
    },

    // 自定义el-table合计函数
    getSummaries(param) {
      let { columns, data } = param;
      let sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        let values = data.map((item) => Number(item[column.property]));

        // 判断 当前是员工明细表时我只合计总提成的数值，当前是员工汇总表时我合计所有行的数值，并且要保留小数点后两位数
        if (column.property != "ItemName") {
          // console.log(values);
          sums[index] = values.reduce((prev, curr) => {
            let value = parseFloat(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);

          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
      });
      this.sumsList = sums;
      return sums;
    },

    // 自定义二维数组（注意：这里是表格渲染数据的重中之重，如要修改请慎重）
    twoDimensionalArray() {
      let list = this.recordList; // 当前明细表数据
      let length = list.length; // 列表长度（表格一共多少行）

      let arr = []; // 用来存储数组矩阵

      // 初始化矩阵数组
      for (let x = 0; x < length; x++) {
        arr[x] = [];
        for (var y = 0; y < 2; y++) {
          arr[x][y] = {
            rowspan: 1,
            colspan: 1,
          };
        }
      }

      let count = 0; // 计数器（合并的大行里面的第几个小行）
      let zLenght = 19; // 常量（从1开始数，表格一共多少列，这个得自己数）

      // x就是arr数组的一级下标（当前表格第几行）
      for (var x = 0; x < length; x++) {
        let RecordItemLength = list[x].RecordItemLength || 1; // 当前项目的长度（同一个职位同一个员工，有多少条项目记录，也就是合并的大行里面有多少个小行）

        // y就是arr数组的二级下标（当前表格第几列）
        for (var y = 0; y < zLenght; y++) {
          if (y < 7 && count == 0) {
            // 表格第1列、第7列之后的合并行处理
            // let width = list[row].items.length || 0;
            arr[x][y] = {
              rowspan: RecordItemLength, // 同职位同员工的数据需要占用几行
              colspan: 1, // 需要占用几列
            };
          } else if (y < 7 && count > 0) {
            // 表格第1列、第7列之后被合并行占用了多少行，那么下面被占用的行也是有矩阵数组表示的
            // 那么被占用的行就要输出 { rowspan: 0, colspan: 0,} 来表示空白行不渲染
            arr[x][y] = {
              rowspan: 0,
              colspan: 0,
            };
          } else {
            // 其他行正常就是 { rowspan: 1, colspan: 1,} 表示只占用1行1列
            arr[x][y] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        }

        count++;

        if (RecordItemLength == count) {
          // 当表格的每一大行的所有小行都循环完了，要重置小行计数
          count = 0;
        }
      }
      this.tableData = arr;
    },

    // 自定义合并行回调函数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      return this.tableData[rowIndex][columnIndex];
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "项目分类统计明细表");
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.class-detail-statis {
  .df-row {
    flex-wrap: wrap;
    margin: 15px 0;
    .df-col {
      margin: 5px 10px 5px 0;
    }
  }
}
</style>
