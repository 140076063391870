<template>
  <div class="send">
    <div class="top">
      <el-select
        v-model="selectDropdownValue"
        placeholder="全部模块"
        size="mini"
      >
        <el-option
          v-for="item in selectOptionData"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="header">
        <div class="th">
          <div class="td">
            <span>总类</span>
          </div>
          <div class="td">
            <span>类型</span>
          </div>
          <div class="td">
            <span>通知内容</span>
          </div>
          <div class="td">
            <span>自定义选择发送模块</span>
          </div>
        </div>
      </div>
      <div class="tbody">
        <div class="th">
          <div class="td">
            <span>消费/开冲卡</span>
          </div>
          <!--  -->
          <div class="td">
            <div class="col-1">
              <span>会员消费通知</span>
            </div>
            <div class="col-1">
              <samp>散客消费通知</samp>
            </div>
            <div class="col-1">
              <samp>开充卡</samp>
            </div>
          </div>
          <!--  -->
          <div class="td">
            <div class="col-1">
              <div class="col-1-text1">
                <span>
                  您在【店铺名称】消费【xx】元，订单编号：【xx】【xx】【xx】，其中【xx】卡⾦消费：【xx】元（余额：【xx】元、赠⾦：【xx】元）、现⾦：【xx】元、银联：【xx】元、⽀付宝：【xx】元、微信：【xx】元、团购：【xx】元、现⾦券：【xx】元、免单：【xx】元、⽋款：【xx】元。服务员工：【xx】、【xx】。【xx】
                </span>
                <div class="btn-box">
                  <el-button type="info" size="mini" @click="onEdit"
                    >修改</el-button
                  >
                </div>
              </div>
              <div class="col-1-text2">
                今日发送：0
              </div>
              <div class="col-1-text3">
                本月发送：0
              </div>
            </div>
            <div class="col-1">
              <div class="col-1-text1">
                您在【店铺名称】办理【会员卡名】，余额：【xx】元，赠金：【xx】元，如有疑问请联系【店铺联系电话】。
              </div>
            </div>
            <div class="col-1">
              <div class="col-1-text1">
                您在【店铺名称】办理【会员卡名】，余额：【xx】元，赠金：【xx】元，如有疑问请联系【店铺联系电话】。
              </div>
              <div class="col-1-text2">今日发送：0</div>
              <div class="col-1-text3">本月发送：0</div>
            </div>
          </div>
          <!--  -->
          <div class="td">
            <div class="col-1">
              <div class="col-1-text1">
                <el-checkbox v-model="checked">结账发送短信通知</el-checkbox>
                <el-checkbox v-model="checked">结账后提示发送通知</el-checkbox>
                <el-checkbox v-model="checked"
                  >消单后再结账不发送通知</el-checkbox
                >
              </div>
              <div class="col-1-text2"></div>
              <div class="col-1-text3">
                <el-checkbox v-model="checked">消费疗程卡发送通知</el-checkbox>
              </div>
            </div>
            <!--  -->
            <div class="col-1">
              <div class="col-1-text1">
                <el-checkbox v-model="checked">散客发送</el-checkbox>
              </div>
            </div>
            <!--  -->
            <div class="col-1">
              <div class="col-1-text1">
                <el-checkbox v-model="checked">储值卡开卡发送通知</el-checkbox>
              </div>
              <div class="col-1-text2">
                <el-checkbox v-model="checked">疗程卡开卡发送通知</el-checkbox>
              </div>
              <div class="col-1-text3">
                <el-checkbox v-model="checked">充值发送通知</el-checkbox>
                <el-checkbox v-model="checked">充值后提示发送通知</el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end 表格 -->
    <add-dialog ref="dialog"></add-dialog>
  </div>
</template>

<script>
import addDialog from "./components/add-dialog.vue";
export default {
  name: "send",
  components: { addDialog },
  data() {
    return {
      selectOptionData: [
        { value: 1, label: "微信通知" },
        { value: 2, label: "手机短信通知" },
      ],
      selectDropdownValue: "",
      checked: "",
    };
  },
  methods: {
    onEdit() {
      this.$refs.dialog.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.send {
  padding: 20px 0;
  .top {
    margin-bottom: 20px;
  }
  .table {
    border: 1px solid #ebeef5;
    border-radius: 2px;
    .header {
      display: flex;
      align-items: center;
      color: #909399;
      font-weight: bold;
      .th {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeef5;
        .td {
          height: 40px;
          line-height: 40px;
          border-right: 1px solid #ebeef5;
          box-sizing: border-box;
          > span {
            padding: 0 15px;
          }
        }
        .td:nth-child(1) {
          flex: 1;
        }
        .td:nth-child(2) {
          flex: 2;
        }
        .td:nth-child(3) {
          flex: 3;
        }
        .td:nth-child(4) {
          flex: 3;
        }
        .td:last-child {
          border-right: 0;
        }
      }
    }

    .tbody {
      .th {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
        .td {
          border-right: 1px solid #ebeef5;
          box-sizing: border-box;
        }
        .td:nth-child(1) {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .td:nth-child(2) {
          flex: 2;
          .col-1 {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .td:nth-child(3) {
          flex: 3;
        }
        .td:nth-child(4) {
          flex: 3;
          .col-1-text1,
          .col-1-text2,
          .col-1-text3 {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          .el-checkbox {
            margin-bottom: 10px;
          }
          .el-checkbox:last-child {
            margin-bottom: 0;
          }
        }
        .td:last-child {
          border-right: 0;
        }
      }
      .th:last-child {
        border-bottom: 0;
      }

      .col-1 {
        height: 300px;
        border-bottom: 1px solid #ebeef5;
        .col-1-text1 {
          min-height: 140px;
          .flex-col;
          align-items: unset;
          .btn-box {
			.flex-row;
			justify-content: flex-end;
            .el-button {
            }
          }
        }
        .col-1-text2,
        .col-1-text3 {
          height: 80px;
        }
        .col-1-text1,
        .col-1-text2 {
          border-bottom: 1px solid #ebeef5;
        }
        .col-1-text1,
        .col-1-text2,
        .col-1-text3 {
          padding: 20px 15px;
          box-sizing: border-box;
        }
      }
      .col-1:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
