<template>
  <div class="content">
    <div class="container">
      <el-form ref="formData" :model="formData" :rules="rulesFormData">
        <el-form-item prop="database">
          <el-input
            placeholder="请输入数据库码"
            v-model="formData.database"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-coin"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="deviceCode">
          <el-input
            placeholder="请输入安全验证码"
            v-model="formData.deviceCode"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-pie-chart"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="username">
          <el-input
            placeholder="请输入账号"
            v-model="formData.username"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            placeholder="请输入密码"
            v-model="formData.password"
            show-password
            @keyup.enter.native="onSubmit"
          >
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item class="item">
          <el-button type="primary" @click="onSubmit" size="medium"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import User from "@/api/user";
import Index from "@/api/index.js";

export default {
  data() {
    return {
      // 表单数据
      formData: {
        database: "", // 1583257110
        deviceCode: "", // 123456
        username: "", // difengys
        password: "", //
      },
      // 表单验证
      rulesFormData: {
        database: [
          { required: true, message: "请输入数据库码", trigger: "blur" },
          { min: 10, max: 10, message: "请输入10位数据库码", trigger: "blur" },
        ],
        deviceCode: [
          { required: true, message: "请输入安全验证码", trigger: "blur" },
          { min: 6, max: 16, message: "请输入6位数验证码", trigger: "blur" },
        ],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          // { min: 6, max: 16, message: "请输入6至16位数密码", trigger: "blur" },
        ],
      },
    };
  },

  async mounted() {
    // this.$message.info(navigator.userAgent || navigator.vendor || window.opera)
    if (this.$route.query.authorization) {
      try {
        let ticket = this.$route.query.authorization;
        let { data } = await User.applyCrossWebTicket({
          ticket: encodeURIComponent(ticket),
        });
        this.publicData(data);
      } catch (e) {
        console.log(e);
        //TODO handle the exception
      }
    } else {
      let userinfo = localStorage.getItem("userinfo");
      if (userinfo) {
        this.formData = JSON.parse(userinfo);
      }
    }
  },

  methods: {
    //验证表单
    onSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          let params = {
            DBNO: this.formData.database,
            AppCode: "WEB",
            LoginName: this.formData.username,
            LoginPwd: this.formData.password,
            DeviceCode: this.formData.deviceCode,
          };
          try {
            let { data, errcode } = await User.login(params);

            if (errcode == 0) {
              this.formData.password = "";

              try {
                await window.localStorage.setItem(
                  "userinfo",
                  JSON.stringify(this.formData)
                );
              } catch (err2) {
                // console.log(err2);
                // return this.$confirm(err2.data.errmsg, "登录失败", {
                //   confirmButtonText: "确定",
                //   cancelButtonText: "取消",
                //   type: "error",
                // });
              }

              await this.publicData(data);
            }
          } catch (err1) {
            // console.log(err1);
            // return this.$confirm(err1.data.errmsg, "登录失败", {
            //   confirmButtonText: "确定",
            //   cancelButtonText: "取消",
            //   type: "error",
            // });
          }
        } else {
          return false;
        }
      });
    },

    async publicData(data) {
      //缓存用户登录token 和 用户权限powers
      try {
        window.sessionStorage.setItem("token", data.acctoken);
        window.sessionStorage.setItem("accountInfo", JSON.stringify(data));
        let storesGuid = data.authType == "H" ? data.StoresGuid : "";
        window.sessionStorage.setItem("selectStoreGuid", storesGuid);

        try {
          let { data } = await Index.getSystemData();
          this.storage.setStorage("systemData", data);
          this.$router.push("/index");
        } catch (error) {
          return this.$message.error("网络错误！");
        } finally {
        }
      } catch (err) {
        return this.$confirm(err.toString(), "本地存储失败", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        });
      } finally {
        
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  background: url(../../assets/img/back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .container {
    position: fixed;
    top: 37%;
    left: 65%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px 0;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .el-form-item {
      margin: 0 20px 20px;
      .el-button {
        width: 100%;
      }
    }
    .el-form-item:last-child {
      margin-bottom: 0;
    }

    .item {
      .flex-col;
      align-items: unset;
      .el-form-item__content {
        width: 100%;
        .el-button {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .container {
    scale: 2;
    transform: scale(2);
    -webkit-transform: scale(1); /* 旧版Webkit浏览器，如Safari */
    -moz-transform: scale(1); /* 旧版Firefox浏览器 */
    -ms-transform: scale(1); /* 旧版IE浏览器 */
    -o-transform: scale(1); /* 旧版Opera浏览器 */
    transform: unset !important;
    left: unset !important;
    right: 30% !important;
  }
}
</style>
