<template>
  <div class="monthly">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="submitData.month"
          value-format="yyyy-MM"
          type="month"
          size="mini"
          placeholder="选择月份"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="getFinalStatis"
          >查询</el-button
        >
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onShowSalaryManage"
          >基本工资管理</el-button
        >
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="importRoyaData"
          >导入员工薪资</el-button
        >
      </div>
      <!-- <div class="df-col">
        <el-button type="primary" size="mini" @click="">导入员工奖罚</el-button>
      </div> -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="importBorrowData"
          >导入员工借支</el-button
        >
      </div>
      <div class="df-col">
        <el-button type="danger" size="mini" @click="deleteRoyaData"
          >删除员工薪资</el-button
        >
      </div>
      <!-- <div class="df-col">
        <el-button type="danger" size="mini" @click="">删除员工奖罚</el-button>
      </div> -->
      <div class="df-col">
        <el-button type="danger" size="mini" @click="deleteBorrowData"
          >删除员工借支</el-button
        >
      </div>

      <div class="df-col">
        <el-button
          type="primary"
          size="mini"
          plain
          @click="$store.dispatch('onChangeCusDialog', true)"
          >员工提成参考</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="tableData"
      :max-height="tableMaxHeight"
      border
      stripe
    >
      <el-table-column prop="" label="员工" width="">
        <el-table-column prop="EmplNo" label="工号" width=""></el-table-column>
        <el-table-column
          prop="EmplName"
          label="姓名"
          width=""
        ></el-table-column>
        <el-table-column
          prop="DutyName"
          label="职位"
          width=""
        ></el-table-column>
        <el-table-column
          prop="LevelName"
          label="级别"
          width=""
        ></el-table-column>
      </el-table-column>

      <el-table-column prop="" label="基本工资" width="">
        <el-table-column
          v-if="displayMap.AtteDays"
          prop="AtteDays"
          label="考勤天数"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.BasePay"
          prop="BasePay"
          label="底薪"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.ChargeMeals"
          prop="ChargeMeals"
          label="餐费"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.ChargeStay"
          prop="ChargeStay"
          label="住宿费"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.ChargePhone"
          prop="ChargePhone"
          label="电话费"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.ChargeContact"
          prop="ChargeContact"
          label="交际费"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.FullAttendance"
          prop="FullAttendance"
          label="全勤"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.Reward"
          prop="Reward"
          label="奖励"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.Deduction"
          prop="Deduction"
          label="扣罚"
          width=""
        ></el-table-column>
        <el-table-column
          v-if="displayMap.Borrow"
          prop="Borrow"
          label="借支"
          width=""
        ></el-table-column>
      </el-table-column>

      <el-table-column prop="TotalRoya" label="提成" width=""></el-table-column>
      <el-table-column
        prop="TotalFinal"
        label="合计"
        width=""
      ></el-table-column>
    </el-table>

    <SalaryManageDialog
      ref="SalaryManageDialog"
      @close="initPageData"
    ></SalaryManageDialog>
  </div>
</template>

<script>
import Report from "@/api/report";

import SalaryManageDialog from "./components/salary-manage-dialog";

export default {
  components: { SalaryManageDialog },

  data() {
    return {
      tableMaxHeight: "",
      submitData: {
        month: "",
      },
      displayMap: {
        AtteDays: true,
        BasePay: true,
        ChargeContact: true,
        ChargeMeals: true,
        ChargePhone: true,
        ChargeStay: true,
        Deduction: true,
        FullAttendance: true,
        KeepRoya: true,
        Reward: true,
        SellRoya: true,
        WorkRoya: true,
      },
      tableData: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 初始化页面数据
    async initPageData() {
      await this.initDateState();
      await this.getFinalStatis();
    },

    // 获取工资月报数据
    async getFinalStatis() {
      this.loading = true;
      try {
        let { data } = await Report.getFinalStatis(this.submitData);
        let arr = [];
        this.tableData = data.list;
        this.displayMap = data.displayMap;
        // for(let key in data.displayMap){
        //   if(data.list[key]){
        //     arr.push()
        //   }
        // }
        // this.showTableColumn =
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 导入员工薪资
    importRoyaData() {
      this.$confirm("确定要导入员工薪资吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.importRoyaData(this.submitData);
          if (errcode == 0) {
            this.$message.success("导入成功");
            this.getFinalStatis();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 导入员工借支
    importBorrowData() {
      this.$confirm("确定要导入员工借支吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.importBorrowData(this.submitData);
          if (errcode == 0) {
            this.$message.success("导入成功");
            this.getFinalStatis();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 删除员工薪资
    deleteRoyaData() {
      this.$confirm("确定要删除员工薪资吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.deleteRoyaData(this.submitData);
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getFinalStatis();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 删除员工借支
    deleteBorrowData() {
      this.$confirm("确定要删除员工借支吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Report.deleteBorrowData(this.submitData);
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getFinalStatis();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    // 打开工资管理对话框
    onShowSalaryManage() {
      this.$refs.SalaryManageDialog.onShowDialog();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginMonth } = await this.$store.dispatch("onGetDateState");
      this.submitData.month = beginMonth;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { monthTime: event });
      this.initDateState();
    },
  },
};
</script>

<style lang="less" scoped>
.monthly {
  .df-row {
    .flex-row;
    flex-wrap: wrap;
    margin-top: 20px;
    .df-col {
      .el-date-editor {
        width: 120px;
      }
    }
  }
}

@media (max-width: 1190px) {
  .monthly {
    .df-row {
      margin-bottom: 0;
      .df-col {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
