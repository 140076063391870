<template>
  <div class="agreement">
    <div class="df-row">
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onShowAgreement('')"
          >+ 添加协议</el-button
        >
      </div>
    </div>

    <el-table
      v-loading="loading"
      :max-height="tableMaxHeight"
      :data="dataList"
      size="mini"
    >
      <el-table-column prop="Name" label="协议名称"> </el-table-column>
      <el-table-column label="显示状态">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.ShowMark"
            :active-value="1"
            :inactive-value="0"
            @change="showContract(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{
            scope.row.CreateTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.CreateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          {{
            scope.row.UpdateTime
              ? util.dateFormat(
                  "YYYY-mm-dd HH:MM:SS",
                  new Date(scope.row.UpdateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="control-box">
            <el-button
              type="primary"
              size="mini"
              @click="onShowAgreement(scope.row)"
              >修改</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteContract(scope.row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <AddAgreeDialog
      ref="AddAgreeDialog"
      @success="getContractMaintList"
    ></AddAgreeDialog>
  </div>
</template>

<script>
import Contract from "@/api/contract.js";

import AddAgreeDialog from "./components/add-agreement.dialog.vue";

export default {
  components: { AddAgreeDialog },

  data() {
    return {
      tableMaxHeight: 0,
      dataList: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.getContractMaintList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取协议管理列表
    async getContractMaintList() {
      try {
        let { data } = await Contract.getContractMaintList();
        this.dataList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 显示或隐藏协议
    async showContract(event) {
      try {
        let { errcode } = await Contract.showContract({
          contract_guid: event.ContractGuid,
          show_mark: event.ShowMark,
        });
      } catch (err) {
        event.ShowMark = event.ShowMark ? 0 : 1;
        console.log(err);
      }
    },

    // 删除协议
    deleteContract(event) {
      this.$confirm("确定删除该协议吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Contract.deleteContract({
            contract_guid: event.ContractGuid,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getContractMaintList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 60);
    },

    // 打开新增/修改协议对话框
    onShowAgreement(event = "") {
      this.$refs.AddAgreeDialog.onShowDialog(event.ContractGuid || "");
    },
  },
};
</script>

<style lang="less" scoped>
.agreement {
  .df-row {
    margin-top: 20px;
  }
  .control-box{
    .flex-row;
    flex-wrap: wrap;
  }
}

@media (max-width: 1000px) {
  .el-button{
    margin: 2px 0 2px 10px;
  }
}
</style>
