<template>
  <div>
    <el-menu
      :default-active="
        $route.path.substring(
          0,
          $route.path.indexOf('/', $route.path.indexOf(checkPath, 1) + 1)
        ) || $route.fullPath
      "
      mode="horizontal"
      active-text-color="#20a0ff"
      router
    >
      <el-menu-item
        :index="item.path"
        v-for="(item, index) in menu"
        :key="index"
      >
        {{ item.name }}
      </el-menu-item>
    </el-menu>
    <!-- <el-menu 
    :default-active="
    $route.path.substring(0,$route.path.indexOf('/',$route.path.indexOf('/',1)+1)) || $route.fullPath" 
    mode="horizontal" active-text-color="#20a0ff" router>
      <el-menu-item 
      :index="item.path" 
      v-for="(item,index) in menu" 
      :key="index">
        {{item.name}}
      </el-menu-item>
    </el-menu> -->
  </div>
</template>

<script>
import menuData from "./menu-data.js";
export default {
  props: {
    listName: {
      type: String,
      default: "",
    },

    checkPath: {
      type: String,
      default: "/",
    },
  },
  data() {
    return {
      menu: menuData[this.listName],
    };
  },
  watch: {
    listName: {
      handler(newVal) {
        // (newVal != "report" || newVal != "cashier") ? this.$store.dispatch("onRemoveDateState") : "";
        let menu = menuData[newVal];
        this.menu = menu;
        // console.log(newVal);
        // console.log(
        //   menu,
        //   this.$route,
        //   this.$route.path.substring(
        //     0,
        //     this.$route.path.indexOf("/", this.$route.path.indexOf(this.checkPath, 1) + 1)
        //   )
        // );
      },
      immediate: true,
    },

    checkPath: {
      handler(newVal) {
        // console.log('sss');
        // console.log(this.$route.path.indexOf('/', this.$route.path.indexOf(newVal, 1) + 1), newVal);
        // console.log(this.$route.path.substring(
        //   0,
        //   this.$route.path.indexOf('/', this.$route.path.indexOf(newVal, 1) + 1)
        // ), this.$route);
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.el-menu {
  .flex-row;
  height: 50px;
  padding: 0 30px;
  overflow-x: auto;

  // border-bottom: 1px solid #afcbff;
  .el-menu-item {
    position: relative;
    height: 50px;
    line-height: 50px;
    padding: 0 5px;
    margin-right: 20px;
  }

  .el-menu-item:hover {
    color: #20a0ff;
  }

  .is-active {
    color: #20a0ff;
  }

  .is-active::after {
    content: "";
  }

  .is-active::before {
    content: "";
  }
}
</style>
