<template>
    <div>
        <div>
            <MenuThird listName="package"></MenuThird>
        </div>
        <div>
            <router-view/>
        </div>
    </div>
</template>

<script>
import MenuThird from '@/components/menu/menu-third.vue'

export default {
    name: 'StoreWebSetup',

    components: { MenuThird },

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
</style>