<template>
  <div class="revenue">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <!-- 门店选择器 -->
      <div v-if="authType == 'H'" class="df-col stores-selector">
        <el-select
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          size="small"
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 营收类型选择器 -->
      <div class="df-col query-types">
        <el-select
          v-model="submitData.QueryTypes"
          multiple
          collapse-tags
          size="small"
          placeholder="请选择营收类型"
        >
          <el-option
            v-for="item in typeSelector"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 显示内容单选框 -->
      <div class="df-col">
        <el-radio-group v-model="submitData.QueryMode" @input="onChangeRadio">
          <el-radio
            v-for="(item, index) in modeSelector"
            :key="index"
            :label="item.Value"
          >
            {{ item.Name }}
          </el-radio>
        </el-radio-group>
      </div>

      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-show="submitData.QueryMode == 1"
          v-model="dataArray"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
        <el-date-picker
          v-show="submitData.QueryMode == 2"
          v-model="monthArray"
          type="monthrange"
          size="small"
          value-format="yyyy-MM"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="onChangeDate"
        >
        </el-date-picker>
        <el-date-picker
          v-show="submitData.QueryMode == 3"
          v-model="dayArray"
          type="daterange"
          size="small"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button @click="onSearchData" size="small" type="primary"
          >开始查询</el-button
        >

        <!-- 导出表格按钮 -->
        <el-button
          @click="onExportExcel"
          size="small"
          type="primary"
          :disabled="excelDisabled"
          >导出表格</el-button
        >

        <!-- 打印数据按钮 -->
        <el-button @click="onPrintTable" size="small" type="primary"
          >打印数据</el-button
        >

        <!-- 自定义表格按钮 -->
        <el-button @click="onCustomTable" size="small" type="primary"
          >自定义表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <!-- start 营收报表 -->
    <div class="print">
      <el-table
        id="exprot-table"
        :data="dataList"
        v-loading="loading"
        :max-height="tableMaxHeight"
        show-summary
        stripe
        style="width: 100%"
      >
        <el-table-column
          :fixed="isFixedRow"
          v-if="headList[0]"
          :label="headList[0].Name"
          width="100"
        >
          <template slot-scope="scope">
            {{
              scope.row[headList[0].DataKey]
                ? scope.row[headList[0].DataKey]
                : "--"
            }}
          </template>
        </el-table-column>

        <!-- 合并列表头(一级表头) -->
        <el-table-column
          :label="top.Name"
          v-for="(top, index) in topList"
          :key="index"
        >
          <template v-for="(list, listIndex) in headList">
            <!-- 子表头(二级表头) -->
            <el-table-column
              v-if="top.Code == list.TopCode"
              :key="list.DataKey"
              :label="list.Name"
              :prop="list.DataKey"
            >
              <template slot-scope="scope">
                {{ scope.row[list.DataKey] ? scope.row[list.DataKey] : "--" }}
              </template>
            </el-table-column>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- end 营收报表 -->

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      :reportCode="reportCode"
      @refresh="onRefreshCustom"
      @onConfirm="onSubmitRevenue"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import api from "@/api/report.js";
import CustomDialog from "@/views/report/components/custom-dialog.vue";
import printJS from "print-js";
export default {
  components: { CustomDialog },

  data() {
    return {
      reportCode: "",
      tableMaxHeight: "", // 表格最大高度
      authType: "", // 当前账号等级 H 总店 M 各门店
      storesData: [], // 基准门店列表
      typeSelector: [], // 营收类型列表
      modeSelector: [], // 显示内容列表
      submitData: {
        // 提交数据
        StoresGuids: [], // 门店id
        QueryTypes: [], // 营收类型
        QueryMode: 3, // 营收显示内容
        BeginDate: "", // 开始时间
        EndDate: "", // 结束时间
      },
      headData: {}, // 自定义表头源数据
      heads: [], // 自定义表头
      dataArray: [], // 日期范围选择数据
      monthArray: [], // 月份选择数据
      dayArray: [], // 日选择数据
      topList: [], // 一级表头数据
      headList: [], // 二级表头数据
      dataList: [], // 表格数据
      loading: false, // 表格加载
      isFixedRow: true, // 固定列
      excelDisabled: false, // 导出禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;

    await this.initDateState();
    await this.fetchBusinessRevenue();
    await this.initPageData();
    await this.queryBusinessRevenue();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      let tableMaxHeight = clientHeight - (100 + 40 + 72);
      this.tableMaxHeight = tableMaxHeight > 400 ? tableMaxHeight : 400;
    },

    async initPageData() {
      try {
        let { data } = await api.businessRevenue({ subact: "init_data" });
        let { typeSelector, modeSelector } = data;
        typeSelector.forEach((item) =>
          this.submitData.QueryTypes.push(item.Value)
        );
        this.typeSelector = typeSelector;
        this.modeSelector = modeSelector;
      } catch (e) {
        //TODO handle the exception
      }
    },

    async fetchBusinessRevenue() {
      try {
        let { data } = await api.businessRevenue({ subact: "fetch_custom" });
        let { Heads, reportCode } = data;
        this.headData = data;
        this.heads = Heads;
        this.reportCode = reportCode;
      } catch (e) {}
    },

    async queryBusinessRevenue() {
      this.loading = true;
      try {
        let { data } = await api.queryBusinessRevenue(this.submitData);
        let { DataList, HeadList, TopList } = data;
        this.topList = TopList;
        this.headList = HeadList;
        this.dataList = DataList;
      } catch (e) {
        //TODO handle the exception
      }
      this.loading = false;
    },

    // 初始化日期状态
    async initDateState() {
      let {
        beginDay,
        endDay,
        beginMonth,
        endMonth,
      } = await this.$store.dispatch("onGetDateState");
      this.dataArray = [beginDay, endDay];
      this.dayArray = [beginDay, endDay];
      this.monthArray = [beginMonth, endMonth];
      this.onChangeRadio(this.submitData.QueryMode);
    },

    // 改变日期状态
    onChangeDate(event) {
      this.$store.dispatch(
        "onChangeDateState",
        this.submitData.QueryMode == 2
          ? { monthTime: event }
          : { dayTime: event }
      );
      this.initDateState();
    },

    // 单选框改变事件
    onChangeRadio(event) {
      let arr = [];
      if (event == 1) {
        arr = this.dataArray;
      } else if (event == 2) {
        arr = this.monthArray;
      } else if (event == 3) {
        arr = this.dayArray;
      }
      this.submitData.BeginDate = arr[0];
      this.submitData.EndDate = arr[1];
    },

    async onRefreshCustom() {
      await this.fetchBusinessRevenue();
      await this.onCustomTable();
    },

    onCustomTable() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    onSubmitRevenue(event) {
      let { Heads } = event;
      this.heads = Heads;
      this.saveBusinessRevenue();
    },

    async saveBusinessRevenue() {
      try {
        let { errcode } = await api.saveBusinessRevenue({
          Heads: this.heads,
        });
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.fetchBusinessRevenue();
          this.queryBusinessRevenue();
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    onSearchData() {
      this.queryBusinessRevenue();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "营收报表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },

    onPrintTable() {
      let table = document.querySelector(".print").innerHTML;
      let div = document.createElement("div");
      div.id = "tTable";
      div.innerHTML = table;
      div.querySelector(".hidden-columns").remove();
      div.querySelector(".el-table__header-wrapper").remove();
      div.querySelector(".el-table__footer-wrapper").remove();
      div.querySelector(".el-table__fixed").remove();
      div.querySelector(".el-table__column-resize-proxy").remove();
      div.querySelector(".el-loading-mask").remove();
      let block = div.querySelector(".el-table__empty-block");
      if (block) {
        block.remove();
      }
      let tbody = div.querySelector(".el-table__body-wrapper table tbody");

      // 表头
      let group = document.querySelector(
        "#exprot-table .el-table__header-wrapper table .is-group"
      ).innerHTML;
      let thead = document.createElement("thead");
      thead.innerHTML = group;
      thead.className = "is-group";
      // console.log(thead);

      // 表尾
      let gutter = document.querySelector(
        "#exprot-table .el-table__footer-wrapper table .has-gutter tr"
      ).innerHTML;
      let tr = document.createElement("tr");
      tr.innerHTML = gutter;
      tr.className = "el-table__row";

      tbody.before(thead);
      tbody.append(tr);

      document.querySelector(".print").appendChild(div);

      // this.isFixedRow = false;
      this.$nextTick(() => {
        printJS({
          printable: "tTable", //'exprot-table',
          type: "html",
          scanStyles: false,
          style: `table { border: 1px solid #333; } th{ border: 1px solid #333; margin: 5px 0; text-align: center; } td{ border: 1px solid #333; margin: 5px 0; text-align: center;}`, // 表格样式
        });

        div.remove();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.revenue {
  .df-row {
    align-items: center;
    margin: 20px 0;
    .df-col {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      div > * {
        width: 100%;
      }

      .el-radio-group {
        display: flex;
        align-items: center;
      }

      .el-date-editor {
        width: 260px;
      }

      .el-select {
        min-width: 260px;
      }

      .el-button {
        width: 100%;
      }
    }
  }

  .el-tag {
    display: flex;
    align-items: center;
    .el-select__tags-text {
      display: inline-block;
      max-width: 85px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 1001px) and (max-width: 1280px) {
    .df-row {
      flex-wrap: wrap;
      margin-block: 15px;
      .df-col {
        margin-block: 5px;
        .el-date-editor {
          // width: 240px;
        }
      }

      .query-types {
        // margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 1000px) {
    .df-row {
      flex-wrap: wrap;
      margin-block: 15px;
      .df-col {
        margin-block: 5px;
        .el-date-editor {
          // width: 240px;
        }
      }
    }

    .query-types {
      // margin-bottom: 10px;
    }
  }
}
</style>
