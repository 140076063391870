<template>
  <div class="delete">
    <!-- top -->
    <div class="df-row">
      <div class="df-col">
        <el-input
          placeholder="请输入姓名/手机号码"
          v-model="submitData.Word"
          size="mini"
        >
        </el-input>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">
          搜索
        </el-button>
      </div>
    </div>
    <!-- end top -->
    <!-- table -->
    <el-table
      :data="dataList"
      style="width: 100%"
      :max-height="tableMaxHeight"
      v-loading="loading"
      @sort-change="onSortHandle"
    >
      <el-table-column type="selection" width="48"> </el-table-column>
      <el-table-column
        label="姓名"
        :width="adaptiveDefault.tableColumnWidth.name"
        fixed="left"
        width="220px"
      >
        <template slot-scope="scope">
          <div class="member-name">
            <div class="member-name-left">
              <el-image v-if="scope.row.MemberHead" :src="scope.row.MemberHead">
              </el-image>
              <div v-else class="name">
                {{ scope.row.MemberName.substring(0, 1) }}
              </div>
              <el-tag
                type="warning"
                size="mini"
                style="width: 100%; text-align: center"
                >{{ scope.row.LossType }}</el-tag
              >
            </div>
            <div>
              <div>
                <span>{{ scope.row.MemberName }}</span>
                <i
                  :class="
                    scope.row.MemberSex == 1 ? 'el-icon-male' : 'el-icon-female'
                  "
                  style="margin-left: 5px; font-size: 15px; font-weight: bold"
                ></i>
              </div>
              <div class="size">{{ scope.row.MemberPhone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!--  -->
      <el-table-column
        v-for="(item, index) in headList"
        :key="index"
        :label="item.Name"
        :prop="item.DataKey"
        :width="adaptiveDefault.tableColumnWidth.other"
      >
        <template slot-scope="scope">
          <div>
            <div v-if="item.DataKey == 'Birthday'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'MemberTags'">
              <el-tag
                v-for="(tagItem, tagIndex) in scope.row[item.DataKey]"
                :key="'tag' + tagIndex"
                size="small"
              >
                {{ tagItem }}
              </el-tag>
            </div>
            <div v-else-if="item.DataKey == 'DimeSigners'">
              <div class="dime-sign">
                <div
                  class="sign-text"
                  v-for="(dimeItem, dimeIndex) in scope.row[item.DataKey]"
                  :key="dimeIndex"
                  :style="'background:' + dimeItem.SignColor"
                >
                  {{ dimeItem.SignText }}
                </div>
              </div>
            </div>
            <div v-else-if="item.DataKey == 'VipInfos'">
              <el-popover placement="bottom" width="400" trigger="click">
                <div class="member-card">
                  <div
                    class="member-card-list"
                    v-for="(vipItem, vipIndex) in scope.row[item.DataKey]"
                    :key="'vip' + vipIndex"
                  >
                    <div class="title">
                      <span>{{ vipItem.CardName }}</span>
                      <span>（{{ vipItem.VipNo }}）</span>
                    </div>
                    <ul class="grid">
                      <li>
                        <p>充值余额</p>
                        <p>{{ vipItem.KeepBalance }}</p>
                      </li>
                      <li>
                        <p>赠送金额</p>
                        <p>{{ vipItem.DonaBalance }}</p>
                      </li>
                      <li>
                        <p>卡的积分</p>
                        <p>{{ vipItem.InteBalance }}</p>
                      </li>
                      <li>
                        <p>卡的欠款</p>
                        <p>{{ vipItem.DebtBalance }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <span
                  v-if="scope.row[item.DataKey][0]"
                  slot="reference"
                  class="look-card"
                >
                  <span>{{ scope.row[item.DataKey][0].CardName }}</span>
                  <i class="el-icon-caret-bottom"></i>
                </span>
              </el-popover>
            </div>
            <div v-else-if="item.DataKey == 'LastExpeTime'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else-if="item.DataKey == 'RegisterDate'">
              {{
                scope.row[item.DataKey]
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row[item.DataKey] * 1000)
                    )
                  : ""
              }}
            </div>
            <div v-else>
              {{ scope.row[item.DataKey] }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="UpdateTime"
        label="删除时间"
        :width="adaptiveDefault.tableColumnWidth.other"
        sortable
        fixed="right"
        width="120px"
      >
        <template slot-scope="scope">
          {{
            scope.row.UpdateTime
              ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.UpdateTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>

      <!--  -->
      <el-table-column
        label="操作"
        :width="adaptiveDefault.tableColumnWidth.control"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            :disabled="restoreDisabled"
            @click="onRestore(scope.row)"
          >
            恢复
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- end table -->

    <!--pagination-->
    <el-pagination
      background
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="submitData.PageSize"
      :current-page="submitData.PageIndex"
      :total="submitData.TotalRecord"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!-- end pagination-->
  </div>
</template>

<script>
import api from "../../../../api/member.js";
const cityOptions = ["上海", "北京", "广州", "深圳"];
export default {
  data() {
    return {
      dataList: [],
      headList: [],
      submitData: {
        VipNo: "",
        MemberName: "",
        MemberPhone: "",
        MemberKind: "",
        MemberState: 3,
        MemberSex: -1,
        StoresShare: -1,
        MemberFollow: "",
        SourceWay: -1,
        LossLevel: 0,
        MemberTag: "",
        IntroduceGuid: "",
        StarBirthday: "",
        EndBirthday: "",
        StartAge: 0,
        EndAge: 0,
        StartExpeFreq: 0,
        EndExpeFreq: 0,
        StartLastExpeAway: 0,
        EndLastExpeAway: 0,
        StartLastExpeDate: "",
        EndLastExpeDate: "",
        PageSize: 10,
        PageIndex: 1,
        TotalRecord: 0,
        Word: "",
        OrderByExp: "",
      },
      loading: false,
      restoreDisabled: false, // 恢复按钮禁用
      tableMaxHeight: "",

      mediaWidthAdaptive: {
        "1000": {
          tableColumnWidth: {
            name: "200px",
            other: "180px",
            control: "90px",
          },
        },
        "1280": {
          tableColumnWidth: {
            name: "200px",
            other: "180px",
            control: "90px",
          },
        },
        "1920": {
          tableColumnWidth: {
            name: "250px",
            other: "180px",
            control: "100px",
          },
        },
      },
      adaptiveDefault: {
        tableColumnWidth: {
          name: "",
          other: "",
          control: "",
        },
      },
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 监听窗口变化实时获取浏览器窗口大小
    getResize() {
      let { clientHeight, clientWidth } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 260;

      for (let key in this.mediaWidthAdaptive) {
        // console.log(Number(key));
        if (clientWidth <= Number(key)) {
          this.adaptiveDefault = {};
          this.adaptiveDefault = JSON.parse(
            JSON.stringify(this.mediaWidthAdaptive[key])
          );
          break;
        }
      }
    },

    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.getMemberMaintList(this.submitData);
        let { filter, result } = data;
        let { DataList, HeadList } = result;
        this.dataList = DataList;
        this.headList = HeadList;
        this.submitData = filter;
      } catch (e) {}
      this.loading = false;
    },

    onSearch() {
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    onSortHandle({ column, prop, order }) {
      // console.log(column, prop, order);
      // this.$refs.name
      this.submitData.OrderByExp = order === null ? '' : (order === 'ascending' ? `${prop}^${1}` : `${prop}^${0}`)
      this.onSearch()
    },

    handleSizeChange(event) {
      this.submitData.PageSize = event;
      this.submitData.PageIndex = 1;
      this.initPageData();
    },

    handleCurrentChange(event) {
      this.submitData.PageIndex = event;
      this.initPageData();
    },

    onRestore(event) {
      this.restoreDisabled = true;
      this.$confirm(`是否恢复该‘${event.MemberName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.restoreMember({
              member_guid: event.MemberGuid,
            });
            this.$message({
              message: "恢复成功!",
              type: "success",
            });
            this.initPageData();
          } catch (e) {
            console.log(e);
          }
        })
        .finally(() => {
          this.restoreDisabled = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.delete {
  .df-row {
    margin-top: 20px;
  }
  .member-name {
    display: flex;
    align-items: center;
    .member-name-left {
      position: relative;
      height: 60px;
      width: 60px;
      margin-right: 10px;
      background-color: #c8c5c8;
      border-radius: 5px;

      .el-image {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .name {
        height: 100%;
        width: 100%;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
      }

      .el-tag {
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }

    .el-icon-female {
      color: tomato;
    }

    .el-icon-male {
      color: #409eff;
    }
  }

  .dime-sign {
    .flex-row;
    width: 100%;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    .sign-text {
      .flex-row;
      justify-content: center;
      height: 20px;
      width: 20px;
      margin: 0 3px;
      font-size: 14px;
      color: white;
      border-radius: 50%;
    }
  }


  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
</style>
