<template>
  <div class="add-qualify-dialog">
    <!-- start 添加对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="showQualifyDialog"
      width="700px"
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <!-- start form表格 -->
      <el-form
        ref="formData"
        :model="formData"
        :rules="formRules"
        size="small"
        label-width="200px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="规则名称" prop="QualifyName">
              <el-input v-model="formData.QualifyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="轮牌业绩提成百分比模式">
              <el-radio-group v-model="formData.PercentMode">
                <el-radio :label="0">覆盖</el-radio>
                <el-radio :label="1">增加</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="
                `轮牌业绩${formData.PercentMode ? '增加' : ''}提成百分率(%)`
              "
              prop="AwardPercent"
            >
              <el-input v-model="formData.AwardPercent"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="
                `指定业绩${formData.PercentMode ? '增加' : ''}提成百分率(%)`
              "
              prop="AwardPercentAssign"
            >
              <el-input v-model="formData.AwardPercentAssign"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职位" prop="DutyNo">
              <el-select
                v-model="formData.DutyNo"
                placeholder="请选择"
                @change="onDutyChange"
              >
                <el-option
                  v-for="item in basicSettingList.dutyList"
                  :key="item.DutyNo"
                  :label="item.DutyName"
                  :value="item.DutyNo"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="级别" prop="LevelGuid">
              <el-select v-model="formData.LevelGuid" placeholder="请选择">
                <el-option
                  v-for="item in levelList"
                  :key="item.LevelGuid"
                  :label="item.LevelName"
                  :value="item.LevelGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目基类" prop="ItemBasicGuid">
              <el-select v-model="formData.ItemBasicGuid" placeholder="请选择">
                <el-option
                  v-for="item in basicSettingList.itemBasicList"
                  :key="item.BasicGuid"
                  :label="item.BasicName"
                  :value="item.BasicGuid"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客户类型" prop="Customer">
              <el-select v-model="formData.Customer" placeholder="请选择">
                <el-option
                  v-for="item in basicSettingList.customerList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动业绩开始" prop="PerforBegin">
              <el-input v-model.number="formData.PerforBegin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动业绩结束" prop="PerforEnd">
              <el-input v-model.number="formData.PerforEnd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动现金业绩开始" prop="CashPerforBegin">
              <el-input v-model.number="formData.CashPerforBegin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动现金业绩结束" prop="CashPerforEnd">
              <el-input v-model.number="formData.CashPerforEnd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动指定业绩开始" prop="AssignPerforBegin">
              <el-input v-model.number="formData.AssignPerforBegin"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动指定业绩结束" prop="AssignPerforEnd">
              <el-input v-model.number="formData.AssignPerforEnd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动轮牌业绩开始" prop="NotAssignPerforBegin">
              <el-input
                v-model.number="formData.NotAssignPerforBegin"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="劳动轮牌业绩结束" prop="NotAssignPerforEnd">
              <el-input v-model.number="formData.NotAssignPerforEnd"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="开充卡现金业绩开始"
              prop="OpenKeepCashPerforBegin"
            >
              <el-input
                v-model.number="formData.OpenKeepCashPerforBegin"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="开充卡现金业绩结束"
              prop="OpenKeepCashPerforEnd"
            >
              <el-input
                v-model.number="formData.OpenKeepCashPerforEnd"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="手动修改过的不修改"
              prop="IgnoreManualModified"
            >
              <el-select
                v-model="formData.IgnoreManualModified"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in isList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="按金额设置提成的不修改"
              prop="IgnoreSetRoyaByMoney"
            >
              <el-select
                v-model="formData.IgnoreSetRoyaByMoney"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in isList"
                  :key="item.Value"
                  :label="item.Name"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- end form表格 -->

      <!-- start 腿部 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog" size="small">取 消</el-button>
        <el-button
          @click="submitForm('formData')"
          :disabled="submitButton"
          size="small"
          type="primary"
          >确 定</el-button
        >
      </span>
      <!-- end 腿部 -->
    </el-dialog>
    <!-- end 设置卡类型对话框 -->
  </div>
</template>

<script>
import Qualify from "@/api/qualify.js";
export default {
  data() {
    let checkAwardPercent = (rule, value, callback) => {
      let { AwardPercent, AwardPercentAssign } = this.formData;
      AwardPercent = Number(AwardPercent);
      AwardPercentAssign = Number(AwardPercentAssign);

      this.$refs.formData.clearValidate();
      if (!AwardPercent > 0 && !AwardPercentAssign > 0) {
        return callback(new Error("请至少选择一个提成百分率"));
      } else if (AwardPercent <= 0 && !AwardPercentAssign) {
        return callback(new Error("提成百分率不能小于零"));
      } else {
        this.formData.AwardPercent = AwardPercent;
        return callback();
      }
    };

    let checkAwardPercentAssign = (rule, value, callback) => {
      let { AwardPercent, AwardPercentAssign } = this.formData;
      AwardPercent = Number(AwardPercent);
      AwardPercentAssign = Number(AwardPercentAssign);

      this.$refs.formData.clearValidate();
      if (!AwardPercent > 0 && !AwardPercentAssign > 0) {
        return callback(new Error("请至少选择一个提成百分率"));
      } else if (AwardPercentAssign <= 0 && !AwardPercent) {
        return callback(new Error("提成百分率不能小于零"));
      } else {
        this.formData.AwardPercentAssign = AwardPercentAssign;
        return callback();
      }
    };
    return {
      title: "",
      formData: {},
      formRules: {
        QualifyName: [
          { required: true, message: "请输入规则名称", trigger: "blur" },
        ],
        AwardPercent: [{ validator: checkAwardPercent, trigger: "blur" }],
        AwardPercentAssign: [
          { validator: checkAwardPercentAssign, trigger: "blur" },
        ],
      },
      basicSettingList: {},
      isList: [
        { Name: "修改", Value: 0 },
        { Name: "不修改", Value: 1 },
      ],
      levelList: [],
      showQualifyDialog: false,
      submitButton: false,
    };
  },

  mounted() {
    this.initBasicData();
  },

  methods: {
    // 初始化达标规则所需修改的下拉框的所有数据
    async initBasicData() {
      let { data } = await Qualify.initSaveQualify();

      data.dutyList.unshift({
        DutyNo: "",
        DutyName: "不限",
      });
      data.itemBasicList.unshift({
        BasicGuid: "",
        BasicName: "不限",
        BasicNo: "",
      });
      this.basicSettingList = data;
      // this.tableData = data;
    },

    // 提交事件
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitButton = true;
          this.formData = {
            ...this.formData,
            PerforBegin: this.formData.PerforBegin || 0,
            PerforEnd: this.formData.PerforEnd || 0,
            CashPerforBegin: this.formData.CashPerforBegin || 0,
            CashPerforEnd: this.formData.CashPerforEnd || 0,
            AssignPerforBegin: this.formData.AssignPerforBegin || 0,
            AssignPerforEnd: this.formData.AssignPerforEnd || 0,
            NotAssignPerforBegin: this.formData.NotAssignPerforBegin || 0,
            NotAssignPerforEnd: this.formData.NotAssignPerforEnd || 0,
            OpenKeepCashPerforBegin: this.formData.OpenKeepCashPerforBegin || 0,
            OpenKeepCashPerforEnd: this.formData.OpenKeepCashPerforEnd || 0,
          };
          try {
            let { data, errcode, errmsg } = await Qualify.saveQualify(
              this.formData
            );
            if (errcode == 0) {
              this.$message({
                message: "操作成功!",
                type: "success",
              });

              this.resetFormData();
              this.onCloseDialog();
              this.$emit("success");
            } else {
              this.$message.error(errmsg);
            }
          } finally {
            this.submitButton = false;
          }
        } else {
          return false;
        }
      });
    },

    // 职位更改事件
    onDutyChange() {
      this.levelList = this.basicSettingList.levelList.filter(
        (item) => item.DutyNo == this.formData.DutyNo
      );
      this.levelList.unshift({
        DutyNo: "",
        LevelGuid: "",
        LevelName: "不限",
      });
      this.formData.LevelGuid = this.levelList[0].LevelGuid;
    },

    // 打开对话框
    onShowDialog(type, editData) {
      this.showQualifyDialog = true;
      this.title = type + "规则";
      this.formData = editData || {
        DutyNo: "",
        ItemBasicGuid: "",
        LevelGuid: "",
        Customer: 0,
        IgnoreManualModified: 1,
        IgnoreSetRoyaByMoney: 1,
        AssignPerforBegin: 0,
        AssignPerforEnd: 0,
        AwardPercent: "",
        AwardPercentAssign: "",
        CashPerforBegin: 0,
        CashPerforEnd: 0,
        NotAssignPerforBegin: 0,
        NotAssignPerforEnd: 0,
        OpenKeepCashPerforBegin: 0,
        OpenKeepCashPerforEnd: 0,
        PerforBegin: 0,
        PerforEnd: 0,
        PercentMode: 0,
      };

      this.onDutyChange();
    },

    resetFormData() {
      this.formData = {};
      this.$refs.formData.resetFields();
    },

    // 关闭对话框
    onCloseDialog() {
      this.showQualifyDialog = false;
      this.formData = {};
      this.$refs.formData.resetFields();
    },
  },
};
</script>

<style lang="less" scoped></style>
