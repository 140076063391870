<template>
  <div>
    <el-dialog
      title="批量设置预约项目"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" :inline="false" size="small">
          <el-form-item label="预约项目所属职位">
            <el-select
              v-model="submitData.DutyNo"
              @change="onChangeDuty"
              clearable
              placeholder="请选择预约项目所属职位"
            >
              <el-option
                v-for="(item, index) in dutyList"
                :key="item.DutyNo"
                :label="item.DutyName"
                :value="item.DutyNo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="级别">
            <el-select
              v-model="submitData.LevelGuid"
              placeholder="请选择级别"
              clearable
            >
              <el-option
                v-for="(item, index) in levelScreenData"
                :key="item.LevelGuid"
                :label="item.LevelName"
                :value="item.LevelGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预约项目分类显示">
            <el-select
              v-model="submitData.WechatGuid"
              clearable
              placeholder="请选择预约项目分类显示"
            >
              <el-option
                v-for="(item, index) in itemWechatList"
                :key="item.WechatGuid"
                :label="item.WechatName"
                :value="item.WechatGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="submitDisabled"
          @click="batchReserveSetting"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Service from "@/api/service.js";

export default {
  props: {
    dutyList: {
      type: Array,
      default: () => {
        [];
      },
    },
    levelList: {
      type: Array,
      default: () => {
        [];
      },
    },
    itemWechatList: {
      type: Array,
      default: () => {
        [];
      },
    },
  },

  data() {
    return {
      submitData: {
        DutyNo: "",
        LevelGuid: "",
        WechatGuid: "",
        ItemGuids: [],
      },
      levelScreenData: [],
      showDialog: false,
      submitDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 保存预约项目设置
    async batchReserveSetting() {
      this.submitDisabled = true;
      try {
        let { errcode } = await Service.batchReserveSetting(this.submitData);
        if (errcode == 0) {
          this.$emit("success");
          this.$message.success("设置成功");
          this.onCloseDialog();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.submitDisabled = false;
      }
    },

    onChangeDuty(event) {
      let tempArray = [];
      this.levelList.forEach((item, index) => {
        if (item.DutyNo == event) {
          tempArray.push(item);
        }
      });
      tempArray.unshift({ LevelGuid: "ALL", LevelName: "不限" });
      // this.submitData.DutyGuid = this.selectDuty
      this.levelScreenData = tempArray;
    },

    // 打开对话框
    onShowDialog(event) {
      this.submitData.ItemGuids = JSON.parse(JSON.stringify(event));
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.submitData = {
        DutyNo: "",
        LevelGuid: "",
        WechatGuid: "",
        ItemGuids: [],
      };
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
