<template>
  <div>
    <el-container>
      <!-- el-aside -->
      <el-aside :width="innerWidth < 1552 ? '180px' : '200px'">
        <el-select
          @change="onChangeItemState"
          v-model="selectItemState"
          placeholder="请选择项目状态"
        >
          <el-option
            v-for="item in itemState"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-tree
          ref="Etree"
          :data="treeData"
          node-key="id"
          accordion
          :highlight-current="true"
          :default-expanded-keys="defaultExpandedKeys"
          :allow-drag="allowDrag"
          :current-node-key="currentNodeKey"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span :id="data.id">{{ node.label }}</span>
            <span>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onAddTree(node, data)"
                    >新建子类</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="onEditTree(node, data)"
                    divided
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="onDeleteTree(node, data)"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
        </el-tree>
      </el-aside>
      <!-- end el-aside -->
      <!-- el-main -->
      <el-main>
        <!-- top -->
        <div class="top">
          <div class="left">
            <el-radio-group
              @change="onChange"
              v-model="selectItemType"
              size="mini"
            >
              <el-radio-button label="全部"></el-radio-button>
              <el-radio-button label="消费项目"></el-radio-button>
              <el-radio-button label="预约项目"></el-radio-button>
              <el-radio-button label="产品管理"></el-radio-button>
            </el-radio-group>
          </div>

          <div class="df-row right">
            <!-- 门店选择器 -->
            <!-- <div class="df-col" v-if="authType == 'H'">
              <el-select
                v-model="sourceStoresGuid"
                placeholder="请选择门店"
                clearable
                size="mini"
                @change="onChangeStores"
              >
                <el-option
                  v-for="item in storesList"
                  :key="item.StoresGuid"
                  :label="item.StoresName"
                  :value="item.StoresGuid"
                >
                </el-option>
              </el-select>
            </div> -->
            <div class="content">
              <div class="df-col">
                <el-input
                  placeholder="请输入内容"
                  v-model="searchText"
                  size="mini"
                >
                </el-input>
              </div>
              <div class="df-col">
                <el-button type="primary" size="mini" @click="onSearchItem"
                  >查询</el-button
                >
              </div>
              <div class="df-col">
                <el-button
                  @click="addItem"
                  size="mini"
                  type="primary"
                  icon="el-icon-plus"
                  >新增项目</el-button
                >
              </div>

              <div class="df-col">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowGroupMove(0)"
                  >显示组移组</el-button
                >
              </div>
              <div class="df-col">
                <el-button size="mini" type="primary" @click="onShowGroupSort"
                  >显示组排序</el-button
                >
              </div>
              <div class="df-col">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowGroupMove(1)"
                  >项目移组</el-button
                >
              </div>
              <div class="df-col">
                <el-button size="mini" type="primary" @click="onShowItemSort"
                  >项目排序</el-button
                >
              </div>
              <div class="df-col">
                <el-button type="primary" size="mini" @click="onShowBasic"
                  >批量设置基类</el-button
                >
              </div>
              <div class="df-col">
                <el-button type="primary" size="mini" @click="onShowReserve"
                  >批量设置预约项目</el-button
                >
              </div>

              <div
                v-show="authType == 'H' && innerWidth <= 1552"
                class="df-col"
              >
                <el-button type="primary" size="mini" @click="onShowGoods"
                  >选择项目</el-button
                >
              </div>
              <div
                v-show="authType == 'H' && innerWidth <= 1552"
                class="df-col"
              >
                <el-button type="success" size="mini" @click="onShowSync"
                  >同步项目</el-button
                >
              </div>
            </div>

            <div v-show="authType == 'H' && innerWidth > 1552" class="other">
              <div class="df-row right">
                <div class="df-col">
                  <el-button type="primary" size="mini" @click="onShowGoods"
                    >选择项目</el-button
                  >
                </div>
                <div class="df-col">
                  <el-button type="success" size="mini" @click="onShowSync"
                    >同步项目</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end top -->
        <!-- table -->
        <el-table
          ref="multipleTable"
          v-loading="loading"
          :data="tableData"
          row-key="ItemGuid"
          style="width: 100%"
          :height="tableMaxHeight"
        >
          <!-- <el-table-column type="selection" width="48"></el-table-column> -->
          <el-table-column width="60">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                v-model="isActiveCheck"
                :checked="allCheck"
                :indeterminate="indeterminate"
                @change="onSelectChange('all', tableData)"
              ></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
                :value="scope.row.isCheck"
                :checked="scope.row.isCheck"
                :indeterminate="false"
                @change="onSelectChange('', scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="项目" width="250">
            <template slot-scope="scope">
              <div class="image-text">
                <div class="image">
                  <el-image
                    :src="
                      scope.row.Picture ||
                        require('../../../../assets/img/empty.jpg')
                    "
                  ></el-image>
                </div>

                <div class="text">
                  <el-tooltip
                    effect="dark"
                    :content="scope.row.ItemName"
                    :disabled="tooltipDisabled"
                    placement="top-start"
                  >
                    <span
                      class="item-name"
                      @mouseenter="onShowTip($event, scope.row.ItemName)"
                      @mouseleave="tooltipDisabled = true"
                      >{{ scope.row.ItemName }}</span
                    >
                  </el-tooltip>
                  <!-- <span
                    class="item-name"
                    @mouseenter="onShowTip($event, scope.row.ItemName)"
                    @mouseleave="onHideTip"
                    >{{ scope.row.ItemName }}</span
                  > -->
                  <span class="size" @click="onClipboard(scope.row.ItemGuid)">
                    编号: {{ scope.row.ItemNo }}
                  </span>
                  <span v-show="scope.row.HeadMark" style="color: tomato;"
                    >[总]</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="ListBasicName"
            label="基类"
            show-overflow-tooltip
            width="150"
          ></el-table-column>
          <el-table-column
            prop="Price"
            label="单价"
            width="100"
          ></el-table-column>
          <el-table-column prop="ItemState" label="项目状态" width="100">
            <template slot-scope="scope">
              <el-tag type="success" v-show="scope.row.ItemState == 1"
                >正常</el-tag
              >
              <el-tag type="info" v-show="scope.row.ItemState == 2"
                >已停用</el-tag
              >
              <el-tag type="danger" v-show="scope.row.ItemState == 3"
                >已删除</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="ListDeptName" label="所属部门">
          </el-table-column>
          <el-table-column prop="IsSale" label="扣库存">
            <template slot-scope="scope">{{
              scope.row.IsSale | whether
            }}</template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="230">
            <template slot-scope="scope">
              <div v-if="scope.row.ItemState === 1">
                <el-button
                  @click="onAlterItem(scope.row)"
                  type="primary"
                  size="mini"
                  >修改</el-button
                >
                <el-button
                  @click="disableItem(scope.row)"
                  :disabled="deleteDisabled"
                  type="info"
                  size="mini"
                  >停用</el-button
                >
                <el-button
                  @click="onDeleteItem(scope.row)"
                  :disabled="deleteDisabled"
                  type="danger"
                  size="mini"
                  >删除</el-button
                >
              </div>
              <div v-else>
                <el-button
                  @click="recoveryItem(scope.row)"
                  :disabled="recoveryDisabled"
                  type="warning"
                  size="mini"
                  >恢复</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- end table -->

        <!-- pagination -->
        <div class="pagination-box">
          <el-button type="primary" size="small" @click="onChangePagination"
            >{{ !isPagination ? "" : "不" }}分页</el-button
          >
          <el-pagination
            background
            :page-sizes="[15, 20, 30, 40, 50, 100, 500, 10000]"
            :page-size="pageSize"
            :current-page="pageIndex"
            :layout="
              !isPagination
                ? 'total'
                : 'total, sizes, prev, pager, next, jumper'
            "
            :total="totalRecord"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!--pagination-->

        <EditDialog
          ref="blow"
          :etitle="etitle"
          :dialogTableVisible="showDialog"
          :callFatherFunction="callFatherFunction"
          :fromData="fromData"
          :roundItem="roundItem"
          :deptList="deptList"
          :dutyList="dutyList"
          :itemBasicList="itemBasicList"
          :itemClassList="itemClassList"
          :itemWechatList="itemWechatList"
          :levelList="levelList"
          :unitsList="unitsList"
          @success="initPageData"
          @closeDialog="closeDialog"
        >
        </EditDialog>
      </el-main>
      <!-- end el-main -->
    </el-container>
    <!-- el-dialog -->
    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.isDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form>
        <el-form-item :label="dialogData.label">
          <el-input v-model="treeShowName" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item class="left">
          <el-button @click="onCancelTree">取消</el-button>
          <el-button @click="onConfirmTree" type="primary">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- end el-dialog -->

    <ItemSortDialog
      ref="ItemSortDialog"
      @success="onSearchItem"
    ></ItemSortDialog>

    <GroupMoveDialog
      ref="GroupMoveDialog"
      :treeData="treeData"
      :showType="showType"
      @success="updateGroupSort(showType)"
    ></GroupMoveDialog>

    <GroupSortDialog
      ref="GroupSortDialog"
      @success="updateGroupSort"
    ></GroupSortDialog>

    <DfGoodsDialog
      ref="DfGoodsDialog"
      :multiple="true"
      :title="selectItemType"
      :storesGuid="sourceStoresGuid"
      :itemState="selectItemState"
      :itemType="selectItemTypeValue"
      :asideEnabled="true"
      @onConfirm="onSelectItems"
    ></DfGoodsDialog>

    <DfSyncStoreDialog
      ref="SyncItemDialog"
      :syncFuction="synItemAndRefs"
      @success=""
    ></DfSyncStoreDialog>

    <BasicDialog
      ref="BasicDialog"
      :itemBasicList="itemBasicList"
      @success="initPageData"
    ></BasicDialog>

    <ReserveDialog
      ref="ReserveDialog"
      :dutyList="dutyList"
      :levelList="levelList"
      :itemWechatList="itemWechatList"
      @success="initPageData"
    ></ReserveDialog>

    <!-- 提示框组件 -->
    <!-- <Tooltip ref="tooltip" :maxWidth="240">
      <div class="u-content" v-if="content">{{ content }}</div>
    </Tooltip> -->
  </div>
</template>

<script>
import service from "@/api/service.js";
import EditDialog from "./components/edit-dialog.vue";
import ItemSortDialog from "./components/item-sort-dialog.vue";
import GroupSortDialog from "./components/group-sort-dialog.vue";
import GroupMoveDialog from "./components/group-move-dialog.vue";
import BasicDialog from "./components/basic-dialog.vue";
import ReserveDialog from "./components/reserve-dialog.vue";
// import Tooltip from "@/components/df-tooltip/df-tooltip.vue";
// import item from "../../../cashier/order/item";

export default {
  components: {
    EditDialog,
    ItemSortDialog,
    GroupSortDialog,
    GroupMoveDialog,
    BasicDialog,
    ReserveDialog,
    // Tooltip,
  },

  directives: {},

  data() {
    return {
      tableMaxHeight: "",
      innerWidth: 0,
      authType: "",
      loading: true,
      allCheck: false,
      showType: 0,
      treeData: [],
      currentNodeKey: "ALL",
      defaultExpandedKeys: ["ALL"],
      sourceStoresGuid: "",
      treeGuid: "",
      treeShowName: "",
      treeType: "",
      dialogData: {
        // 对话框数据
        title: "",
        isDialog: false,
        label: "",
      },
      requestPowerParam: {
        // 控制权限的对话框
        show: false,
        key: "",
        code: "",
        power: "",
        callback: () => {},
      },
      itemState: [
        { value: 1, label: "项目 - 正常" },
        { value: 2, label: "项目 - 已停用" },
        { value: 3, label: "项目 - 已删除" },
      ],
      storesList: [],
      selectItemState: 1,
      searchText: "",
      selectItemType: "消费项目",
      selectItemTypeValue: 1,
      tableData: [],
      selectItems: [],
      pageIndex: 1,
      pageSize: 15,
      totalRecord: 0,
      fromData: {},
      roundItem: [],
      deptList: [],
      dutyList: [],
      itemBasicList: [],
      itemClassList: [],
      unitsList: [],
      itemWechatList: [],
      levelList: [],
      deleteDisabled: false,
      recoveryDisabled: false,
      showDialog: false,
      isActiveCheck: false,
      allCheck: false,
      indeterminate: false,
      isPagination: true,
      etitle: "",

      // content: "",
      tooltipDisabled: true,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesList = accountInfo.manageStoresList;
    this.sourceStoresGuid = JSON.parse(
      JSON.stringify(this.authType == "H" ? this.storesList[0].StoresGuid : "")
    );

    this.initPageData();
    this.initTreeData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    // 初始化显示组树
    async initTreeData() {
      try {
        let { data } = await service.getItemshowTree({
          // stores_guid: this.sourceStoresGuid,
        });
        this.treeData = data;
      } catch (e) {}
    },

    // 初始化项目列表
    async initPageData(itemGuids = []) {
      this.loading = true;
      this.tableData = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;
      try {
        let submitData = {
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
          Word: this.searchText,
          ItemState: this.selectItemState,
          ItemType: this.selectItemTypeValue,
          ShowGuid: this.currentNodeKey,
          // StoresGuid: this.sourceStoresGuid,
          ItemGuids: itemGuids,
        };
        let { data, errcode } = await service.getItemMaintItemList(submitData);
        // console.log(data);
        if (errcode == 0) {
          let { filter, itemMaintItemList } = await data;
          this.totalRecord = filter.TotalRecord;
          if (itemGuids.length > 0) {
            this.isActiveCheck = true;
            this.onSelectChange("all", itemMaintItemList);
            return;
          } else if (this.selectItems.length > 0) {
            this.tableData = itemMaintItemList;
            this.onRowSelection();
            return;
          }
          this.tableData = itemMaintItemList;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // 同步
    async synItemAndRefs(event, callback) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems));
      // console.log(selectItems, this.selectItems);
      // return new Promise(async (resolve, reject) => {
      try {
        let res = await service.synItemAndRefs(event.authKey, event.authCode, {
          SourceStoresGuid: window.sessionStorage.getItem("selectStoreGuid"),
          TargetStoresGuid: event.targetStoresGuid,
          SelectItemGuids: selectItems.map((e) => e.ItemGuid),
        });
        // await callback(res)
        // await resolve(res);
        return res;
      } catch (err) {
        console.log(err);
        return err;
        // await callback(err)
        // await reject(err);
      }
      // });
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight, innerWidth } = this.util.getDocumentElementInfo();
      // console.log(innerWidth);
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 52 + 40);
      this.innerWidth = innerWidth;
    },

    onClipboard(text) {
      let that = this;
      // console.log(text);
      this.$copyText(text).then(() => {
        that.$message.success("已复制到剪贴板!");
      });
    },

    onCheckTooltip(event) {
      console.log(event);
      return true;
    },

    // 选择项目状态
    onChangeItemState() {
      this.initPageData();
    },

    // 门店改变事件
    onChangeStores() {
      // console.log(this.sourceStoresGuid);
      this.initTreeData();
      this.initPageData();
    },

    // 多选框改变事件
    async onSelectChange(event = "", row) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems)),
        tableData = JSON.parse(JSON.stringify(row));
      this.loading = true;
      if (event == "all") {
        selectItems = [];
        await tableData.forEach((e) => {
          if (this.isActiveCheck) {
            e.isCheck = true;
            selectItems.push(e);
          } else {
            selectItems = [];
            e.isCheck = false;
          }
        });
        this.tableData = await tableData;
      } else {
        let index = await selectItems.findIndex(
          (e) => e.ItemGuid == row.ItemGuid
        );
        index > -1
          ? selectItems.splice(index, 1)
          : (selectItems = [...selectItems, row]);
        row.isCheck = !row.isCheck;
      }

      this.selectItems = await selectItems;
      await this.onAllSelection();
      await setTimeout(() => {
        this.loading = false;
      }, 200);
      // this.selectItems = [...this.selectItems, row]
      // console.log(this.isActiveCheck, this.allCheck, this.indeterminate);
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(async () => {
        let count = 0;
        // console.log(this.tableData, this.selectItems);
        await this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].ItemGuid === item.ItemGuid) {
              count++;
              // console.log(this.tableData[index], item);
              // this.$refs.multipleTable.toggleRowSelection(
              //   this.tableData[index],
              //   true
              // );
              this.tableData[index].isCheck = true;
              break;
            }
          }
        });

        if (count > 0) {
          await this.onAllSelection();
        } else {
          this.isActiveCheck = false;
          this.allCheck = false;
          this.indeterminate = false;
        }
      });
    },

    // 全选回显
    async onAllSelection() {
      this.isActiveCheck = (await this.selectItems.length) > 0 ? true : false;
      this.allCheck =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length == this.tableData.length
          ? true
          : false;
      this.indeterminate =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length != this.tableData.length
          ? true
          : false;
    },

    //
    nodeClick(data, dragNode, enterNode) {
      this.resetData();
      this.currentNodeKey = data.id;
      this.defaultExpandedKeys = [data.id];
      this.initPageData();
    },

    // 判断节点能否被拖拽, 如果为 false 不允拖动。
    allowDrag(draggingNode) {
      return draggingNode.data.label.indexOf("全部显示组") === -1;
    },

    onShowTip(event, content) {
      let style = window.getComputedStyle(event.target, null),
        fontSize = style.fontSize
          ? Number(style.fontSize.substring(0, style.fontSize.length - 2))
          : 18;

      if (content.length * fontSize > event.target.offsetWidth) {
        this.tooltipDisabled = false;
      }
      // this.content = content;
      // this.$refs.tooltip.show(e.target);
    },

    onHideTip() {
      this.$refs.tooltip.onHide();
    },

    // 添加节点
    onAddTree(event, data) {
      this.treeType = "add";
      this.treeGuid = data.id;
      this.treeShowName = "";
      this.dialogData.title = "新建子类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },

    // 修改节点
    onEditTree(event, data) {
      this.treeType = "edit";
      this.treeShowName = data.label;
      this.treeGuid = data.id;
      this.dialogData.title = "修改分类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },

    // 删除节点
    onDeleteTree(event, _data) {
      this.$confirm("是否删除该分类?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.deleteDisabled = true;
          try {
            let { data, errcode } = await service.deleteItemShow({
              show_guid: _data.id,
            });
            if (errcode == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.dialogData.isDialog = false;
              this.initTreeData();
            } else {
              this.$message.error("删除失败!");
            }
          } catch (e) {
            console.error(e);
          } finally {
            this.deleteDisabled = false;
          }
        })
        .catch(() => {});
    },

    onCancelTree() {
      this.dialogData.isDialog = false;
    },

    // 打开项目对话框
    onShowGoods() {
      this.$refs.DfGoodsDialog.onShowDialog();
    },

    // 打开移动组别对话框
    onShowGroupMove(showType = 0) {
      let arr = this.selectItems.map((e) => e.ItemGuid);
      if (showType == 0 && this.currentNodeKey == "ALL") {
        return this.$message.error(
          "您所选择的组别不可移动，请选择它的子级组别"
        );
      } else if (showType == 1 && !arr.length) {
        return this.$message.error("请先选择项目");
      }

      this.showType = showType;
      // console.log(arr);
      this.$refs.GroupMoveDialog.onShowDialog(
        showType == 0 ? [this.currentNodeKey] : arr
      );
    },

    // 打开同步对话框
    onShowSync() {
      this.util.checkPower("1007", this.requestPowerParam, async () => {
        let arr = await this.selectItems.map((e) => e.ItemGuid);
        if (!arr.length) {
          return this.$message.error("请先选择项目");
        }
        await this.$refs.SyncItemDialog.onShowDialog(arr);
      });
    },

    async onConfirmTree() {
      try {
        if (this.treeType == "add") {
          let submitData = {
            FatherGuid: this.treeGuid,
            ShowName: this.treeShowName,
          };
          let { data, errcode } = await service.addItemShow(submitData);
          if (errcode == 0) {
            this.dialogData.isDialog = false;
            this.initTreeData();
            this.$message({
              type: "success",
              message: "添加成功!",
            });
          } else {
            this.$message.error("添加失败!");
          }
        } else {
          let submitData = {
            ShowGuid: this.treeGuid,
            ShowName: this.treeShowName,
          };
          let { data, errcode } = await service.updateItemShow(submitData);
          if (errcode == 0) {
            this.dialogData.isDialog = false;
            this.initTreeData();
            this.$message({
              type: "success",
              message: "修改成功!",
            });
          } else {
            this.$message.error("修改失败!");
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    // 选中项目回调
    async onSelectItems(event) {
      let { selectItems } = event;
      let arr = await selectItems.map((item) => {
        return item.ItemGuid;
      });
      // console.log(arr);
      await this.initPageData(arr);
    },

    onChangePagination() {
      this.handleSizeChange(this.isPagination ? 10000 : 15);
      this.isPagination = !this.isPagination;
    },

    // 改变页面数据条数
    handleSizeChange(event) {
      this.pageIndex = 1;
      this.pageSize = event;
      this.initPageData();
    },

    // 改变页面码
    handleCurrentChange(event) {
      this.pageIndex = event;
      this.initPageData();
    },

    // 打开显示组排序对话框
    onShowGroupSort() {
      if (this.currentNodeKey == "ALL") {
        return this.$message.error(
          "您所选择的显示组不可排序，请选择它的子级组别"
        );
      }
      this.$refs.GroupSortDialog.onShowDialog({
        show_guid: this.currentNodeKey,
      });
    },

    // 打开项目排序对话框
    onShowItemSort() {
      if (this.currentNodeKey == "ALL") {
        return this.$message.error(
          "您所选择的显示组不可排序，请选择它的子级组别"
        );
      }
      this.$refs.ItemSortDialog.onShowDialog({
        father_guid: this.currentNodeKey,
        item_state: this.selectItemState,
      });
    },

    // 打开批量设置基类对话框
    async onShowBasic() {
      let arr = await this.selectItems.map((e) => e.ItemGuid);
      if (!arr.length) {
        return this.$message.error("请先选择项目");
      }
      await this.initItemData();
      await this.$refs.BasicDialog.onShowDialog(arr);
    },

    // 打开批量设置预约项目对话框
    async onShowReserve() {
      let arr = await this.selectItems.map((e) => e.ItemGuid);
      if (!arr.length) {
        return this.$message.error("请先选择项目");
      }
      await this.initItemData();
      await this.$refs.ReserveDialog.onShowDialog(arr);
    },

    // 更新显示组树叉数据，重新选中节点
    async updateGroupSort(showType = 0) {
      await this.initTreeData();
      await this.$nextTick(() => {
        let tree = this.$refs.Etree;
        tree.setCurrentKey(this.currentNodeKey);
      });
      if (showType == 1) {
        this.initPageData();
      }
      this.resetData();
    },

    onChange(event) {
      switch (event) {
        case "消费项目":
          this.selectItemTypeValue = 1;
          break;
        case "预约项目":
          this.selectItemTypeValue = 2;
          break;
        case "产品管理":
          this.selectItemTypeValue = 3;
          break;
        default:
          this.selectItemTypeValue = 0;
          break;
      }
      this.pageIndex = 1;
      this.initPageData();
    },

    dataFormat() {
      return {
        ShowGuid: this.currentNodeKey,
        ItemName: "",
        ClassGuid: "",
        Mnemonic: "",
        Barcode: "",
        LevelGuid: "",
        ListDeptGuid: [],
        ItemNote: "",
        WechatGuid: "",
        PriceChange: 0,
        CountChange: 0,
        ModifyRoya: 0,
        BookingTime: "",
        RoundItem: 0,
        Price: "",
        Picture: "",
        PictureUrl: "",
        ItemSell: 0,
        ListBasicGuid: [],
        IgnoreDuty: [],
      };
    },

    async initItemData(item_guid, callback) {
      try {
        let submitData = item_guid ? { item_guid } : {};
        let { data } = await service.initSaveItemData(submitData);
        let {
          RoundItem,
          deptList,
          dutyList,
          itemBasicList,
          itemClassList,
          itemWechatList,
          levelList,
          initItem,
          unitsList,
        } = data;
        let etitle = await "修改项目";
        if (!initItem) {
          initItem = await this.dataFormat();
          etitle = await "添加项目";
        }

        if (initItem.LevelGuid && initItem.LevelGuid.length > 4) {
          for (let i = 0; i < levelList.length; i++) {
            if (levelList[i].LevelGuid == initItem.LevelGuid) {
              for (let j = 0; j < dutyList.length; j++) {
                if (dutyList[j].DutyNo == levelList[i].DutyNo) {
                  initItem.DutyGuid = dutyList[j].DutyNo;
                  break;
                }
              }
            }
          }
        } else {
          let arr = dutyList.filter(
            (item) => item.DutyNo == initItem.LevelGuid
          );
          arr.length ? (initItem.DutyGuid = arr[0].DutyNo) : "";
          initItem.LevelGuid = initItem.LevelGuid ? "ALL" : "";
        }

        this.roundItem = RoundItem;
        this.deptList = deptList;
        this.dutyList = dutyList;
        this.itemBasicList = itemBasicList;
        this.itemClassList = itemClassList;
        this.unitsList = unitsList;
        this.itemWechatList = itemWechatList;
        this.levelList = levelList;
        this.fromData = initItem;
        this.etitle = etitle;
        callback ? callback() : "";
        // this.showDialog = true;
      } catch (e) {}
    },

    // 添加项目
    async addItem() {
      if (this.currentNodeKey == "ALL") {
        this.$message({
          message: "请选择左边的显示组, 在点击‘新增项目’",
          type: "warning",
        });
        return;
      }
      await this.initItemData("", () => {
        this.showDialog = true;
      });
    },

    async onAlterItem(event) {
      this.initItemData(event.ItemGuid, () => {
        this.showDialog = true;
      });
      this.$refs.blow.onChangeDuty(this.fromData.DutyGuid);
      this.$refs.blow.dialog = true;
    },

    // 删除
    onDeleteItem(event) {
      this.$confirm(`是否删除该‘${event.ItemName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await service.deleteItem({
              itemGuidList: [event.ItemGuid],
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    // 停用
    disableItem(event) {
      this.$confirm(`是否停用该‘${event.ItemName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await service.disableItem({
              itemGuidList: [event.ItemGuid],
            });
            if (errcode == 0) {
              this.$message({
                message: "停用成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    // 恢复
    recoveryItem(event) {
      this.$confirm(`是否恢复该‘${event.ItemName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await service.recoveryItem({
              itemGuidList: [event.ItemGuid],
            });
            if (errcode == 0) {
              this.$message({
                message: "恢复成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    // 搜索项目
    onSearchItem() {
      this.pageIndex = 1;
      this.initPageData();
    },

    //
    callFatherFunction() {
      this.showDialog = false;
    },

    resetData() {
      this.selectItems = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;
    },

    closeDialog() {
      // this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  margin-top: 20px;
  .el-aside {
    height: calc(100vh - 160px);
    border-right: 1px solid #d3dce6;
    .el-select {
      // width: 219px;
      width: auto;
      margin-bottom: 10px;
      box-sizing: border-box;
    }

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 0;
      .el-dropdown-link {
        display: inline-block;
        height: 36px;
        width: 36px;
        text-align: center;
        line-height: 36px;
        color: #bfcbd9;
      }
      .el-dropdown-link:hover {
        background-color: #d3dce6;
        color: #8492a6;
      }
    }
  }
  .el-main {
    position: relative;
    padding: 0 0 0 20px;

    .top {
      display: flex;
      align-items: unset;
      justify-content: space-between;
      margin-bottom: 10px;
      .left {
        min-width: 340px;
      }
      .right {
        .flex-col;
        flex-wrap: wrap;
        // justify-content: start;
        // max-width: 500px;
        margin-bottom: 0;

        .content {
          .flex-row;
          flex-wrap: wrap;
        }

        .other {
          .flex-row;
          width: 100%;
          flex-wrap: wrap;

          .df-row {
            .flex-row;
            justify-content: flex-end;
            width: 100%;
            margin-top: 10px;
          }
          .right {
            display: flex;
            // justify-content: flex-end;
          }
        }
      }
      .el-input {
        width: 200px !important;
      }
    }

    .el-table {
      .el-table__row {
        position: relative;
      }
      .image-text {
        .flex-row;
        .image-slot {
          i {
            font-size: 50px;
            color: #8492a6;
          }
        }
        .image {
          min-width: 56px;
          min-height: 56px;
          width: 56px;
          height: 56px;
          border-radius: 5px;
          overflow: hidden;

          .el-image {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 56px;
          margin-left: 10px;

          span {
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .size {
            font-size: 10px;
          }

          .el-tooltip {
            padding: 0 !important;
          }

          .item-name[data-title] {
            // 样式：
            &:hover:after {
              opacity: 1;
              transition: all 0.1s ease 0.5s;
              visibility: visible;
              white-space: unset;
              word-wrap: break-word;
              word-break: break-all;
            }
            // 背景的样式 位置 字体等
            &:after {
              content: attr(data-title);
              position: absolute;
              padding: 1px 10px;
              left: -30px;
              top: 40px;
              max-width: 200px;
              border-radius: 4px;
              color: #fff;
              background-color: rgba(80, 79, 79, 0.8);
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
              font-size: 14px;
              white-space: nowrap;
              visibility: hidden;
              opacity: 0;
            }
          }
        }
      }
    }

    .pagination-box {
      .flex-row;
      justify-content: flex-end;
      margin-top: 20px;

      .el-button {
        margin-right: 20px;
      }
    }
  }
}

.el-dialog {
  .left {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

.colorBlue {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 1552px) {
  .el-container {
    .df-row {
      .flex-row;
      // align-items: unset;
      flex-wrap: wrap;
      // margin-block: 20px 10px;

      .df-col {
        margin-block: 0 10px;
        .el-date-editor {
          width: 240px;
        }
      }
    }
  }

  .el-main {
    .top {
      flex-wrap: wrap;
      .left {
        width: 100%;
        // min-width: 340px;
      }
      .right {
        .flex-row;
        margin-top: 10px;

        // .other {
        //   .flex-row;
        //   width: 100%;
        //   flex-wrap: wrap;

        //   .df-row {
        //     .flex-row;
        //     justify-content: flex-end;
        //     width: 100%;
        //     margin-top: 10px;
        //   }
        //   .right {
        //     display: flex;
        //     // justify-content: flex-end;
        //   }
        // }
      }
    }
  }
}
</style>
