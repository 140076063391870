<template>
  <div class="summary">
    <div class="df-row">
      <div class="df-col">
        <DfDatePicker
          :date="selectDateValue"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :shortcuts="['today', 'yesterday', 'month', 'lastMonth']"
          @change="onChangeDate"
          @shortcutClick="onShortcutClick"
        ></DfDatePicker>
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>
    </div>

    <div v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="box">
            <h1>
              {{ initData.WorkPerforTotal || 0 }}
            </h1>
            <h4>总劳动业绩</h4>
            <div class="card">
              <p>
                {{ "项目劳动业绩: " + initData.WorkNormalPerforTotal || 0 }}
              </p>
              <p>{{ "疗程劳动业绩: " + initData.WorkPackPerforTotal || 0 }}</p>
            </div>
          </div>
        </el-col>
        <!-- <el-col :span="6">
        <div class="box">
          <h1>{{ initData.WorkPackPerforTotal }}</h1>
          <h4>疗程劳动业绩</h4>
        </div>
      </el-col> -->
        <el-col :span="12">
          <div class="box df-box cash-pay">
            <div>
              <h1>{{ initData.CashPayTotal || 0 }}</h1>
              <h4>实收现金</h4>
              <div class="card">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowEchart('cashPayments')"
                  >查看分析图</el-button
                >
              </div>
            </div>
            <div>
              <div class="number">
                <ul v-if="initData.CashPayments">
                  <li
                    v-for="(item, index) in initData.CashPayments"
                    :key="index"
                  >
                    {{ item.Name + ": " + item.Amount }}
                  </li>
                  <li>{{ "日常支出: " + initData.SpendExpense || 0 }}</li>
                  <li>{{ "日常收入: " + initData.SpendIncome || 0 }}</li>
                </ul>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="box" v-if="initData.OpenAndKeepData">
            <h1>{{ initData.OpenAndKeepData.TotalPerfor || 0 }}</h1>
            <h4>开充卡业绩</h4>
            <div class="card">
              <p>储值卡业绩：{{ initData.OpenAndKeepData.OpenPerfor || 0 }}</p>
              <p>充值业绩: {{ initData.OpenAndKeepData.KeepPerfor || 0 }}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.OpenPackCardPerforTotal || 0 }}</h1>
            <h4>开疗程卡业绩</h4>
          </div>
        </el-col>
        <el-col :span="12">
          <div
            class="box df-box member-analysis"
            v-if="initData.ClientCategoryData && categoryType == 'Client'"
          >
            <div>
              <h1>{{ initData.ClientCategoryData.TotalPerson || 0 }}</h1>
              <div
                style="display: flex; align-items: center; flex-wrap: wrap; margin-left: 20px;"
                class="df-row"
              >
                <h4 style="margin: 0; margin-right: 20px;">总客数</h4>
                <el-button
                  style="height: 30px; margin-left: 0;"
                  type="primary"
                  size="mini"
                  @click="onChangeType"
                  >切换客次</el-button
                >
              </div>
              <div class="card">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowEchart('category')"
                  >查看分析图</el-button
                >
              </div>
            </div>
            <div class="df-more">
              <div class="number">
                <ul>
                  <li>
                    男客数：{{ initData.ClientCategoryData.MalePerson || 0 }}
                  </li>
                  <li>
                    会员客数:
                    {{ initData.ClientCategoryData.MemberPerson || 0 }}
                  </li>
                  <li>
                    指定客数:
                    {{ initData.ClientCategoryData.AssignPerson || 0 }}
                  </li>
                  <li>
                    现金客数:
                    {{ initData.ClientCategoryData.CashPayPerson || 0 }}
                  </li>
                  <li>
                    其他付款:
                    {{ initData.ClientCategoryData.OtherPayPerson || 0 }}
                  </li>
                </ul>
              </div>
              <div class="number">
                <ul>
                  <li>
                    女客数: {{ initData.ClientCategoryData.FemalePerson || 0 }}
                  </li>
                  <li>
                    散客数:
                    {{ initData.ClientCategoryData.NotMemberPerson || 0 }}
                  </li>
                  <li>
                    非指定客数:
                    {{ initData.ClientCategoryData.NotAssignPerson || 0 }}
                  </li>
                  <li>
                    卡扣客数:
                    {{ initData.ClientCategoryData.CardPayPerson || 0 }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="box df-box member-analysis"
            v-else-if="initData.BillCategoryData && categoryType == 'Bill'"
          >
            <div>
              <h1>{{ initData.BillCategoryData.TotalBill || 0 }}</h1>
              <div
                style="display: flex; align-items: center; flex-wrap: wrap; margin-left: 20px;"
                class="df-row"
              >
                <h4 style="margin: 0; margin-right: 20px;">总客次</h4>
                <el-button
                  style="height: 30px; margin-left: 0;"
                  type="primary"
                  size="mini"
                  @click="onChangeType"
                  >切换客数</el-button
                >
              </div>
              <div class="card">
                <el-button
                  type="primary"
                  size="mini"
                  @click="onShowEchart('category')"
                  >查看分析图</el-button
                >
              </div>
            </div>
            <div class="df-more">
              <div class="number">
                <ul>
                  <li>男客次：{{ initData.BillCategoryData.MaleBill || 0 }}</li>
                  <li>
                    会员客次: {{ initData.BillCategoryData.MemberBill || 0 }}
                  </li>
                  <li>
                    指定客次: {{ initData.BillCategoryData.AssignBill || 0 }}
                  </li>
                  <li>
                    现金客次: {{ initData.BillCategoryData.CashPayBill || 0 }}
                  </li>
                  <li>
                    其他付款: {{ initData.BillCategoryData.OtherPayBill || 0 }}
                  </li>
                </ul>
              </div>
              <div class="number">
                <ul>
                  <li>
                    女客次: {{ initData.BillCategoryData.FemaleBill || 0 }}
                  </li>
                  <li>
                    散客次: {{ initData.BillCategoryData.NotMemberBill || 0 }}
                  </li>
                  <li>
                    非指定客次:
                    {{ initData.BillCategoryData.NotAssignBill || 0 }}
                  </li>
                  <li>
                    卡扣客次: {{ initData.BillCategoryData.CardPayBill || 0 }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.SellPerforTotal || 0 }}</h1>
            <h4>外卖产品</h4>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.NewMemberCount || 0 }}</h1>
            <h4>新开发会员</h4>
            <div class="card">
              <p>会员转化率：{{ initData.NewMemberRate || 0 }}%</p>
              <p>散客次: {{ initData.NotMemberBillCount || 0 }}</p>
              <p>总客次: {{ initData.TotalBillCount || 0 }}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.MemberArrivalData.ArrivalRate || 0 }}%</h1>
            <h4>到店率</h4>
            <div class="card">
              <p>
                到店会员数: {{ initData.MemberArrivalData.ArrivalCount || 0 }}
              </p>
              <p>
                总会员数：{{ initData.MemberArrivalData.TotalMemberCount || 0 }}
              </p>
              <p>
                未到店会员数:
                {{ initData.MemberArrivalData.UnArrivalCount || 0 }}
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.AvgBillPrice || 0 }}</h1>
            <h4>客单价</h4>
            <div class="card">
              <p>总客次：{{ initData.TotalBillCount || 0 }}</p>
              <p>总业绩: {{ initData.TotalWorkSellPerfor || 0 }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.VipRefundAmount || 0 }}</h1>
            <h4>会员卡退款</h4>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="box">
            <h1>{{ initData.ShopRefundAmount || 0 }}</h1>
            <h4>商城退款</h4>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      :width="echartData.length > 1 ? '1100px' : '700px'"
      :top="echartData.length > 1 ? '3%' : '5%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="showDialog = false"
    >
      <div class="content">
        <div class="df-row">
          <div class="df-col" v-for="(item, index) in echartData" :key="index">
            <div :id="`echarts${(index + 1).toString()}`" class="echarts"></div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="showDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/report.js";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default {
  data() {
    return {
      title: "",
      categoryType: "Bill",
      pickerActive: "",
      initData: {
        MemberArrivalData: {},
        WorkNormalPerforTotal: "",
        WorkPackPerforTotal: "",
      },
      submitData: {
        begin_date: "",
        end_date: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerSelector: [
        { name: "今天", value: "today" },
        { name: "昨天", value: "yesterday" },
        { name: "本月", value: "month" },
        { name: "上月", value: "lastMonth" },
      ],
      selectDateValue: [],
      echartData: [],
      loading: false,
      showDialog: false,
      excelDisabled: false, // 表格按钮禁用
    };
  },

  async mounted() {
    await this.initDateState();
    await this.initPageData();
  },

  methods: {
    // 初始化数据
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.businessSummary(this.submitData);
        this.initData = data;
        // console.log(data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.selectDateValue = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    // 日期改变事件
    async onChangeDate(event) {
      // console.log(event);
      await this.$store.dispatch("onChangeDateState", { dayTime: event });
      await this.initDateState();
    },

    async onShortcutClick(event) {
      await this.onChangeDate(event);
      await this.initPageData();
    },

    // 查询按钮点击事件
    onSearch() {
      this.initPageData();
    },

    onChangeType() {
      this.categoryType = this.categoryType == "Client" ? "Bill" : "Client";
    },

    // 打开图表
    async onShowEchart(type) {
      let { initData, categoryType } = this,
        arr = [];

      if (type == "cashPayments") {
        arr = [
          {
            title: "实收现金分析图",
            data: [],
          },
        ];
        await initData.CashPayments.forEach((e) => {
          arr[0].data.push({ name: e.Name, value: e.Amount });
        });
        arr[0].data = await [
          ...arr[0].data,
          { name: "日常支出", value: initData.SpendExpense },
          { name: "日常收入", value: initData.SpendIncome },
        ];
      } else if (type == "category") {
        let name = categoryType == "Bill" ? "客次" : "客数",
          param = {
            assign:
              categoryType == "Bill"
                ? initData.BillCategoryData.AssignBill
                : initData.ClientCategoryData.AssignPerson,
            cardPay:
              categoryType == "Bill"
                ? initData.BillCategoryData.CardPayBill
                : initData.ClientCategoryData.CardPayPerson,
            cashPay:
              categoryType == "Bill"
                ? initData.BillCategoryData.CashPayBill
                : initData.ClientCategoryData.CashPayPerson,
            female:
              categoryType == "Bill"
                ? initData.BillCategoryData.FemaleBill
                : initData.ClientCategoryData.FemalePerson,
            male:
              categoryType == "Bill"
                ? initData.BillCategoryData.MaleBill
                : initData.ClientCategoryData.MalePerson,
            member:
              categoryType == "Bill"
                ? initData.BillCategoryData.MemberBill
                : initData.ClientCategoryData.MemberPerson,
            notAssign:
              categoryType == "Bill"
                ? initData.BillCategoryData.NotAssignBill
                : initData.ClientCategoryData.NotAssignPerson,
            notMember:
              categoryType == "Bill"
                ? initData.BillCategoryData.NotMemberBill
                : initData.ClientCategoryData.NotMemberPerson,
            otherPay:
              categoryType == "Bill"
                ? initData.BillCategoryData.OtherPayBill
                : initData.ClientCategoryData.OtherPayPerson,
            total:
              categoryType == "Bill"
                ? initData.BillCategoryData.TotalBill
                : initData.ClientCategoryData.TotalPerson,
          };

        arr = [
          {
            title: `男/女${name}占比分析图`,
            data: [
              {
                name: `男${name}`,
                value: param.male,
              },
              {
                name: `女${name}`,
                value: param.female,
              },
            ],
          },
          {
            title: `会员/散${name}占比分析图`,
            data: [
              {
                name: `会员${name}`,
                value: initData.ClientCategoryData.MemberPerson,
              },
              {
                name: `散${name}`,
                value: initData.ClientCategoryData.NotMemberPerson,
              },
            ],
          },
          {
            title: `指定/非指定${name}占比分析图`,
            data: [
              {
                name: `指定${name}`,
                value: param.assign,
              },
              {
                name: `非指定${name}`,
                value: param.notAssign,
              },
            ],
          },
          {
            title: `现金/卡扣${name}占比分析图`,
            data: [
              {
                name: `现金${name}`,
                value: param.cashPay,
              },
              {
                name: `卡扣${name}`,
                value: param.cardPay,
              },
              {
                name: "其他付款",
                value: param.otherPay,
              },
            ],
          },
        ];
      }

      this.echartData = arr;
      this.showDialog = true;
      console.log(arr);

      this.$nextTick(() => {
        arr.forEach((item, index) => {
          const echartsDom = this.$echarts.init(
            document.getElementById(`echarts${(index + 1).toString()}`)
          );

          echartsDom.setOption({
            title: {
              text: item.title,
              // subtext: "Fake Data",
              left: "center",
              top: "8%",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              // top: "5%",
              // left: "centet",
              bottom: "0",
            },
            series: [
              {
                // name: "Access From",
                type: "pie",
                radius: arr.length > 1 ? ["20%", "45%"] : ["30%", "55%"],
                // avoidLabelOverlap: true,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: "#fff",
                  borderWidth: 2,
                  minWidth: 10,
                },
                labelLine: {
                  show: true,
                },
                label: {
                  formatter: "{b}：{c}",
                  // show: false,
                  fontSize: 14,
                },
                data: item.data,
              },
            ],
          });
        });
      });
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(async () => {
        try {
          // let tableArray = [
          //   ['']
          // ]

          // this.selectDateValue.forEach(item=>{
          //   tableArray
          // })

          // return console.log(this.selectDateValue);

          try {
            //创建工作簿
            var workbook = XLSX.utils.book_new();
            // var worksheet = XLSX.utils.json_to_sheet(this.tableData,{header:['A','B','C'],skipHeader:true})
            var ws = XLSX.utils.json_to_sheet(
              [
                {
                  S: { t: "n", v: 1111, s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
                  h: 2,
                  e: 3,
                  e_1: { t: "n", v: 999, s: { r: 1, c: 4 }, e: { r: 1, c: 6 } },
                  t: 5,
                  J: 6,
                  S_1: 7,
                },
              ],
              { header: ["S", "h", "e", "e_1", "t", "J", "S_1"] }
            );
            console.log(ws);
            //工作簿、工作表、工作表名
            XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
            let wbout = XLSX.writeFile(workbook, "测试111.xlsx");
          } catch (err) {
            typeof console !== "undefined" ? console.log(err, wbout) : null;
          }
          // console.log(dom);
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.summary {
  > .df-row {
    flex-wrap: wrap;
    margin: 15px 0;
    .df-col{
      margin: 5px 10px 5px 0;
    }
  }
}

.el-row {
  margin-top: 20px;

  .el-button {
    margin-left: 10px;
  }

  .el-date-editor {
    max-width: 350px !important;
  }

  .box {
    height: 200px;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 5px;
    h4,
    h1 {
      margin: 20px;
    }
    h4 {
      color: #0ae;
    }
    h1 {
      font-size: 34px;
    }

    .card {
      margin: 0 20px;
      // color: #999999;
    }
  }

  .member-analysis,
  .cash-pay {
    h4 {
      // margin-bottom: 0;
    }
    .card {
      .el-button {
        margin: 0;
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }

  .box:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .df-box {
    display: flex;
    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      flex: 2;
    }

    .df-more {
      display: flex;
      > div {
        flex: 1;
      }
    }

    .number {
      height: 200px;
      // color: #999999;

      ul {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0;
        padding: 20px;
        box-sizing: border-box;

        li {
          margin: 5px 0;
        }
      }
    }
  }
}
.el-dialog {
  .content {
    .df-row {
      .flex-row;
      justify-content: center;
      flex-wrap: wrap;
      .df-col {
        padding: 0;
        margin: 0;
      }
      ::v-deep .echarts {
        .flex-row;
        justify-content: center;
        width: 500px;
        height: 500px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
