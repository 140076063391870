<template>
  <div v-if="shortcuts.length > 0" class="df-shortcut-date">
    <div
      class="shortcut-btn"
      v-for="(item, index) in pickerSelector"
      :key="index"
    >
      <el-button
        :type="pickerActive == item.value ? 'primary' : ''"
        size="mini"
        @click="onChangePicker(item.value)"
        >{{ item.name }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: [Array, String],
      default: () => [],
    },
    shortcuts: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    date: {
      handler() {
        this.checkPickerChange();
      },
      deep: true,
    },
    shortcuts: {
      handler() {
        this.initShortcut();
      },
      deep: true,
    },
  },

  data() {
    return {
      pickerActive: "",
      pickerShortcutList: [
        { name: "今天", value: "today" },
        { name: "昨天", value: "yesterday" },
        { name: "本月", value: "month" },
        { name: "上月", value: "lastMonth" },
      ],
      pickerSelector: [],
    };
  },

  methods: {
    // 初始化快捷按钮
    async initShortcut() {
      let { pickerShortcutList, shortcuts } = this,
        arr = [];
      await shortcuts.forEach((item) => {
        for (let i = 0; i < pickerShortcutList.length; i++) {
          if (item == pickerShortcutList[i].value) {
            arr.push(pickerShortcutList[i]);
            break;
          }
        }
      });
      this.pickerSelector = await arr;
    },

    // 按钮改变事件
    async onChangePicker(event) {
      let dateValue = [];
      switch (event) {
        case "today":
          dateValue = [
            this.util.getNowMonthToday(),
            this.util.getNowMonthToday(),
          ];
          break;
        case "yesterday":
          dateValue = [
            this.util.dateFormat(
              "YYYY-mm-dd",
              this.util.dateAdd(new Date(), "d", -1)
            ),
            this.util.dateFormat(
              "YYYY-mm-dd",
              this.util.dateAdd(new Date(), "d", -1)
            ),
          ];
          break;
        case "lastMonth":
          dateValue = [
            this.util.getLastNthMonthBegin(),
            this.util.getLastNthMonthEnd(),
          ];
          break;
        default:
          dateValue = [
            this.util.getNowMonthFirst(),
            this.util.getNowMonthToday(),
          ];
      }
      //   this.pickerActive = event;
      this.$emit("change", dateValue);
      //   await this.onChangeDate(dateValue);
      //   await this.initPageData();
    },

    // 确认日期的回显
    checkPickerChange() {
      let today = this.util.getNowMonthToday(),
        yesterday = this.util.dateFormat(
          "YYYY-mm-dd",
          this.util.dateAdd(new Date(), "d", -1)
        ),
        monthBegin = this.util.getNowMonthFirst(),
        lastMonthBegin = this.util.getLastNthMonthBegin(),
        lastMonthEnd = this.util.getLastNthMonthEnd(),
        begin = this.date[0],
        end = this.date[1];
      this.pickerActive = "";

      if (begin == end && begin == today) {
        this.pickerActive = "today";
      } else if (begin == end && begin == yesterday) {
        this.pickerActive = "yesterday";
      } else if (begin == monthBegin && end == today) {
        this.pickerActive = "month";
      } else if (begin == lastMonthBegin && end == lastMonthEnd) {
        this.pickerActive = "lastMonth";
      }
      // console.log(lastMonthBegin, lastMonthEnd);
    },
  },
};
</script>

<style lang="less" scoped>
.df-shortcut-date {
  .flex-row;
  // flex-wrap: wrap;
  .shortcut-btn {
    margin-right: 10px;
  }
}
</style>
