import Vue from "vue";
import util from "@/utils/utils";
import store from "@/store/index";
import VueRouter from "vue-router";
import cashier from "./cashier.js";
import member from "./member.js";
import service from "./service.js";
import setting from "./setting.js";
import employees from "./employees.js";
import marketing from "./marketing.js";
import report from "./report.js";
import pressing from "./pressing.js";
import customer from "./customer.js";
import shop from "./shop.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "用户登录",
      keepAlive: true,
    },
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/index",
    name: "Index",
    redirect: "/cashier",
    meta: {
      title: "首页",
      keepAlive: true,
    },
    components: {
      default: () => import("../views/index/index.vue"),
      reportEmplRoya: () => import("../views/report/employee/roya/roya.vue"),
    },
    children: [
      /** 前台 */
      cashier,
      /** 会员 */
      member,
      /** 服务与商品 */
      service,
      /* 设置 */
      setting,
      /* 员工 */
      employees,
      /* 营销 */
      marketing,
      /* 经营与财务 */
      report,
      /* 紧急报警 */
      pressing,
      /* 客情管理 */
      customer,
      /* 商城 */
      shop,
    ],
  },
  {
    path: "/alarm",
    name: "Alarm",
    meta: {
      title: "报警记录",
      keepAlive: true,
    },
    component: () => import("../views/pressing/alarm/alarm.vue"),
  },
  {
    path: "/routerTest",
    name: "RouterTest",
    meta: {
      title: "跳转测试",
      keepAlive: true,
    },
    component: () => import("../views/test.vue"),
  },
  {
    path: "*",
    component: () => import("../views/page404/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // document.querySelector('meta[name="viewport"]').content =
  //   "width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no";

  // console.log(to.path);
  let token = window.sessionStorage.getItem("token");
  // util.elapseNext(`token: ${token}, to: ${to.path}, from: ${from.path}`);
  if (to.path == "/login" || to.path == "/routerTest") {
    next();
    // util.elapseNext(`正常跳转`);
  } else if (token != null) {
    next();
    // util.elapseNext(`正常跳转`);
  } else {
    next({
      path: "/login",
    });
    // util.elapseNext(`重定向跳转`);
  }
  // util.elapseEnd('router.beforeEach');
});

router.afterEach((to, from) => {
  // document.addEventListener("DOMContentLoaded", function() {
  document.querySelector('meta[name="viewport"]').content =
    "width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no";
  // console.log(to, from);

  let reg = new RegExp("^/{1}cashier|report");
  // console.log(to.path, from.path);
  // console.log(reg.test(to.path) && (reg.test(from.path) || from.path == "/"));
  reg.test(to.path) && (reg.test(from.path) || from.path == "/")
    ? ""
    : store.dispatch("onRemoveDateState");

  if (from.path == "/login" || (to.path == "/login" && from.path == "/")) {
    document.querySelector('meta[name="viewport"]').content =
      "maximum-scale=1.0,user-scalable=no";
  } else {
    document.querySelector('meta[name="viewport"]').content =
      "maximum-scale=1.0,user-scalable=yes";
  }
  // });
  // let basic = `跳转结果: ${to.path}`;
  // if (from.path == "/login") {
  //   util.elapseEnd(basic);
  // } else {
  //   util.elapseNext(basic);
  // }
});

export default router;
