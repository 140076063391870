<template>
  <div class="dimension-classic">
    <div class="df-row">
      <!-- 门店选择器 -->
      <!-- <div class="df-col">
        <el-select
          v-if="authType == 'H'"
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          clearable
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStores"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- <div class="df-col">
        <el-select
          v-model="submitData.DimensionGuid"
          placeholder="维度分类"
          size="mini"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->

      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onCustomDialog"
          >自定义表格</el-button
        >
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onParamDialog"
          >消费级别设置</el-button
        >
      </div>

      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>

    <el-table
      ref="table"
      id="exprot-table"
      border
      stripe
      :data="dataList"
      :max-height="tableMaxHeight"
      v-loading="loading"
    >
      <!-- 合并列表头（一级表头），由于表格需要自定义，只能依靠循环渲染列的数据，而列的循环顺序则依赖后端接口返回的data顺序，
      所以如果需要修改渲染出来的顺序就要让后端改 -->
      <el-table-column
        v-for="(item, index) in topList"
        :key="index"
        :label="item.Name"
        align="center"
      >
        <!-- 子表头（二级表头） -->
        <el-table-column
          v-if="item.Code == item2.TopCode"
          v-for="(item2, index2) in headList"
          :key="index2"
          :label="item2.Name"
          :prop="item2.DataKey"
          show-overflow-tooltip
          :width="item2.DataKey == 'RangeDesc' ? '150' : 'auto'"
        >
          <template slot-scope="scope">
            <div>{{ scope.row[item2.DataKey] }}</div>
            <div>
              {{
                scope.row[item2.DataKeyPlus] != undefined
                  ? "(" + scope.row[item2.DataKeyPlus] + ")"
                  : ""
              }}
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      :reportCode="reportCode"
      @refresh="onRefreshCustom"
      @onConfirm="onSubmitMemberExpenseAmountStatis"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->

    <ParamSettingDialog
      ref="ParamSettingDialog"
      @success="onSearch"
    ></ParamSettingDialog>
  </div>
</template>

<script>
import report from "@/api/report.js";
import CustomDialog from "@/views/report/components/custom-dialog.vue";
import ParamSettingDialog from "./components/param-setting-dialog.vue";

export default {
  components: { CustomDialog, ParamSettingDialog },

  data() {
    return {
      tableMaxHeight: "",
      authType: "",
      totalRecord: 0,

      submitData: {
        begin_date: "",
        end_date: "",
        // StoresGuid: "",
        // DimensionGuid: "",
      },
      total: {},
      headData: {}, // 自定义对话框表头源数据（尽可能不要污染）
      heads: [], // 自定义对话框的表头列表
      topList: [], // 表格的合并表头（一级表头）
      headList: [], // 表格的表头（二级表头）
      storesList: [], // 门店列表
      dataList: [], // 表格内容数据列表
      multipleDate: [],
      loading: false,
      excelDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = this.storage.getStorage("accountInfo");
    this.authType = accountInfo.authType;
    // this.storesList = this.authType == 'H' ? accountInfo.manageStoresList : [];
    await this.initDateState();
    await this.fetchHeadMemberExpenseAmountStatis();
    await this.queryExpenseAmountStatis();
    // this.fetchHeadMemberExpenseAmountStatis();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取表头设置
    async fetchHeadMemberExpenseAmountStatis() {
      try {
        let { data } = await report.fetchHeadMemberExpenseAmountStatis();
        let { Heads, reportCode } = data;
        this.headData = data;
        this.heads = Heads;
        this.reportCode = reportCode;
      } catch (e) {}
    },

    // 查询会员消费分析数据
    async queryExpenseAmountStatis() {
      this.loading = true;
      try {
        let { data } = await report.queryExpenseAmountStatis(this.submitData);
        let { DataList, HeadList, TopList, Total } = data;
        // await this.tableColumnMaxWidth(HeadList);
        this.dataList = await DataList;
        this.headList = await HeadList;
        this.topList = await TopList;
        this.total = Total;
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    // 保存表头设置
    async saveHeadMemberExpenseAmountStatis() {
      try {
        let { errcode } = await report.saveHeadMemberExpenseAmountStatis({
          Heads: this.heads,
        });
        if (errcode === 0) {
          this.$message.success("表格设置保存成功");
          this.fetchHeadMemberExpenseAmountStatis();
          this.queryExpenseAmountStatis();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    onSearch() {
      this.queryExpenseAmountStatis();
    },

    async onRefreshCustom() {
      await this.fetchHeadMemberExpenseAmountStatis();
      await this.onCustomDialog();
    },

    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 60);
    },

    // 提交自定义表格设置
    onSubmitMemberExpenseAmountStatis(event) {
      let { Heads } = event;
      this.heads = Heads;
      this.saveHeadMemberExpenseAmountStatis();
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    onChangeStores() {},

    // 打开自定义表格对话框
    onCustomDialog() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    onParamDialog() {
      this.$refs.ParamSettingDialog.onShowDialog();
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "自定义消费级别分析统计表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dimension-classic {
  .df-row {
    margin-block-start: 20px;
  }
}

@media (max-width: 1280px) {
  .dimension-classic {
    .df-row {
      .flex-row;
      // align-items: unset;
      flex-wrap: wrap;
      margin-block: 20px 10px;

      .df-col {
        margin-block: 0 10px;
        .el-date-editor {
          width: 240px;
        }
      }
    }
  }
}
</style>
