<template>
  <div>
    <el-dialog
      title="批量设置基类"
      :visible.sync="showDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="auto" :inline="false" size="small">
          <el-form-item label="项目基类">
            <el-select
              v-model="submitData.BasicGuids"
              multiple
              collapse-tags
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in itemBasicList"
                :key="item.BasicGuid"
                :label="item.BasicName"
                :value="item.BasicGuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="batchBasicSetting" :disabled="submitDisabled"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Service from "@/api/service.js";

export default {
  props: {
    itemBasicList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      submitData: {
        BasicGuids: [],
        ItemGuids: [],
      },
      showDialog: false,
      submitDisabled: false,
    };
  },

  mounted() {},

  methods: {
    // 保存基类设置
    async batchBasicSetting() {
      this.submitDisabled = true;
      try {
        let { errcode } = await Service.batchBasicSetting(this.submitData);
        if (errcode == 0) {
          this.$emit("success");
          this.$message.success("设置成功");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.submitDisabled = false;
      }
    },

    // 打开对话框
    onShowDialog(event) {
      this.submitData.ItemGuids = JSON.parse(JSON.stringify(event || []));
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.submitData.BasicGuids = [];
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
