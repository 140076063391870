<template>
  <div class="empl-total">
    <div class="df-row">
      <div class="df-col">
        <DfDatePicker
          :date="multipleDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :shortcuts="['today', 'yesterday', 'month', 'lastMonth']"
          @change="onChangeDate"
          @shortcutClick="onShortcutClick"
        ></DfDatePicker>
      </div>
      <div class="df-col">
        <el-select
          v-model="submitData.duty_no"
          placeholder="请选择员工级别"
          size="mini"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col avg-bill">
        <el-checkbox
          v-model="submitData.avg_bill_count"
          :true-label="1"
          :false-label="0"
          :indeterminate="false"
          >平分客数</el-checkbox
        >
      </div>
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>
      <div class="df-col">
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button>
      </div>
    </div>

    <!--  -->
    <div class="total-table">
      <el-table
        id="exprot-table"
        :data="recordList"
        show-summary
        stripe
        :max-height="tableMaxHeight"
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column
          prop="EmplName"
          label="员工"
          width="100"
          :fixed="isFixedRow"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.EmplNo }}号</p>
            <p>{{ scope.row.DutyName }}</p>
            <p>{{ scope.row.LevelName }}</p>
            <p class="table-empl-name">[{{ scope.row.EmplName }}]</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalCashPay"
          sortable
          label="总现金"
          width="90"
        ></el-table-column>
        <el-table-column prop="TotalVipPay" sortable label="总划卡" width="90">
        </el-table-column>
        <el-table-column
          prop="TotalOtherPay"
          sortable
          label="总其它"
          width="90"
        >
        </el-table-column>
        <!--  -->
        <el-table-column label="劳动业绩">
          <el-table-column prop="WorkCashPay" label="现金业绩">
            <template slot-scope="scope">
              <span :class="scope.row.WorkCashPay ? 'table-red-font' : ''">{{
                scope.row.WorkCashPay
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="WorkVipPay" label="储值划卡业绩">
            <template slot-scope="scope">
              <span :class="scope.row.WorkVipPay ? 'table-red-font' : ''">{{
                scope.row.WorkVipPay
              }}</span>
              <br />
              <span v-show="scope.row.WorkVipDonaPay > 0">
                (赠金:{{ scope.row.WorkVipDonaPay }})</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="WorkOtherPay" label="其它业绩">
          </el-table-column>
          <el-table-column prop="WorkPackDona" label="疗程划卡业绩">
          </el-table-column>
          <el-table-column prop="WorkRestPay" label="0收费业绩">
          </el-table-column>
          <el-table-column
            prop="WorkTotal"
            sortable
            label="劳动业绩"
            width="110"
          >
          </el-table-column>
        </el-table-column>
        <!--  -->
        <el-table-column label="卖品业绩">
          <el-table-column prop="SellCashPay" label="现金业绩">
          </el-table-column>
          <el-table-column label="划卡业绩">
            <template slot-scope="scope">
              <span>{{ scope.row.SellVipPay }}</span>
              <br />
              <span v-show="scope.row.SellVipDonaPay > 0">
                (赠金:{{ scope.row.SellVipDonaPay }})</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="SellOtherPay" label="其它业绩">
          </el-table-column>
          <el-table-column prop="SellBillCount" label="卖品客数">
          </el-table-column>
        </el-table-column>
        <!--  -->
        <el-table-column
          prop="OpenKeepTotal"
          sortable
          label="开|充卡业绩"
          width="120"
        >
          <template slot-scope="scope">
            <span :class="scope.row.OpenKeepTotal ? 'table-red-font' : ''">{{
              scope.row.OpenKeepTotal
            }}</span>
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column
          sortable
          :sort-by="[
            'OpenPackTotal',
            'OpenPackCashPay',
            'OpenPackVipPay',
            'OpenPackOtherPay',
          ]"
          label="疗程销售业绩"
        >
          <el-table-column prop="OpenPackTotal" label="疗程销售额">
            <template slot-scope="scope">
              <span :class="scope.row.OpenPackTotal ? 'table-red-font' : ''">{{
                scope.row.OpenPackTotal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="OpenPackCashPay" label="现金业绩">
          </el-table-column>
          <el-table-column prop="OpenPackVipPay" label="划卡业绩">
          </el-table-column>
          <el-table-column prop="OpenPackOtherPay" label="其它业绩">
          </el-table-column>
        </el-table-column>
        <!--  -->
        <el-table-column prop="InventPerfor" label="创单业绩">
        </el-table-column>
        <!--  -->
        <el-table-column
          prop="AssignBillCount"
          sortable
          label="指定客数"
          width="110"
        >
          <template slot-scope="scope">
            <span :class="scope.row.AssignBillCount ? 'table-red-font' : ''">{{
              scope.row.AssignBillCount
            }}</span>
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column prop="AssignPerfor" label="指定业绩">
          <template slot-scope="scope">
            <span :class="scope.row.AssignPerfor ? 'table-red-font' : ''">{{
              scope.row.AssignPerfor
            }}</span>
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column prop="NotAssignBillCount" label="非指定客数">
          <template slot-scope="scope">
            <span
              :class="scope.row.NotAssignBillCount ? 'table-red-font' : ''"
              >{{ scope.row.NotAssignBillCount }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="NotAssignPerfor" label="非指定业绩">
          <template slot-scope="scope">
            <span :class="scope.row.NotAssignPerfor ? 'table-red-font' : ''">{{
              scope.row.NotAssignPerfor
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="AssignRate" sortable label="指定率" width="90">
          <template slot-scope="scope">
            <span :class="scope.row.AssignRate ? 'table-red-font' : ''"
              >{{ scope.row.AssignRate }}%</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalBillCount"
          sortable
          label="总客数"
          width="90"
        >
          <template slot-scope="scope">
            <span :class="scope.row.TotalBillCount ? 'table-red-font' : ''">{{
              scope.row.TotalBillCount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="AvgBillPrice" label="客单价">
          <template slot-scope="scope">
            <span :class="scope.row.AvgBillPrice ? 'table-red-font' : ''">{{
              scope.row.AvgBillPrice
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="TotalItemCount" label="项目数"></el-table-column>
        <!--  -->
        <el-table-column label="男客业绩">
          <el-table-column prop="MaleAssignPerfor" label="指定">
            <template slot-scope="scope">
              <span
                :class="scope.row.MaleAssignPerfor ? 'table-red-font' : ''"
                >{{ scope.row.MaleAssignPerfor }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="MaleNotAssignPerfor" label="非指定">
            <template slot-scope="scope">
              <span
                :class="scope.row.MaleNotAssignPerfor ? 'table-red-font' : ''"
                >{{ scope.row.MaleNotAssignPerfor }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="MaleBillCount" label="总个数">
            <template slot-scope="scope">
              <span :class="scope.row.MaleBillCount ? 'table-red-font' : ''">{{
                scope.row.MaleBillCount
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <!--  -->
        <el-table-column label="女客业绩">
          <el-table-column prop="FemaleAssignPerfor" label="指定">
            <template slot-scope="scope">
              <span
                :class="scope.row.FemaleAssignPerfor ? 'table-red-font' : ''"
                >{{ scope.row.FemaleAssignPerfor }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="FemaleNotAssignPerfor" label="非指定">
            <template slot-scope="scope">
              <span
                :class="scope.row.FemaleNotAssignPerfor ? 'table-red-font' : ''"
                >{{ scope.row.FemaleNotAssignPerfor }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="FemaleBillCount" label="总个数">
            <template slot-scope="scope">
              <span
                :class="scope.row.FemaleBillCount ? 'table-red-font' : ''"
                >{{ scope.row.FemaleBillCount }}</span
              >
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from "@/api/report.js";
export default {
  data() {
    return {
      submitData: {
        subact: "query_data",
        begin_date: "",
        end_date: "",
        duty_no: "",
        avg_bill_count: 0,
      },
      dutyList: [],
      multipleDate: [],
      recordList: [],
      tableMaxHeight: "",
      loading: false,
      isFixedRow: true,
      excelDisabled: false,
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initConditionData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68);
    },

    async initConditionData() {
      try {
        let { data } = await api.initEmployeePerforStatis({
          subact: "init_data",
        });
        this.dutyList = data.dutyList;
      } catch (e) {}
    },

    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.initEmployeePerforStatis(this.submitData);
        let tArray = [];
        data.RecordList.forEach((item) => {
          tArray.push({
            ...item.BillCountAndPerforData,
            ...item.OpenAndKeepPerforPaymentData,
            ...item.OpenPackPerforPaymentData,
            ...item.SellPerforPaymentData,
            ...item.WorkPerforPaymentData,
            ...item.ItemCountStatisData,
            ...item,
          });
        });

        this.recordList = tArray;
      } catch (e) {}
      this.loading = false;
    },

    onSearch() {
      this.initPageData();
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.multipleDate = [beginDay, endDay];
      this.submitData.begin_date = beginDay;
      this.submitData.end_date = endDay;
    },

    // 日期选择器改变时间
    async onChangeDate(event) {
      await this.$store.dispatch("onChangeDateState", { dayTime: event });
      await this.initDateState();
    },

    async onShortcutClick(event) {
      await this.onChangeDate(event);
      await this.initPageData();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "员工业绩汇总表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 15px 0;

  .df-col{
    margin: 5px 10px 5px 0;
  }

  .avg-bill {
    .flex-row;
  }
}

.empl-total {
  box-sizing: border-box;
  .el-table {
    .ascending .ascending {
      border-bottom-color: red;
    }

    .descending .descending {
      border-top-color: red;
    }
  }
}
</style>
