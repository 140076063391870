<template>
  <div class="df-each">
    <div class="df-row">
      <div class="df-col">
        <!-- 日期选择器 -->
        <DfDatePicker
          :date="selectDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :shortcuts="['today', 'yesterday', 'month', 'lastMonth']"
          @change="onChangeDate"
          @shortcutClick="onShortcutClick"
        ></DfDatePicker>
      </div>

      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button size="mini" type="primary" @click="initPageData">
          查询
        </el-button>

        <!-- 导出按钮 -->
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
        >
          导出表格
        </el-button>
      </div>
    </div>

    <!-- start 项目分类付款统计表 -->
    <el-table
      id="exprot-table"
      :data="dataList"
      :max-height="tableMaxHeight"
      :summary-method="getSummaries"
      border
      show-summary
      stripe
      v-loading="loading"
    >
      <el-table-column
        v-for="(head, index) in headList"
        :label="head.Name"
        :key="index"
        :fixed="index ? false : isFixedRow"
        :width="index ? '' : '120'"
      >
        <template slot-scope="scope">
          {{ scope.row[headList[index].DataKey] }}
        </template>
      </el-table-column>
    </el-table>
    <!-- start 项目分类付款统计表 -->
  </div>
</template>

<script>
import api from "@/api/report.js";
export default {
  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      selectDate: [], // 日期选择器数据
      submitData: { // 提交数据
        begin_date: "",
        end_date: "",
      },
      dataList: [], // 表格数据列表
      headList: [], // 表头列表
      total: {},  // 合计数据
      isFixedRow: true, // 首行是否悬浮
      loading: false, // 表格加载
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    window.addEventListener("resize", this.getResize);
    
    await this.initDateState()
    await this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 220;
    },

    // 初始化表格数据
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.queryBasicClassPaymentStatis(this.submitData);
        let { DataList, HeadList, Total } = data;
        this.dataList = DataList;
        this.headList = HeadList;
        this.total = Total;
      } catch (e) {}
      this.loading = false;
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.selectDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    async onChangeDate(event) {
      await this.$store.dispatch("onChangeDateState", { dayTime: event });
      await this.initDateState();
    },

    async onShortcutClick(event) {
      await this.onChangeDate(event);
      await this.initPageData();
    },

    // 合计函数
    getSummaries() {
      let { headList, total } = this;
      let arr = ["合计"];
      headList.forEach((item, index) => {
        if (index != 0) {
          arr.push(total[item.DataKey]);
        }
      });
      return arr;
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "项目分类付款统计表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-row {
  flex-wrap: wrap;
  margin: 15px 0;
  .df-col{
    margin: 5px 10px 5px 0;
  }
}
</style>
