<template>
  <div class="df-ranking">
    <div class="df-row df-ranking-top">
      <div class="df-col">
        <DfDatePicker
          :date="selectDate"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :shortcuts="['today', 'yesterday', 'month', 'lastMonth']"
          @change="onChangeDate"
          @shortcutClick="onShortcutClick"
        ></DfDatePicker>
      </div>
      <div class="df-col store-select">
        <el-select
          v-if="authType == 'H'"
          v-model="submitData.StoresGuids"
          collapse-tags
          size="mini"
          multiple
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-select
          v-model="submitData.DutyNo"
          placeholder="请选择部门"
          size="mini"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-button size="mini" type="primary" @click="initPageData">
          查询
        </el-button>
      </div>
      <div class="df-col">
        <el-button
          @click="onExportExcel"
          :disabled="excelDisabled"
          size="mini"
          type="primary"
        >
          导出表格
        </el-button>
      </div>
    </div>

    <div class="df-table">
      <el-table
        id="exprot-table"
        style="width: 100%"
        :data="tableData"
        border
        stripe
        size="mini"
        :max-height="tableMaxHeight"
        v-loading="loading"
      >
        <el-table-column label="姓名 工号">
          <template slot-scope="scope">
            <p>{{ scope.row.EmplNo }}号</p>
            <p>{{ scope.row.EmplName }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="StoresName" label="店名" width="180">
        </el-table-column>
        <el-table-column prop="LevelName" label="级别"> </el-table-column>
        <el-table-column prop="TotalCashPay" label="总现金业绩" sortable>
        </el-table-column>
        <el-table-column prop="WorkTotal" label="劳动业绩" sortable>
        </el-table-column>
        <el-table-column prop="SellTotal" label="卖品业绩" sortable>
        </el-table-column>
        <el-table-column prop="OpenKeepTotal" label="开|充卡业绩" sortable>
        </el-table-column>
        <el-table-column prop="OpenPackTotal" label="销售套餐">
        </el-table-column>
        <el-table-column prop="AssignBillCount" label="指定客数" sortable>
        </el-table-column>
        <el-table-column prop="AssignPerfor" label="指定业绩" sortable>
        </el-table-column>
        <el-table-column prop="AssignRate" label="指定率" sortable>
          <template slot-scope="scope">
            {{ scope.row.AssignRate * 100 }} %
          </template>
        </el-table-column>
        <el-table-column prop="TotalBillCount" label="总客数" sortable>
        </el-table-column>
        <el-table-column prop="AvgBillPrice" label="客单价" sortable>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from "@/api/report.js";
export default {
  data() {
    return {
      tableMaxHeight: "",
      selectDate: [],
      deptList: [],
      tableData: [],
      dutyList: [],
      submitData: {
        BeginDate: "",
        EndDate: "",
        DutyNo: "",
        StoresGuids: [],
      },
      loading: false,
      excelDisabled: false,
      authType: "",
      storesData: [],
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;
    this.initDateState();
    this.initConditionData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 220;
    },

    async initConditionData() {
      try {
        let { data } = await api.employeePerforStoresStatis({
          subact: "init_data",
        });
        console.log(data);
        this.dutyList = data.dutyList;
      } catch (e) {
        console.log(e);
      }
    },

    async initPageData() {
      this.loading = true;
      try {
        let { data } = await api.initEmployeePerforStoresStatis(
          this.submitData
        );
        let tArray = [];
        data.RecordList.forEach((item) => {
          tArray.push({
            ...item.BillCountAndPerforData,
            ...item.OpenAndKeepPerforPaymentData,
            ...item.OpenPackPerforPaymentData,
            ...item.SellPerforPaymentData,
            ...item.WorkPerforPaymentData,
            ...item,
          });
        });
        this.tableData = tArray;
      } catch (e) {}
      this.loading = false;
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.selectDate = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    async onChangeDate(event) {
      await this.$store.dispatch("onChangeDateState", { dayTime: event });
      await this.initDateState();
    },

    async onShortcutClick(event) {
      await this.onChangeDate(event);
      await this.initPageData();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "各门店员工业绩汇总排行表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.df-ranking {
  .df-ranking-top {
    display: flex;
    margin: 15px 0;
    flex-wrap: wrap;
    .df-col{
      margin: 5px 10px 5px 0;
    }
    ::v-deep .el-date-editor {
      width: 240px !important;
    }
    .store-select .el-select{
      width: 260px;
    }
  }

  .el-tag {
    display: flex;
    align-items: center;
    .el-select__tags-text {
      display: inline-block;
      max-width: 85px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
