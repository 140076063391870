<template>
  <div>
    <el-dialog
      title="新增项目"
      :visible.sync="showDialog"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="rulesData"
        ref="formName"
        size="small"
        label-width="80px"
      >
        <el-form-item label="项目名称" prop="ItemName">
          <el-input v-model="formData.ItemName" placeholder="请输入项目名称">
          </el-input>
        </el-form-item>
        <el-form-item label="收支类型" prop="SpendType">
          <el-select
            :disabled="true"
            v-model="formData.SpendType"
            placeholder="请选择收支类型"
          >
            <el-option
              v-for="(item, index) in initComponentData.spendType"
              :key="index"
              :value="item.Value"
              :label="item.Name"
            >
              {{ item.Name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分类" prop="KindGuid">
          <el-select
            :disabled="true"
            v-model="formData.KindGuid"
            placeholder="请选择所属分类"
          >
            <el-option
              v-for="(item, index) in initComponentData.kindList"
              :key="item.KindGuid"
              :value="item.KindGuid"
              :label="item.KindName"
            >
              {{ item.KindName }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCancelDialog"> 取 消 </el-button>
        <el-button
          ref="submitButton"
          size="small"
          @click="submitForm"
          type="primary"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../../api/cashier.js";
export default {
  data() {
    return {
      showDialog: false,
      formData: {
        ItemName: "",
        SpendType: "",
        KindGuid: "",
        ItemGuid: "",
      },
      rulesData: {
        ItemName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        SpendType: [
          { required: true, message: "请选择收支类型", trigger: "change" },
        ],
        KindGuid: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
      },
      initComponentData: {},
    };
  },

  methods: {
    async openAddItem(event, guid) {
      try {
        let { data } = await api.initSpendItemData({
          item_guid: guid ? guid : "",
        });
        this.initComponentData = data;
        if (guid) {
          this.formData.ItemName = event.NodeName;
          this.formData.SpendType = event.SpendType;
          this.formData.KindGuid = data.detail.KindGuid;
          this.formData.ItemGuid = guid;
        } else {
          this.formData.KindGuid = event.NodeGuid;
          this.formData.SpendType = event.SpendType;
        }
        this.showDialog = true;
      } catch (e) {}
    },

    closeAddItem() {
      this.formData = {
        ItemName: "",
        SpendType: "",
        KindGuid: "",
        ItemGuid: "",
      };
      this.$refs["formName"].resetFields();
      this.showDialog = false;
    },

    onCancelDialog() {
      this.closeAddItem();
    },

    submitForm() {
      this.$refs["formName"].validate(async (valid) => {
        if (valid) {
          try {
            this.$refs.submitButton.disabled = await true;
            let { data, errcode, errmsg } = await api.saveSpendItem(
              this.formData
            );
            if (errcode == (await 0)) {
              await this.$message({
                message: "添加成功!",
                type: "success",
              });
              await this.$emit("addItemSuccess");
              await this.closeAddItem();
            } else {
              await this.$message.error(errmsg);
            }
          } catch (e) {}
          this.$refs.submitButton.disabled = await false;
        } else {
          return false;
        }
      });
    },

    onConfirmDialog() {
      this.closeAddItem();
    },
  },
};
</script>

<style lang="less" scoped>
</style>